export interface Apis {
  /** 列表 */
  selectCameraBlackByPage: string;
  /** 新增 */
  insertCameraBlack: string;
  /** 修改 */
  updateCameraBlack: string;
  /** 状态切换 */
  updateCameraBlackStatus: string;
  /** 详情 */
  selectCameraBlackByBlackId: string;
  /** 人脸库列表 */
  selectCameraFaceByPage: string;
  /** 人脸信息 */
  selectCameraFace: string;
  /** 人脸库对比详情 */
  selectCameraFaceDetail: string;
}
export const apis: Apis = {
  selectCameraBlackByPage: '/business/cameraBlack/selectCameraBlackByPage',
  insertCameraBlack: '/business/cameraBlack/insertCameraBlack',
  updateCameraBlack: '/business/cameraBlack/updateCameraBlack',
  updateCameraBlackStatus: '/business/cameraBlack/updateCameraBlackStatus',
  selectCameraBlackByBlackId: '/business/cameraBlack/selectCameraBlackByBlackId',
  selectCameraFaceByPage: '/business/cameraFace/selectCameraFaceByPage',
  selectCameraFace: '/business/cameraFace/selectCameraFace',
  selectCameraFaceDetail: '/business/cameraFace/selectCameraFaceDetail'
}
