import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/guideManageList',
    name: 'guideManageList',
    meta: {
      title: '评价管理'
    },
    component: () => import('@/views/guideComment/ManageList.vue')
  },
  {
    path: '/guideManageList/guideCommentList',
    name: 'guideCommentList',
    meta: {
      title: '查看评价'
    },
    component: () => import('@/views/guideComment/List.vue')
  },
  {
    path: '/guideManageList/guideCommentList/detail:id',
    name: 'guideCommentDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/guideComment/Detail.vue')
  }
]

export default router
