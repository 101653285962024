// 接口名定义
export interface Apis {
  // 获取项目列表
  selectSumProjectList: string;
  // 客流分析历史（汇总）
  selectPassengerData: string;
  // 客流分析历史（折线图）
  selectPassengerDataChart: string;
  // 客流分析历史（列表）
  selectPassengerDataList: string;
  // 客流分析预测
  selectPassengerForecast: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/passenger/selectSumProjectList',
  selectPassengerData: '/business/passenger/selectPassengerData',
  selectPassengerDataChart: '/business/passenger/selectPassengerDataChart',
  selectPassengerDataList: '/business/passenger/selectPassengerDataList',
  selectPassengerForecast: '/business/passenger/selectPassengerForecast'
}
