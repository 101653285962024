export interface Apis {
    /** 资产类型 */
    /** 新增 */
    insertAssetType: string;
    /** 修改 */
    updateAssetType: string;
    /** 删除 */
    deleteAssetType: string;
    /** 详情 */
    selectAssetTypeByAssetType: string;
    /** 启用/禁用资产类型 */
    updateAssetTypeStatus: string;
    /** 查询资产类型树 */
    selectAssetTypeByList: string;
    /** 分页查询资产类型列表 */
    selectAssetTypeByPage: string;
    /** 资产位置 */
    /** 新增 */
    insertAssetLocation: string;
    /** 修改 */
    updateAssetLocation: string;
    /** 删除 */
    deleteAssetLocation: string;
    /** 详情 */
    selectAssetLocationByAssetLocation: string;
    /** 启用/禁用资产类型 */
    updateAssetLocationStatus: string;
    /** 查询资产类型树 */
    selectAssetLocationByList: string;
    /** 分页查询资产类型列表 */
    selectAssetLocationByPage: string;
    /** 资产配置 */
    /** 新增 */
    insertAssetConfLog: string;
    /** 修改 */
    selectAssetConfLogByConfType: string;
    /** 删除 */
    selectAssetConfLogByList: string;
    selectUserListByList: string;
    selectProjectUserByProjectList: string;
    selectRoleUserByRoleList: string;

    /** 资产入库 */
    /** 新增 */
    insertAssetPutLib: string;
    /** 删除 */
    deleteAssetPutLib: string;
    /** 详情 */
    selectAssetPutLibByOrderId: string;
    /** 分页查询资产入库单列表 */
    selectAssetPutLibByPage: string;
    /** 分页查询资产镜像列表 */
    selectAssetImageByPage: string;
    /** 导入资产清单 */
    importAssetPutLib: string;
    /** 导出资产入库单 */
    exportAssetPutLib: string;

    /** 资产清单 */
    /** 新增 */
    updateAsset: string;
    /** 删除 */
    deleteAsset: string;
    /** 详情 */
    selectAssetByAssetId: string;
    /** 分页查询资产操作信息 */
    selectAssetOrderByPage: string;
    /** 分页查询资产列表 */
    selectAssetByPage: string;
    /** 资产清单导出 */
    exportAsset: string;

    /** 资产领用 */
    /** 新增 */
    insertAssetReceive: string;
    /** 删除 */
    deleteAssetReceive: string;
    /** 详情 */
    selectAssetReceiveByOrderId: string;
    /** 分页查询资产领用单列表 */
    selectAssetReceiveByPage: string;
    /** 导出资产领用单 */
    exportAssetReceive: string;

    /** 资产退还 */
    /** 新增 */
    insertAssetReturn: string;
    /** 删除 */
    deleteAssetReturn: string;
    /** 详情 */
    selectAssetReturnByPage: string;
    /** 分页查询资产领用单列表 */
    selectAssetReturnByOrderId: string;
    /** 导出资产退还单 */
    exportAssetReturn: string;

    /** 资产调拨 */
    /** 新增 */
    insertAssetAllocate: string;
    /** 删除 */
    deleteAssetAllocate: string;
    /** 详情 */
    selectAssetAllocateByOrderId: string;
    /** 分页查询资产领用单列表 */
    selectAssetAllocateByPage: string;
    /** 审批资产调拨 */
    approvalAssetAllocate: string;
    /** 查询资产调拨候选人 */
    selectAssetTransferUserByList: string;
    /** 根据项目查询调入，调出方主管列表 */
    selectAssetTransferListByProjectId: string;
    /** 导出资产调拨单 */
    exportAssetAllocate: string;

    /** 资产盘点-新增资产盘库单 */
    insertAssetInventory: string;
    /** 资产盘点-新增盘盈 */
    insertInventoryAsset: string;
    /** 资产盘点-删除盘盈 */
    deleteInventoryAsset: string;
    /** 资产盘点-盘点资产 */
    updateInventoryAsset: string;
    /** 资产盘点-删除资产盘库单 */
    deleteAssetInventory: string;
    /** 资产盘点-结束盘点 */
    endAssetInventory: string;
    /** 资产盘点-分页查询资产盘库单列表 */
    selectAssetInventoryByPage: string;
    /** 资产盘点-查询资产盘库单详情 */
    selectAssetInventoryByOrderId: string;
    /** 资产盘点-查询资产盘库单详情 */
    exportAssetInventory: string;

    /** 资产处置-新增资产处置单 */
    insertAssetHandle: string;
    /** 资产处置-分页查询资产处置单列表 */
    selectAssetHandleByPage: string;
    /** 资产处置-查询资产处置单详情 */
    selectAssetHandleByOrderId: string;
    /** 资产处置-审批资产处置 */
    approvalAssetHandle: string;

    /** 新增资产产权 */
    insertAssetRight: string;
    /** 分页查询资产产权列表 */
    selectAssetRightByPage: string;
    /** 查询资产产权列表 */
    selectAssetRightByList: string;
    /** 查询资产产权详情 */
    selectAssetRightByAssetRight: string;
    /** 编辑资产产权 */
    updateAssetRight: string;
    /** 删除资产产权 */
    deleteAssetRight: string;
  }
export const apis: Apis = {
  insertAssetType: '/business/assetType/insertAssetType',
  updateAssetType: '/business/assetType/updateAssetType',
  deleteAssetType: '/business/assetType/deleteAssetType',
  selectAssetTypeByAssetType: '/business/assetType/selectAssetTypeByAssetType',
  updateAssetTypeStatus: '/business/assetType/updateAssetTypeStatus',
  selectAssetTypeByList: '/business/assetType/selectAssetTypeByList',
  selectAssetTypeByPage: '/business/assetType/selectAssetTypeByPage',
  //
  insertAssetLocation: '/business/assetLocation/insertAssetLocation',
  updateAssetLocation: '/business/assetLocation/updateAssetLocation',
  deleteAssetLocation: '/business/assetLocation/deleteAssetLocation',
  updateAssetLocationStatus: '/business/assetLocation/updateAssetLocationStatus',
  selectAssetLocationByList: '/business/assetLocation/selectAssetLocationByList',
  selectAssetLocationByPage: '/business/assetLocation/selectAssetLocationByPage',
  selectAssetLocationByAssetLocation: '/business/assetLocation/selectAssetLocationByAssetLocation',
  selectUserListByList: 'business/iparkUser/selectUserListByList',
  selectProjectUserByProjectList: 'business/assetConfLog/selectProjectUserByProjectList',
  selectRoleUserByRoleList: 'business/assetConfLog/selectRoleUserByRoleList',
  //
  insertAssetConfLog: '/business/assetConfLog/insertAssetConfLog',
  selectAssetConfLogByConfType: '/business/assetConfLog/selectAssetConfLogByConfType',
  selectAssetConfLogByList: '/business/assetConfLog/selectAssetConfLogByList',
  //
  insertAssetPutLib: '/business/assetPutLib/insertAssetPutLib',
  deleteAssetPutLib: '/business/assetPutLib/deleteAssetPutLib',
  selectAssetPutLibByOrderId: '/business/assetPutLib/selectAssetPutLibByOrderId',
  selectAssetPutLibByPage: '/business/assetPutLib/selectAssetPutLibByPage',
  selectAssetImageByPage: '/business/assetImage/selectAssetImageByPage',
  importAssetPutLib: '/business/assetPutLib/importAssetPutLib',
  exportAssetPutLib: '/business/assetPutLib/exportAssetPutLib',
  //
  updateAsset: '/business/asset/updateAsset',
  deleteAsset: '/business/asset/deleteAsset',
  selectAssetByAssetId: '/business/asset/selectAssetByAssetId',
  selectAssetOrderByPage: '/business/asset/selectAssetOrderByPage',
  selectAssetByPage: '/business/asset/selectAssetByPage',
  exportAsset: '/business/asset/exportAsset',

  /** 资产领用 */
  insertAssetReceive: '/business/assetReceive/insertAssetReceive',
  deleteAssetReceive: '/business/assetReceive/deleteAssetReceive',
  selectAssetReceiveByOrderId: '/business/assetReceive/selectAssetReceiveByOrderId',
  selectAssetReceiveByPage: '/business/assetReceive/selectAssetReceiveByPage',
  exportAssetReceive: '/business/assetReceive/exportAssetReceive',

  /** 资产退还 */
  insertAssetReturn: '/business/assetReturn/insertAssetReturn',
  deleteAssetReturn: '/business/assetReturn/deleteAssetReturn',
  selectAssetReturnByPage: '/business/assetReturn/selectAssetReturnByPage',
  selectAssetReturnByOrderId: '/business/assetReturn/selectAssetReturnByOrderId',
  exportAssetReturn: '/business/assetReturn/exportAssetReturn',

  /** 资产调拨 */
  /** 新增 */
  insertAssetAllocate: '/business/assetAllocate/insertAssetAllocate',
  /** 删除 */
  deleteAssetAllocate: '/business/assetAllocate/deleteAssetAllocate',
  /** 详情 */
  selectAssetAllocateByOrderId: '/business/assetAllocate/selectAssetAllocateByOrderId',
  /** 分页查询资产领用单列表 */
  selectAssetAllocateByPage: '/business/assetAllocate/selectAssetAllocateByPage',
  /** 审批资产调拨 */
  approvalAssetAllocate: '/business/assetAllocate/approvalAssetAllocate',
  /** 查询资产调拨候选人 */
  selectAssetTransferUserByList: '/business/assetAllocate/selectAssetTransferUserByList',
  selectAssetTransferListByProjectId: '/business/assetConfLog/selectAssetTransferListByProjectId',
  exportAssetAllocate: '/business/assetAllocate/exportAssetAllocate',
  insertAssetInventory: '/business/assetInventory/insertAssetInventory',
  insertInventoryAsset: '/business/assetInventory/insertAsset',
  deleteInventoryAsset: '/business/assetInventory/deleteAsset',
  updateInventoryAsset: '/business/assetInventory/updateAsset',
  deleteAssetInventory: '/business/assetInventory/deleteAssetInventory',
  endAssetInventory: '/business/assetInventory/endAssetInventory',
  selectAssetInventoryByPage: '/business/assetInventory/selectAssetInventoryByPage',
  selectAssetInventoryByOrderId: '/business/assetInventory/selectAssetInventoryByOrderId',
  exportAssetInventory: '/business/assetInventory/exportAssetInventory',
  insertAssetHandle: '/business/assetHandle/insertAssetHandle',
  selectAssetHandleByPage: '/business/assetHandle/selectAssetHandleByPage',
  selectAssetHandleByOrderId: '/business/assetHandle/selectAssetHandleByOrderId',
  approvalAssetHandle: '/business/assetHandle/approvalAssetHandle',
  insertAssetRight: '/business/assetRight/insertAssetRight',
  selectAssetRightByPage: '/business/assetRight/selectAssetRightByPage',
  selectAssetRightByList: '/business/assetRight/selectAssetRightByList',
  selectAssetRightByAssetRight: '/business/assetRight/selectAssetRightByAssetRight',
  updateAssetRight: '/business/assetRight/updateAssetRight',
  deleteAssetRight: '/business/assetRight/deleteAssetRight'
}
