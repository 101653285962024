import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/patrolTask',
    name: 'patrolTaskList',
    meta: {
      title: '巡查任务'
    },
    component: () => import('@/views/patrol/task/List.vue')
  },
  {
    path: '/patrolTask/add',
    name: 'patrolTaskAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/patrol/task/Add.vue')
  },
  {
    path: '/patrolTask/planDetail:id',
    name: 'patrolTaskPlanDetail',
    meta: {
      title: '执行记录'
    },
    component: () => import('@/views/patrol/task/Detail.vue')
  },
  {
    path: '/patrolTask/planDetail:id/detail:taskId',
    name: 'patrolTaskDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/patrol/task/TaskDetail.vue')
  },
  {
    path: '/patrolIssue',
    meta: {
      title: '巡查问题'
    },
    component: () => import('@/views/patrol/issue/List.vue')
  },
  {
    path: '/patrolIssue/detail:issueId',
    name: 'patrolIssueDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/patrol/issue/Detail.vue')
  },
  {
    path: '/patrolPoint',
    name: 'patrolPointList',
    meta: {
      title: '巡查点位'
    },
    component: () => import('@/views/patrol/point/List.vue')
  },
  {
    path: '/patrolPoint/add',
    name: 'patrolPointAdd',
    meta: {
      title: '新增点位'
    },
    component: () => import('@/views/patrol/point/Add.vue')
  },
  {
    path: '/patrolPoint/edit:id',
    name: 'patrolPointEdit',
    meta: {
      title: '编辑点位'
    },
    component: () => import('@/views/patrol/point/Add.vue')
  },
  {
    path: '/patrolPoint/detail:id',
    name: 'patrolPointDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/patrol/point/Detail.vue')
  },
  {
    path: '/patrolElement',
    name: 'patrolElementList',
    meta: {
      title: '巡查要素'
    },
    component: () => import('@/views/patrol/element/List.vue')
  },
  {
    path: '/patrolElement/add',
    name: 'patrolElementAdd',
    meta: {
      title: '新增要素'
    },
    component: () => import('@/views/patrol/element/Add.vue')
  },
  {
    path: '/patrolElement/edit:elementId',
    name: 'patrolElementEdit',
    meta: {
      title: '编辑要素'
    },
    component: () => import('@/views/patrol/element/Add.vue')
  },
  {
    path: '/patrolElement/detail:elementId',
    name: 'patrolElementDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/patrol/element/Detail.vue')
  },
  {
    path: '/patrolExport',
    meta: {
      title: '我的导出'
    },
    component: () => import('@/views/patrol/export/Index.vue')
  }
]

export default router
