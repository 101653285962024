<template>
  <div>
    <el-upload
      ref="upload"
      :accept="accept"
      :listType="listType"
      :multiple="multiple"
      :data= "fileData"
      :action="$apis.common.uploadQiniup"
      :on-success="handleSuccess"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-error="handleError"
      :on-exceed="handleExceed"
      :limit='limit'
      :class="{ hide: fileLength >= limit || disabled }"
      v-bind="$attrs">
      <!-- <i class="el-icon-plus"></i> -->
      <slot>
        <i v-if="listType === 'picture-card'" class="el-icon-plus"></i>
      </slot>
      <div slot="tip" class="el-upload__tip">
        <slot name="tip"></slot>
      </div>
      <div class="global-picture-card" v-if="listType === 'picture-card'" slot="file" slot-scope="{file}">
        <template v-if="file && file.name">
          <video v-if="videoType.toLowerCase().indexOf(file.name.substring(file.name.lastIndexOf('.')).toLowerCase()) >= 0"
                 class="el-upload-list__item-thumbnail"
                 :src="file.url" controls></video>
          <img v-else
               class="el-upload-list__item-thumbnail"
               :src="file.url" alt=""
          >
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handlePreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span v-if="!disabled && hasDownload" class="el-upload-list__item-delete" @click="handleDownload(file)">
              <i class="el-icon-download"></i>
            </span>
            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </template>
      </div>
    </el-upload>

    <image-viewer :visible.sync="dialogVisible" :url-list="formatFileList" :initial-index="currentIndex"></image-viewer>
  </div>
</template>

<script>
import ImageViewer from '@/components/ImageViewer'
import lrz from 'lrz'
import { picDns, videoDns, fileDns, bucket } from '@/components/uploadFile/config'
import { getUUID } from '@/utils/formatData'
export default {
  name: 'CustomUpload',
  components: { ImageViewer },
  props: {
    accept: {
      type: String,
      default: '.jpg,.png,.jpeg,.gif,.bmp,.tif,.tiff'
    },
    imgCompressType: {
      type: String,
      default: '.jpg,.png,.jpeg'
    },
    compress: {
      type: Boolean,
      default: true
    },
    videoType: {
      type: String,
      default: '.mp4,.mov'
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    limit: {
      type: Number,
      default: 6
    },
    size: {
      type: Number, // 单位MB
      default: 20
    },
    multiple: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasDownload: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      dialogVisible: false,
      currentIndex: 0,
      fileList: [],
      files: [],
      isInit: true,
      fileData: {
        token: '',
        key: ''
      }
    }
  },
  computed: {
    formatFileList () {
      return this.files.map(item => {
        return { url: item.filePrefix + item.fileUrl, name: item.fileName }
      })
    },
    fileLength () {
      return this.files.length
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val, oldVal) {
        if (!(oldVal && oldVal.length) && val.length) {
          this.fileList = val.map((item, ind) => {
            return {
              tag: new Date().toString() + ind,
              name: item.fileName,
              url: item.filePrefix + item.fileUrl
            }
          })
        }
        this.files = val
      }
    }
  },
  created () {
    this.getToken()
  },
  methods: {
    // 空间名：testipark，对应的下载链接http://test.qxt.cdn.gintell.cn + '/' + key
    getToken () {
      this.$axios.get(this.$apis.common.uploadToken, { bucket }).then(res => {
        this.fileData.token = res
        this.getKey()
      })
    },
    // 获取key 年/月/日/uuid
    getKey () {
      const date = new Date()
      this.fileData.key = `${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}/${getUUID()}`
    },
    // 文件访问域名(根据图片，视频，其他文件判断)
    fileDns (value) {
      const strImg = 'jpeg,jpg,png'
      const strVideo = 'mp4,rmvb,avi'
      const type = value.substring(value.lastIndexOf('.') + 1).toLowerCase()
      if (strImg.indexOf(type) !== -1) {
        return picDns
      } else if (strVideo.indexOf(type) !== -1) {
        return videoDns
      } else {
        return fileDns
      }
    },
    handleSuccess (res, file) {
      // 更新key
      this.getKey()
      this.files.push({
        fileName: file.name,
        filePrefix: this.fileDns(file.name),
        fileUrl: res.key
      })
      this.$emit('input', this.files)
    },
    handleError () {
      console.log('handleError')
      this.$message({ type: 'error', message: '网络异常，请检查网络连接' })
    },
    handleRemove (file) {
      const index = this.files.findIndex(item => item.fileName === file.name)
      if (index >= 0) {
        // this.fileList.splice(index, 1)
        this.files.splice(index, 1)
        this.$emit('input', this.files)
        this.fileList = this.value.map((item, ind) => {
          return {
            tag: new Date().toString() + ind,
            name: item.fileName,
            url: item.filePrefix + item.fileUrl
          }
        })
      }
    },
    handlePreview (file) {
      const index = this.files.findIndex(item => item.fileName === file.name)
      this.currentIndex = index
      this.dialogVisible = true
    },
    handleDownload (file) {
      console.log(file)
    },
    beforeUpload (file) {
      const size = file.size > this.size * 1024 * 1024
      const fileType = file.name.substr(file.name.lastIndexOf('.')).toLowerCase()
      const accept = (this.accept || '').toLowerCase()
      const isFileType = accept.includes(fileType)
      const imgCompressType = (this.imgCompressType || '').toLowerCase()
      const isCompressType = imgCompressType.includes(fileType)
      if (size) {
        setTimeout(() => {
          this.$message({ type: 'warning', message: `文件 [${file.name}] 大小不得超过${this.size}MB` })
        }, 0)
      }
      if (!isFileType) {
        setTimeout(() => {
          this.$message({ type: 'warning', message: `文件 [${file.name}] 格式错误` })
        }, 0)
      }
      if (!size && isFileType) {
        if (isCompressType && this.compress) {
          return lrz(file).then(res => {
            res.file.name = file.name
            return res.file
          })
        }
        this.$emit('beforeUpload', file)
        return true
      } else {
        return false
      }
    },
    handleExceed (file, fileList) {
      this.$message({ type: 'warning', message: '最多上传' + this.limit + '个，请重新选择' })
    }
  }
}
</script>

<style scoped lang="scss">
  .hide {
    ::v-deep .el-upload {
      display: none;
    }
  }
  ::v-deep {
    .el-upload-list__item {
      transition: none !important;
    }
    .el-upload-list__item.is-ready {
      display: none;
    }
    .el-upload__tip {
      color: #999999;
    }
    //上传图片及图片展示大小调整
    .el-upload--picture-card,
    .el-upload-list--picture-card .el-upload-list__item {
      border-radius: 0px;
      width: 104px;
      height: 104px;
      line-height: 104px;
      .global-picture-card {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
      .el-upload-list__item-thumbnail {
        object-fit: fill;
      }
    }
  }
</style>
