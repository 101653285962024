import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/blackList',
    name: 'blackList',
    meta: {
      title: '黑名单管理'
    },
    component: () => import('@/views/blackList/List.vue')
  },
  {
    path: '/faceCapture',
    name: 'faceCapture',
    meta: {
      title: '人脸抓拍库'
    },
    component: () => import('@/views/faceCapture/List.vue')
  },
  {
    path: '/faceCapture/detail:id',
    name: 'faceCaptureDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/faceCapture/Detail.vue')
  }
]

export default router
