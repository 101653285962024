import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/intelligentControl',
    name: 'intelligentControlList',
    meta: {
      title: '智能遥控'
    },
    component: () => import('@/views/intelligentControl/List.vue')
  },
  {
    path: '/intelligentControl/detail:projectId',
    name: 'intelligentControlDetail',
    meta: {
      title: '详情'
    },
    redirect: '/intelligentControl',
    component: () => import('@/views/intelligentControl/Detail.vue'),
    children: [
      {
        path: '/intelligentControl/detail:projectId/map',
        name: 'intelligentControlDetailMap',
        component: () => import('@/views/intelligentControl/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/intelligentControl/detail:projectId/list',
        name: 'intelligentControlDetailList',
        component: () => import('@/views/intelligentControl/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/intelligentControl/detail:projectId/list/history:deviceId',
    name: 'intelligentControlListHistory',
    component: () => import('@/views/intelligentControl/History.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    path: '/intelligentControl/detail:projectId/map/history:deviceId',
    name: 'intelligentControlMapHistory',
    component: () => import('@/views/intelligentControl/History.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    path: '/intelligentControl/detail:projectId/list/bindDevice',
    name: 'intelligentControlBindDevice',
    component: () => import('@/views/intelligentControl/BindDevice.vue'),
    meta: {
      title: '绑定控制设备'
    }
  }
]

export default router
