import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  {
    path: '/restaurant',
    name: 'restaurantList',
    component: () => import('@/views/restaurant/List.vue'),
    meta: {
      title: '餐饮美食'
    }
  },
  {
    path: '/restaurant/add',
    name: 'restaurantAdd',
    component: () => import('@/views/restaurant/Add.vue'),
    meta: {
      title: '新增'
    }
  },
  {
    path: '/restaurant/update:id',
    name: 'restaurantUpdate',
    component: () => import('@/views/restaurant/Add.vue'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/restaurant/detail:id',
    name: 'restaurantDetail',
    component: () => import('@/views/restaurant/Detail.vue'),
    meta: {
      title: '详情'
    }

  }

]

export default router
