export interface Apis {
  /** 添加车辆分类 */
  insertVehicleType: string;
  /** 修改车辆分类 */
  updateVehicleType: string;
  /** 删除车辆分类 */
  deleteDataVehicleType: string;
  /** 查看车辆分类详情 */
  selectVehicleTypeById: string;
  /** 车辆分类列表 */
  selectVehicleTypeList: string;

  /** 添加车辆信息 */
  insertMachine: string;
  /** 修改车辆信息 */
  updateMachine: string;
  /** 删除车辆信息 */
  deleteDataMachine: string;
  /** 车辆信息列表分页  */
  selectMachineByPage: string;
  /** 查看车辆信息详情 */
  selectMachineById: string;
  /** 车辆绑定 */
  bindDevice: string;
  /** 车辆解绑  */
  unbindDevice: string;
  /** 车辆信息列表 */
  selectMachineList: string;

  /** 添加维修信息 */
  insertVehicleRepair: string;
  /** 修改维修信息 */
  updateVehicleRepair: string;
  /** 删除维修信息 */
  deleteDataVehicleRepair: string;
   /** 查看维修信息详情 */
  selectVehicleRepairById: string;
  /** 维修信息列表分页 */
  selectVehicleRepairByPage: string;

  /** 添加违章信息 */
  insertVehicleViolation: string;
  /** 修改违章信息 */
  updateVehicleViolation: string;
  /** 删除违章信息 */
  deleteDataVehicleViolation: string;
  /** 查询违章信息详情 */
  selectVehicleViolationById: string;
  /** 违章信息列表分页 */
  selectVehicleViolationByPage: string;

}
export const apis: Apis = {
  insertVehicleType: '/business/es/vehicleType/insertVehicleType',
  updateVehicleType: '/business/es/vehicleType/updateVehicleType',
  deleteDataVehicleType: '/business/es/vehicleType/deleteDataVehicleType',
  selectVehicleTypeById: '/business/es/vehicleType/selectVehicleTypeById',
  selectVehicleTypeList: '/business/es/vehicleType/selectVehicleTypeList',

  insertMachine: '/business/es/machine/insertMachine',
  updateMachine: '/business/es/machine/updateMachine',
  deleteDataMachine: '/business/es/machine/deleteDataMachine',
  selectMachineByPage: '/business/es/machine/selectMachineByPage',
  selectMachineById: '/business/es/machine/selectMachineById',
  bindDevice: '/business/es/machine/bindDevice',
  unbindDevice: '/business/es/machine/unbindDevice',
  selectMachineList: '/business/es/machine/selectMachineList',

  insertVehicleRepair: '/business/es/vehicleRepair/insertVehicleRepair',
  updateVehicleRepair: '/business/es/vehicleRepair/updateVehicleRepair',
  deleteDataVehicleRepair: '/business/es/vehicleRepair/deleteDataVehicleRepair',
  selectVehicleRepairById: '/business/es/vehicleRepair/selectVehicleRepairById',
  selectVehicleRepairByPage: '/business/es/vehicleRepair/selectVehicleRepairByPage',

  insertVehicleViolation: '/business/es/vehicleViolation/insertVehicleViolation',
  updateVehicleViolation: '/business/es/vehicleViolation/updateVehicleViolation',
  deleteDataVehicleViolation: '/business/es/vehicleViolation/deleteDataVehicleViolation',
  selectVehicleViolationById: '/business/es/vehicleViolation/selectVehicleViolationById',
  selectVehicleViolationByPage: '/business/es/vehicleViolation/selectVehicleViolationByPage'
}
