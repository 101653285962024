import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/customermanage',
    meta: {
      title: '客服管理'
    },
    component: () => import('@/views/customermanage/CustomerManage.vue')
  }
]

export default router
