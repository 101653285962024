// 接口名定义
export interface Apis {
  // 获取游客信息列表
  selectAppUserByPage: string;
  /** 游客反馈分页列表 */
  selectAppUserFeedbackByFeedbackId: string;
  /** 游客反馈详情 */
  selectAppUserFeedbackByPage: string;
  /** 查询市民反馈列表 */
  selectCitizenFeedbackByList: string;
  /** 查询市民反馈详情 */
  selectCitizenFeedbackById: string;
  /** 下发工单 */
  placeOrder: string;
}

export const apis: Apis = {
  selectAppUserByPage: '/operate/appUser/selectAppUserByPage',
  selectAppUserFeedbackByFeedbackId: '/operate/appUserFeedback/selectAppUserFeedbackByFeedbackId',
  selectAppUserFeedbackByPage: '/operate/appUserFeedback/selectAppUserFeedbackByPage',
  selectCitizenFeedbackByList: '/business/citizenFeedback/selectCitizenFeedbackByList',
  selectCitizenFeedbackById: '/business/citizenFeedback/selectCitizenFeedbackById',
  placeOrder: '/business/citizenFeedback/placeOrder'
}
