export interface Apis {
  /** 新增 */
  insertMessage: string;
  /** 发布、下架 */
  releaseMessage: string;
  /** 编辑 */
  updateMessage: string;
  /** 删除 */
  deleteMessage: string;
  /** 查询详情 */
  selectMessageByMessageId: string;
  /** 分页查询 */
  selectMessageByPage: string;
}

export const apis: Apis = {
  insertMessage: '/operate/message/insertMessage',
  releaseMessage: '/operate/message/releaseMessage',
  updateMessage: '/operate/message/updateMessage',
  deleteMessage: '/operate/message/deleteMessage',
  selectMessageByMessageId: '/operate/message/selectMessageByMessageId',
  selectMessageByPage: '/operate/message/selectMessageByPage'
}
