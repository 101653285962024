// 水压检测
export interface Apis {
  // 项目列表
  selectSumProjectList: string;
  // 详情地图
  selectTopsailPressureMap: string;
  // 详情列表
  selectTopsailPressureListByPage: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/topsailPressure/selectSumProjectList',
  selectTopsailPressureMap: '/business/topsailPressure/selectTopsailPressureMap',
  selectTopsailPressureListByPage: '/business/topsailPressure/selectTopsailPressureListByPage'
}
