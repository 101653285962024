import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/pondmonitor',
    name: 'pondMonitortList',
    meta: {
      title: '积水监测'
    },
    component: () => import('@/views/pondMonitor/List.vue')
  },
  {
    path: '/pondmonitor/pondMonitorDetail:projectId',
    name: 'pondMonitorDetail',
    meta: {
      title: '详情'
    },
    redirect: '/pondmonitor',
    component: () => import('@/views/pondMonitor/Detail.vue'),
    children: [
      {
        path: '/pondmonitor/pondMonitorDetail:projectId/map',
        name: 'pondMonitorDetailMap',
        component: () => import('@/views/pondMonitor/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/pondmonitor/pondMonitorDetail:projectId/list',
        name: 'pondMonitorDetailList',
        component: () => import('@/views/pondMonitor/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/pondmonitor/pondMonitorDetail:projectId/list/history:deviceId',
    name: 'pondMonitorListHistory',
    component: () => import('@/views/pondMonitor/History.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    path: '/pondMonitor/pondMonitorDetail:projectId/map/history:deviceId',
    name: 'pondMonitorMapHistory',
    component: () => import('@/views/pondMonitor/History.vue'),
    meta: {
      title: '历史数据'
    }
  }
]

export default router
