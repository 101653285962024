import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/projectWorkerList',
    name: 'projectWorkerList',
    component: () => import('@/views/projectWorker/List.vue'),
    meta: {
      title: '项目工人管理'
    }
  },
  {
    path: '/check',
    meta: { title: '考勤结果管理' },
    component: () => import('@/views/projectWorker/Basis.vue'),
    redirect: '/check/result',
    children: [{
      path: 'result',
      meta: {
        hasBack: false,
        title: '考勤结果管理'
      },
      component: () => import('@/views/projectWorker/ResultManage.vue')
    }, {
      path: 'report',
      meta: {
        hasBack: false,
        title: '考勤统计'
      },
      component: () => import('@/views/projectWorker/ReportManage.vue')
    }, {
      path: 'more',
      meta: {
        hasBack: false,
        title: '多人考勤'
      },
      component: () => import('@/views/projectWorker/MoreManage.vue')
    }]
  },
  {
    path: '/projectWorkerList/add',
    name: 'projectWorkerAdd',
    component: () => import('@/views/projectWorker/Add.vue'),
    meta: {
      title: '项目工人添加'
    }
  },
  {
    path: '/projectWorkerList/edit:workerId',
    name: 'projectWorkerEdit',
    component: () => import('@/views/projectWorker/Add.vue'),
    meta: {
      title: '项目工人编辑'
    }
  },
  {
    name: 'scheduleList',
    path: '/schedule',
    component: () => import('@/views/projectWorker/Scheduling.vue'),
    redirect: '/schedule/manage',
    meta: {
      title: '排班管理'
    },
    children: [
      {
        path: 'manage',
        name: 'scheduleManage',
        props: true,
        component: () => import('@/views/projectWorker/ScheduleManage.vue'),
        meta: {
          title: '排班管理',
          hasBack: false
        }
      },
      {
        path: 'calendar',
        name: 'scheduleCalendar',
        props: true,
        component: () => import('@/views/projectWorker/ScheduleCalendar.vue'),
        meta: {
          title: '排班日历',
          hasBack: false
        }
      }
    ]
  },
  {
    name: 'scheduleListAdd',
    path: '/schedule/add',
    component: () => import('@/views/projectWorker/ScheduleAdd.vue'),
    meta: {
      title: '添加排班'
    }
  },
  {
    name: 'scheduleListEdit',
    path: '/schedule/add:id',
    component: () => import('@/views/projectWorker/ScheduleAdd.vue'),
    meta: {
      title: '编辑排班'
    }
  }, {
    path: '/ondutyanalysis',
    meta: { title: '在岗分析' },
    component: () => import('@/views/projectWorker/OndutyAnalysis.vue'),
    redirect: '/ondutyanalysis/realtime',
    children: [{
      path: 'realtime',
      meta: {
        hasBack: false,
        title: '在岗分析'
      },
      component: () => import('@/views/projectWorker/OndutyAnalysisRealtime.vue')
    }, {
      path: 'history',
      meta: {
        hasBack: false,
        title: '在岗分析'
      },
      component: () => import('@/views/projectWorker/OndutyAnalysisHistory.vue')
    }]
  },
  {
    path: '/ondutyanalysis/playback:projectId:workerId',
    name: 'ondutyanalysisplayback',
    meta: {
      title: '轨迹回放'
    },
    component: () => import('@/views/workcard/DetailPlayback.vue')
  }
]

export default router
