
import { Component, Vue } from 'vue-property-decorator'
import Menu from './menu/Index.vue'
import TopBar from './menu/TopBar.vue'
import ResetPassword from '@/components/resetPassword/Index.vue'

@Component({
  components: {
    TopBar,
    Menu,
    ResetPassword
  }
})
export default class Layout extends Vue {
  private userInfo = ''
  private imgLoading = false
  private websocket: any = ''
  private notify: any = null
  private messageInfo: any = {}
  private defaultImg = require('@/assets/img/avatarDefault.png')
  private audioPlayer: any = null
  get avatar () {
    const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') as any)
    const storeUserInfo = this.$store.state.userInfo && this.$store.state.userInfo.resource
    return userInfo && userInfo.resource ? userInfo.resource.filePrefix + userInfo.resource.fileUrl : storeUserInfo || this.defaultImg
  }

  get userName () {
    let name = ''
    if (this.$store.state.userInfo && this.$store.state.userInfo.name) {
      name = this.$store.state.userInfo.name
    } else if (window.sessionStorage.getItem('userInfo')) {
      name = JSON.parse(window.sessionStorage.getItem('userInfo') as any).name
    }
    return name
  }

  get isImgHtml () {
    return window.sessionStorage.getItem('isImgHtml') || ''
  }

  get customerCode () {
    return window.sessionStorage.getItem('customerCode') || ''
  }

  get messageNum () {
    return this.$store.state.messageNum
  }

  get keepAlive () {
    return this.$store.state.keepAlive
  }

  // 账户信息和首页不展示左侧菜单栏
  get getCurrentUrl () {
    if (this.$store.state.menuList.length > 0) {
      return (this.$route.path !== '/homePage' && this.$route.path !== '/' && this.$route.path !== '/userInfo')
    }
    return false
  }

  get menuName () {
    return this.$route.meta.title
  }

  // 是否有返回按钮
  get parentUrl () {
    if (this.$route.meta.hasBack !== false) {
      const routeUrl = this.$route.meta.backUrl || this.$route.path.substr(0, this.$route.path.lastIndexOf('/'))
      const routeQuery = this.$route.meta.hasQuery ? this.$route.query || {} : {}
      return routeUrl ? { path: routeUrl, query: routeQuery } : false
    } else {
      return false
    }
  }

  destroyed () {
    // 离开之后断开websocket连接
    if (this.websocket) {
      this.websocket.close()
    }
  }

  created () {
    this.imgLoading = true
    this.initWebSocket()
    this.$store.dispatch('getProjectList')
    this.$store.dispatch('getMessageNum')
    // 初次进入系统默认第一个子集页面
    this.$store.dispatch('getMenu').then((url: string) => {
      url && this.$router.push(url)
    })
  }

  // 长链接
  initWebSocket () {
    const userId = JSON.parse(window.sessionStorage.getItem('userInfo') as any).userId
    if (userId) {
      const url = process.env.VUE_APP_WEBSOCKET + '?userId=' + userId
      this.websocket = new WebSocket(url)
      this.websocket.onmessage = this.websocketonmessage
      this.websocket.onerror = this.websocketonerror
      this.websocket.onclose = () => {
        console.log('websocket断开')
        // 断线重连
        this.initWebSocket()
      }
    }
  }

  // 消息接收
  websocketonmessage (e: {data: string}) {
    this.messageInfo = JSON.parse(e.data)
    if (this.notify) {
      this.notify.close()
    }
    if (this.messageInfo.templateLabel === '3') {
      this.audioPlayer = new Audio('./audio/three-level-tips.wav')
      this.audioPlayer.play()
    }
    // 4级提醒
    if (this.messageInfo.templateLabel === '4') {
      this.audioPlayer = new Audio('./audio/four-level-tips.wav')
      this.audioPlayer.play()
    }
    this.notify = this.$notify({
      title: this.messageInfo.title,
      message: this.messageInfo.msg,
      customClass: 'global-notifyMessageStyle',
      onClick: this.showMessageDetail,
      duration: 30000
    })
    // 消息数加一
    this.$store.commit('updateMessageNum', this.messageNum + 1)
  }

  showMessageDetail () {
    this.updateReadState(this.messageInfo.record)
    if (this.notify) {
      this.notify.close()
    }
    const route = this.messageInfo.url ? {
      path: this.messageInfo.url
    } : {
      name: 'warningDetail',
      params: {
        id: this.messageInfo.record
      }
    }
    this.$router.push(route)
    this.$store.commit('updateMessageNum', this.messageNum - 1)
  }

  // 标记单条消息已读
  updateReadState (id: string) {
    this.$axios.post(this.$apis.messageWarning.readMsg, { id: id })
  }

  // 链接失败
  websocketonerror () {
    this.initWebSocket()
  }

  // 告警消息点击
  toMessageCenter () {
    this.$router.push({ name: 'message' })
  }

  // 下拉点击事件
  handleCommand (v: string) {
    if (v === '1') {
      this.$router.push({
        name: 'userInfo'
      })
      this.clearMenuSessionInfo()
    } else if (v === '2') {
      this.loginOut()
    }
  }

  loginOut () {
    this.$axios.post(this.$apis.user.logout).then(() => {
      window.sessionStorage.removeItem('token')
      window.sessionStorage.removeItem('customerCode')
      window.sessionStorage.removeItem('isImgHtml')
      window.sessionStorage.removeItem('userInfo')
      this.clearMenuSessionInfo()
      this.$store.state.userInfo = {}
      this.$router.push('/login')
      this.$message.success('退出成功')
    })
  }

  clearMenuSessionInfo () {
    window.sessionStorage.removeItem('currentMenu')
    this.$store.state.currentMenu = -1
    sessionStorage.removeItem('menuList')
  }

  goToFront () {
    if (this.isImgHtml) {
      window.location.href = window.location.origin + this.isImgHtml
    } else {
      this.$router.push('/')
    }
    // 删除现在的缓存中的菜单
    this.clearMenuSessionInfo()
  }
}
