import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/toilet',
    name: 'toiletList',
    meta: {
      title: '异味监测'
    },
    component: () => import('@/views/toilet/List.vue')
  },
  {
    path: '/toilet/detail:projectId',
    name: 'toiletDetail',
    component: () => import('@/views/toilet/Detail.vue'),
    redirect: '/toilet',
    children: [
      {
        path: '/toilet/detail:projectId/map',
        name: 'toiletDetailMap',
        component: () => import('@/views/toilet/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/toilet/detail:projectId/list',
        name: 'toiletDetailList',
        component: () => import('@/views/toilet/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/toilet/detail:projectId/map/history:toiletId',
    name: 'toiletMapAnalysis',
    component: () => import('@/views/toilet/Analysis.vue'),
    meta: {
      title: '数据分析'
    }
  },
  {
    path: '/toilet/detail:projectId/list/history:toiletId',
    name: 'toiletListAnalysis',
    component: () => import('@/views/toilet/Analysis.vue'),
    meta: {
      title: '数据分析'
    }
  }
]

export default router
