import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/monitorList',
    name: 'monitorList',
    meta: {
      title: '智慧监控'
    },
    component: () => import('@/views/monitor/List.vue')
  },
  {
    path: '/monitorList/monitorDetail:projectId',
    name: 'monitorDetail',
    meta: {
      title: '详情'
    },
    redirect: '/monitorList',
    component: () => import('@/views/monitor/Detail.vue'),
    children: [{
      path: '/monitorList/monitorDetail:projectId/map',
      name: 'monitorMapDetail',
      meta: {
        title: '详情'
      },
      component: () => import('@/views/monitor/DetailMap.vue')
    }, {
      path: '/monitorList/monitorDetail:projectId/list',
      name: 'monitorListDetail',
      meta: {
        title: '详情'
      },
      component: () => import('@/views/monitor/DetailList.vue')
    }]
  }
]

export default router
