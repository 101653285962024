const baseUrl = '/business'
export interface Apis {
  /** 新增自动回答 */
  insertKfAnswer: string;
  /** 修改自动回答 */
  updateKfAnswer: string;
  /** 删除自动回答 */
  deleteKfAnswer: string;
  /** 查询自动回答详情 */
  selectKfAnswerById: string;
  /** 查询自动回答分页 */
  selectKfAnswerByPage: string;
  /** 查询自动回答分页 */
  insertKfPhone: string;
  /** 修改客服电话 */
  updateKfPhone: string;
  /** 查询客服电话列表 */
  selectKfPhoneByPage: string;
  /** 删除客服电话 */
  deleteKfPhone: string;
}
export const apis: Apis = {
  insertKfAnswer: baseUrl + '/kfAnswer/insertKfAnswer',
  updateKfAnswer: baseUrl + '/kfAnswer/updateKfAnswer',
  deleteKfAnswer: baseUrl + '/kfAnswer/deleteKfAnswer',
  selectKfAnswerById: baseUrl + '/kfAnswer/selectKfAnswerById',
  selectKfAnswerByPage: baseUrl + '/kfAnswer/selectKfAnswerByPage',
  insertKfPhone: baseUrl + '/kfPhone/insertKfPhone',
  updateKfPhone: baseUrl + '/kfPhone/updateKfPhone',
  selectKfPhoneByPage: baseUrl + '/kfPhone/selectKfPhoneByPage',
  deleteKfPhone: baseUrl + '/kfPhone/deleteKfPhone'
}
