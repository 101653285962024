// 接口名定义
export interface Apis {
  /** 分页 */
  selectBuildFacilitiesByPage: string;
  /** 详情 */
  selectBuildFacilitiesByBuildId: string;
  deleteBuildFacilities: string;
  updateBuildFacilities: string;
  insertBuildFacilities: string;
  /** 资源一张图前台-资源-建筑设施 */
  selectBuildFacilitiesByList: string;
  /** 资源一张图前台-资源-建筑设施-天人山水-菜单分布分页列表 */
  selectBuildPage: string;
  /** 资源一张图前台-资源-建筑设施-天人山水-地图分布不分页列表 */
  selectBuildList: string;
}
export const apis: Apis = {
  // 分页
  selectBuildFacilitiesByPage: '/operate/buildFacilities/selectBuildFacilitiesByPage',
  selectBuildFacilitiesByBuildId: '/operate/buildFacilities/selectBuildFacilitiesByBuildId',
  // 删除
  deleteBuildFacilities: '/operate/buildFacilities/deleteBuildFacilities',
  // 编辑
  updateBuildFacilities: '/operate/buildFacilities/updateBuildFacilities',
  // 新增
  insertBuildFacilities: '/operate/buildFacilities/insertBuildFacilities',
  selectBuildFacilitiesByList: '/operate/buildFacilities/selectBuildFacilitiesByList',
  selectBuildPage: '/operate/buildFacilities/selectBuildPage',
  selectBuildList: '/operate/buildFacilities/selectBuildList'
}
