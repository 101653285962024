import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/waterQuality',
    name: 'waterQualityList',
    meta: {
      title: '水质监测'
    },
    component: () => import('@/views/waterQuality/List.vue')
  },
  {
    path: '/waterQuality/detail:projectId',
    name: 'waterQualityDetail',
    component: () => import('@/views/waterQuality/Detail.vue'),
    redirect: '/waterQuality',
    children: [
      {
        path: '/waterQuality/detail:projectId/map',
        name: 'waterQualityDetailMap',
        component: () => import('@/views/waterQuality/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/waterQuality/detail:projectId/list',
        name: 'waterQualityDetailList',
        component: () => import('@/views/waterQuality/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/waterQuality/detail:projectId/map/dataAnalyse:deviceId',
    name: 'waterQualityMapDataAnalyse',
    component: () => import('@/views/waterQuality/DataAnalyse.vue'),
    meta: {
      title: '数据分析'
    }
  },
  {
    path: '/waterQuality/detail:projectId/list/dataAnalyse:deviceId',
    name: 'waterQualityListDataAnalyse',
    component: () => import('@/views/waterQuality/DataAnalyse.vue'),
    meta: {
      title: '数据分析'
    }
  }
]

export default router
