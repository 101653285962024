import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/deviceList',
    name: 'deviceList',
    meta: {
      title: '设备管理'
    },
    component: () => import('@/views/device/DeviceList.vue')
  },
  {
    path: '/deviceList/deviceDetail/:deviceId',
    name: 'deviceDetail',
    component: () => import('@/views/device/DeviceDetail.vue'),
    redirect: '/deviceList',
    meta: {
      title: '设备详情'
    },
    children: [
      {
        path: 'base',
        props: true,
        component: () => import('@/views/device/Base.vue'),
        meta: {
          title: '设备详情'
        }
      },
      {
        path: 'location',
        component: () => import('@/views/device/Location.vue'),
        meta: {
          title: '设备详情'
        }
      },
      {
        path: 'config',
        props: true,
        component: () => import('@/views/device/Config.vue'),
        meta: {
          title: '设备详情'
        }
      },
      {
        path: 'record',
        props: true,
        component: () => import('@/views/device/Record.vue'),
        meta: {
          title: '设备详情'
        }
      },
      {
        path: 'history',
        props: true,
        component: () => import('@/views/device/History.vue'),
        meta: {
          title: '设备详情'
        }
      }
    ]
  }
]

export default router
