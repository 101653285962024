export interface Apis {
  selectSmartMachineByPage: string;
  selectSmartMachineBySmartMachineId: string;
  deleteSmartMachine: string;
  updateSmartMachine: string;
  insertSmartMachine: string;
}

export const apis: Apis = {
  selectSmartMachineByPage: '/business/smartMachine/selectSmartMachineByPage', // 分页
  selectSmartMachineBySmartMachineId: '/business/smartMachine/selectSmartMachineBySmartMachineId', // 详情
  deleteSmartMachine: '/business/smartMachine/deleteSmartMachine', // 删除
  updateSmartMachine: '/business/smartMachine/updateSmartMachine', // 编辑
  insertSmartMachine: '/business/smartMachine/insertSmartMachine'// 新增
}
