export interface Apis {
  /** 分页查询苗木列表 */
  selectSeedlingByPage: string;
  /** 查询全部苗木列表 */
  selectSeedlingByList: string;
  /** 查询苗木详情 */
  selectSeedlingBySeedlingId: string;
  /** 删除苗木 */
  deleteSeedling: string;
  /** 新增苗木 */
  insertSeedling: string;
  /** 新增通过苗木名称查询苗木信息 */
  selectListByPlantName: string;
  /** 编辑苗木 */
  updateSeedling: string;
  /** 查询crm苗木列表 */
  selectPlantByList: string;
  /** 查询crm苗木详情 */
  selectPlantByPlantId: string;
  /** 不分页查询普通苗木合计列表（一张图资源） */
  selectSeedlingGroupByPlantId: string;
  /** 不分页查询古树名木列表（一张图资源） */
  selectFamousSeedlingByList: string;
  /** 分页查询普通苗木合计列表（一张图资源-天人山水） */
  selectCommonPage: string;
  /** 分页查询古树名木列表（一张图资源-天人山水） */
  selectFamousPage: string;
  /** 不分页查询普通苗木地图打点列表（一张图资源-天人山水） */
  selectCommonList: string;
  /** 不分页查询古树名木地图打点列表（一张图资源-天人山水） */
  selectFamousList: string;
  /** 查询苗木详情包含crm苗木详情及web苗木详情（一张图资源） */
  selectSeedlingInfo: string;
  /** 查询植物id名称列表 */
  selectPlantIdNameByList: string;
  /** 查询古树名木倾斜数据 */
  selectDumpDetail: string;
  /** 古树名木绑定倾斜设备 */
  bindFamousSeedling: string;
  /** 古树名木解除绑定倾斜设备 */
  unBindFamousSeedling: string;
  /** 查询未绑定古树的倾斜设备列表 */
  selectUnBindDumps: string;
  /** 导出 */
  exportSeedlingData: string;
  /** 存在苗木的项目列表 */
  selectProjectList: string;
  /** 查询苗木编码配置 */
  selectSeedlingCodeByList: string;
  /** 删除苗木编码配置 */
  deleteDataSeedlingCode: string;
  /** 新增/修改苗木编码配置 */
  insertSeedlingCode: string;
}

export const apis: Apis = {
  selectSeedlingByPage: '/operate/seedling/selectSeedlingByPage',
  selectSeedlingByList: '/operate/seedling/selectSeedlingByList',
  selectSeedlingBySeedlingId: '/operate/seedling/selectSeedlingBySeedlingId',
  deleteSeedling: '/operate/seedling/deleteSeedling',
  insertSeedling: '/operate/seedling/insertSeedling',
  selectListByPlantName: '/operate/seedling/selectListByPlantName',
  updateSeedling: '/operate/seedling/updateSeedling',
  selectPlantByList: '/crud/plant/selectPlantIdNameByList',
  selectPlantByPlantId: '/crud/plant/selectPlantByPlantId',
  selectSeedlingGroupByPlantId: '/operate/seedling/selectSeedlingGroupByPlantId',
  selectFamousSeedlingByList: '/operate/seedling/selectFamousSeedlingByList',
  selectCommonPage: '/operate/seedling/selectCommonPage',
  selectFamousPage: '/operate/seedling/selectFamousPage',
  selectCommonList: '/operate/seedling/selectCommonList',
  selectFamousList: '/operate/seedling/selectFamousList',
  selectSeedlingInfo: '/operate/seedling/selectSeedlingInfo',
  selectPlantIdNameByList: '/crud/plant/selectPlantIdNameByList',
  selectDumpDetail: '/operate/seedling/selectDumpDetail',
  bindFamousSeedling: '/operate/seedling/bindFamousSeedling',
  unBindFamousSeedling: '/operate/seedling/unBindFamousSeedling',
  selectUnBindDumps: '/operate/seedling/selectUnBindDumps',
  exportSeedlingData: '/operate/seedling/exportSeedlingData',
  selectProjectList: '/operate/seedling/selectProjectList',
  selectSeedlingCodeByList: '/operate/seedling/selectSeedlingCodeByList',
  deleteDataSeedlingCode: '/operate/seedling/deleteDataSeedlingCode',
  insertSeedlingCode: '/operate/seedling/insertSeedlingCode'
}
