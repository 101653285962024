import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../element.config'
import axios from '@/utils/ajax'
import { apis } from '@/api'
import 'vue-class-component/hooks'
import Component from 'vue-class-component'
import { ButtonShow } from './views/layout/menu/menuConfig'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

// 公共样式更改
import '@/assets/scss/reset.scss'
import '@/assets/icon/iconfont.js'

// 公共组件调用
import GlobalPagination from './components/pagination/GlobalPagination.vue'
import CustomUpload from './components/CustomUpload.vue'
import CustomExport from './components/customExport/Index.vue'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])
console.log('duration', duration)
dayjs.extend(duration)
Vue.component('GlobalPagination', GlobalPagination)
Vue.component('CustomUpload', CustomUpload)
Vue.component('CustomExport', CustomExport)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$apis = apis
Vue.prototype.$event = new Vue()
Vue.prototype.$config = process.env
Vue.prototype.$ButtonShow = ButtonShow
Vue.prototype.$dayjs = dayjs

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
