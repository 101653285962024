export interface Apis {
  // 获取登录失败次数
  failuresNum: string;
  // 获取角色列表
  getRoleList: string;
  // 获取角色用户列表
  getRoleUserList: string;
  // 获取省市区{label: '江苏省', children:[{label: '南京市', children:[{label: '江宁区'}...]}...]}
  getRegion: string;
  upload: string;
  // 七牛上传地址
  uploadQiniup: string;
  download: string;
  // 获取当前登录角色菜单列表
  getMenu: string;
  // 数据字典查询
  selectDicByList: string;

  selectDicByList2: string;
  // 获取导入文件模板地址
  selectTemplate: string;
  uploadToken: string;
  // 查询某种设备的项目列表 传deviceTypeId （1001 土壤传感器; 1002 视频监控; 1003 车辆监控; 1004 智能水表; 1005 智能电表; 1006 智能手环; 1007 电磁阀; 1008 终端定位; 1009 人脸考勤机; 1010 智能烟感; 1011 智能垃圾桶; 1012 空气质量监测;）
  selectDeviceProjectList: string;
   // 养护记录列表
  selectMaintainRecordByPage: string;
  /** 一张图服务 */
  selectWeatherPassengerData: string;
  /** 获取景观景点、服务设置地图位置 */
  selectSceneryAndFacilitiesList: string;
  /** 拉取单点登录链接 */
  selectSsotemUrl: string;
  /** 涂鸦取单点登录链接 */
  tuYaYunUserLogin: string;
}

export const apis: Apis = {
  failuresNum: '/user/custom/failuresNum',
  getRoleList: '/business/iparkRole/selectIparkRoleByList',
  getRoleUserList: '/business/iparkUser/selectIparkUserByList',
  getRegion: '/user/address',
  upload: '/user/file/upload',
  uploadQiniup: 'https://upload.qiniup.com',
  download: '/user/file/download',
  getMenu: '/business/iparkMenu/selectIparkMenuByList',
  selectDicByList: '/user/dic/selectDicByList',
  selectDicByList2: '/iparkAdmin/dic/selectDicByList',
  selectTemplate: '/user/selectTemplate',
  uploadToken: '/user/qiniu/getToken',
  selectDeviceProjectList: '/business/project/selectDeviceProjectList',
  selectMaintainRecordByPage: '/business/maintainRecord/selectMaintainRecordByPage',
  selectWeatherPassengerData: '/bi/resourcesPicture/selectWeatherPassengerData',
  selectSceneryAndFacilitiesList: '/business/serviceFacilities/selectSceneryAndFacilitiesList',
  selectSsotemUrl: '/user/selectSsotemUrl',
  tuYaYunUserLogin: '/user/tuYaYun/tuYaYunUserLogin'
}
