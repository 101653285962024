export interface Apis {
  selectSumProjectList: string; // 项目列表
  selectWeatherQuality: string; // 地图设备
  selectWeatherQualityPage: string; // 分页设备列表
  selectWeatherQualityDetail: string; // 空气质量数据分析图表
}
export const apis: Apis = {
  selectSumProjectList: '/business/weatherQuality/selectSumProjectList',
  selectWeatherQuality: '/business/weatherQuality/selectWeatherQuality',
  selectWeatherQualityPage: '/business/weatherQuality/selectWeatherQualityPage',
  selectWeatherQualityDetail: '/business/weatherQuality/selectWeatherQualityDetail'
}
