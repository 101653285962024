import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/inspection',
    name: 'inspectionList',
    meta: {
      title: '巡查工单'
    },
    component: () => import('@/views/inspection/List.vue')
  },
  {
    path: '/inspectionHeatMap',
    name: 'inspectionHeatMap',
    meta: {
      title: '巡查热力图'
    },
    component: () => import('@/views/inspection/HeatMap.vue')
  },
  {
    path: '/inspection/issueList:patrolId',
    name: 'issueList',
    meta: {
      title: '问题列表'
    },
    component: () => import('@/views/inspection/IssueList.vue')
  },
  {
    path: '/inspection/issueList:patrolId/issueAdd',
    name: 'issueAdd',
    meta: {
      title: '添加问题'
    },
    component: () => import('@/views/inspection/IssueAdd.vue')
  },
  {
    path: '/inspection/issueConfirm',
    name: 'issueConfirm',
    meta: {
      title: '问题确认'
    },
    component: () => import('@/views/inspection/IssueConfirm.vue')
  },
  {
    path: '/inspection/issueList:patrolId/issueDetail:issueId',
    name: 'issueDetail',
    meta: {
      title: '问题详情'
    },
    component: () => import('@/views/inspection/IssueDetail.vue')
  },
  {
    path: '/inspectionIssueList',
    name: 'inspectionIssueList',
    meta: {
      title: '问题列表'
    },
    component: () => import('@/views/inspectionList/Index.vue')
  },
  {
    path: '/inspectionIssueList/issueDetail:issueId',
    name: 'inspectionIssueDetail',
    meta: {
      title: '问题详情'
    },
    component: () => import('@/views/inspection/IssueDetail.vue')
  },
  {
    path: '/inspectionSignInRecord',
    name: 'inspectionSignInRecord',
    meta: {
      title: '签到记录'
    },
    component: () => import('@/views/inspection/SignInRecord.vue')
  }
]

export default router
