import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/serviceFacility',
    name: 'serviceFacilityList',
    meta: {
      title: '服务设施'
    },
    component: () => import('@/views/serviceFacility/List.vue')
  },
  {
    path: '/serviceFacility/add',
    name: 'serviceFacilityAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/serviceFacility/Add.vue')
  },
  {
    path: '/serviceFacility/edit:id',
    name: 'serviceFacilityEdit',
    component: () => import('@/views/serviceFacility/Add.vue'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/serviceFacility/detail',
    name: 'serviceFacilityDetail',
    component: () => import('@/views/serviceFacility/Detail.vue'),
    redirect: '/serviceFacility',
    meta: {
      title: '详情'
    },
    children: [
      {
        path: 'serviceDetail',
        props: true,
        component: () => import('@/views/serviceFacility/ServiceDetail.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: 'record',
        props: true,
        component: () => import('@/views/serviceFacility/Record.vue'),
        meta: {
          title: '详情'
        }
      }]
  }
]

export default router
