export interface Apis {
  // 项目手环列表
  summary: string;
  // 查询手环地图
  mapWorkerBracelet: string;
  // 查询手环列表（分页）
  listWorkerBracelet: string;
  // 健康数据汇总
  healthData: string;
  // 健康数据折线图
  lineHealthData: string;
  // sos解除
  cancelSos: string;
  // 查询未绑定手环列表
  listWatchListUnBind: string;
  // 绑定手环
  bindWatchWorker: string;
  // 解绑手环
  unBindWatchWorker: string;
  // 工人轨迹
  track: string;
  /** 修改在岗规则 */
  updateWorkerRules: string;
  /** 查询在岗规则详情 */
  selectWorkerRulesByProjectId: string;
  /** 分页查询在岗规则列表 */
  selectWorkerRulesByPage: string;
  /** 分页查询在岗统计列表 */
  selectWorkerDateByPage: string;
  /** 分页查询在岗统计列表(项目) */
  selectWorkerDateProjectByPage: string;
  /** 导出在岗统计 */
  exportWorkerProjectDate: string;
  /** 在岗统计图表全部 */
  selectWorkerDateByMap: string;
  /** 在岗统计图表 单日 */
  selectWorkerDateProjectByMap: string;
}
export const apis: Apis = {
  summary: '/business/watchWorker/selectSumProjectList',
  mapWorkerBracelet: '/business/watchWorker/selectWatchWorkerList',
  listWorkerBracelet: '/business/watchWorker/selectWatchWorkerPage',
  healthData: '/business/watchWorker/selectHealthData',
  lineHealthData: '/business/watchWorker/selectLineHealthData',
  cancelSos: '/business/watchWorker/updateCancelSos',
  listWatchListUnBind: '/business/watchWorker/selectWatchListUnBindlist',
  bindWatchWorker: '/business/watchWorker/updateBindWatchWorker',
  unBindWatchWorker: '/business/watchWorker/updateUnBindWatchWorker',
  track: '/business/watchWorker/selectTrack',
  updateWorkerRules: '/business/workerRules/updateWorkerRules',
  selectWorkerRulesByProjectId: '/business/workerRules/selectWorkerRulesByProjectId',
  selectWorkerRulesByPage: '/business/workerRules/selectWorkerRulesByPage',
  selectWorkerDateByPage: '/business/workerDate/selectWorkerDateByPage',
  selectWorkerDateProjectByPage: '/business/workerDate/selectWorkerDateProjectByPage',
  exportWorkerProjectDate: '/business/workerDate/exportWorkerProjectDate',
  selectWorkerDateByMap: '/business/workerDate/selectWorkerDateByMap',
  selectWorkerDateProjectByMap: '/business/workerDate/selectWorkerDateProjectByMap'
}
