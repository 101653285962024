// 喷灌
export interface Apis {
  irrigationProjectList: string;
  irrigationAreaAdd: string;
  irrigationAreaDevice: string;// 分页查询已绑定区域设备
  irrigationAreaDeviceNot: string; // 分页查询未绑定区域设备
  irrigationAreaDelete: string;// 删除喷灌区域,
  irrigationAreaDetail: string;// 查询区域详情
  irrigationPlanPage: string;// 分页查询计划列表
  irrigationAreaDevicePlanNot: string; // 查询当前区域下未绑定计划的设备列表
  irrigationAreaDevicePlan: string; // 查询当前区域下已绑定计划的设备列表
  irrigationPlanAdd: string; // 新增/修改计划
  irrigationPlanDelete: string; // 删除喷灌计划
  irrigationPlanDetail: string; // 查询计划详情
  irrigationPlanOpen: string; // 启用禁用计划
  selectAllDeviceList: string; // 查询所有设备列表
  selectUpdateAreaDeviceList: string; // 查询区域设备列表
  selectIrrigationProposal: string; // 根据区域id查询建议阈值
  /** 开启电磁阀 */
  operateStart: string;
  /** 查询电磁阀状态 */
  selectOperateStart: string;
  /** 关闭电磁阀 */
  operateShut: string;
}

export const apis: Apis = {
  irrigationProjectList: '/business/project/selectIrrigationProjectList',
  irrigationAreaAdd: '/business/irrigationAreaDevice/insertUpdateIrrigationArea',
  irrigationAreaDevice: '/business/irrigationAreaDevice/selectIrrigationAreaDevicePage',
  irrigationAreaDeviceNot: '/business/irrigationAreaDevice/selectNotBindIrrigationAreaDevicePage',
  irrigationAreaDelete: '/business/irrigationAreaDevice/deleteIrrigationArea',
  irrigationAreaDetail: '/business/irrigationAreaDevice/selectIrrigationArea',
  irrigationPlanPage: '/business/irrigationPlan/selectIrrigationPlanByPage',
  irrigationAreaDevicePlanNot: '/business/irrigationPlan/selectNotBindPlanDevice',
  irrigationAreaDevicePlan: '/business/irrigationAreaDevice/selectIrrigationAreaDeviceList',
  irrigationPlanAdd: '/business/irrigationPlan/insertUpdateIrrigationPlan',
  irrigationPlanDelete: '/business/irrigationPlan/deleteDataIrrigationPlan',
  irrigationPlanDetail: '/business/irrigationPlan/selectIrrigationPlanByPlanId',
  irrigationPlanOpen: '/business/irrigationPlan/enableIrrigationPlan',
  selectAllDeviceList: '/business/irrigationAreaDevice/selectAllDeviceList',
  selectUpdateAreaDeviceList: '/business/irrigationAreaDevice/selectUpdateAreaDeviceList',
  selectIrrigationProposal: '/business/irrigationPlan/selectIrrigationProposal',
  operateStart: '/business/irrigationPlan/operateStart',
  selectOperateStart: '/device/valve/selectOperateStart',
  operateShut: '/business/irrigationPlan/operateShut'
}
