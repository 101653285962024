// 种植估算
export interface Apis{
  /** 新增 */
  insertPlantEstimated: string;
  /** 分页 */
  selectPlantEstimatedByPage: string;
  /** 编辑 */
  updatePlantEstimated: string;
  /** 删除 */
  deletePlantEstimated: string;
  /** 详情 */
  selectPlantEstimatedByEstimatedId: string;
  /** 种植信息列表 */
  selectPlantMessByList: string;
  /** 估算 */
  estimatedCount: string;
  /** 录入 */
  updatePlantMessList: string;
  /** 导出 */
  exportEstimatedData: string;
  /** 字典新增 */
  insertPlantDictionary: string;
  /** 字典删除 */
  deleteDataPlantDictionary: string;
  /** 字典详情 */
  selectPlantDictionaryByList: string;
  /** 字典列表group */
  selectPlantDictionaryGroup: string;
}
export const apis: Apis = {
  insertPlantEstimated: '/business/plantEstimated/insertPlantEstimated',
  selectPlantEstimatedByPage: '/business/plantEstimated/selectPlantEstimatedByPage',
  updatePlantEstimated: '/business/plantEstimated/updatePlantEstimated',
  deletePlantEstimated: '/business/plantEstimated/deletePlantEstimated',
  selectPlantEstimatedByEstimatedId: '/business/plantEstimated/selectPlantEstimatedByEstimatedId',
  selectPlantMessByList: '/business/plantMess/selectPlantMessByList',
  estimatedCount: '/business/plantEstimated/estimatedCount',
  updatePlantMessList: '/business/plantMess/updatePlantMessList',
  exportEstimatedData: '/business/plantEstimated/exportEstimatedData',
  insertPlantDictionary: '/business/plantDictionary/insertPlantDictionary',
  deleteDataPlantDictionary: '/business/plantDictionary/deleteDataPlantDictionary',
  selectPlantDictionaryByList: '/business/plantDictionary/selectPlantDictionaryByList',
  selectPlantDictionaryGroup: '/business/plantDictionary/selectPlantDictionaryGroup'
}
