export interface Apis {
  warningPage: string;
  deteil: string;
  // 全部标记已读
  readAllMsg: string;
  // 单条消息标记已读
  readMsg: string;
}

export const apis: Apis = {
  warningPage: '/mc/msg/selectMyRecordPage',
  deteil: '/mc/msg/selectView',
  readAllMsg: '/mc/msg/readAllMsg',
  readMsg: '/mc/msg/readMsg'

}
