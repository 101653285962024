// 接口名定义
export interface Apis {
  // 获取项目列表
  selectSumProjectList: string;
  // 不分页查询垃圾桶列表
  selectTrashcan: string;
  // 分页查询垃圾桶列表
  selectTrashcanPage: string;
  // 垃圾桶详情
  selectTrashcanByDeviceId: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/trashcan/selectSumProjectList',
  selectTrashcan: '/business/trashcan/selectTrashcan',
  selectTrashcanPage: '/business/trashcan/selectTrashcanPage',
  selectTrashcanByDeviceId: '/business/trashcan/selectTrashcanByDeviceId'
}
