
import { Component, Vue, Watch } from 'vue-property-decorator'
import { MenuItem } from '@/types/menu'

@Component
export default class Menu extends Vue {
  private active: any = '/' + this.$route.path.split('/')[1]

  get menuList () {
    return this.$store.state.menuList
  }

  get menuLeftList () {
    const index = +this.$store.state.currentMenu
    return (this.menuList[index] && this.menuList[index].childNode) || []
  }

  @Watch('$route')
  routeChange (to: any) {
    this.active = '/' + to.path.split('/')[1]
    this.getButtonList('/' + to.path.split('/')[1], this.menuList)
  }

  created () {
    if (JSON.parse(sessionStorage.getItem('menuList') as string)) {
      this.getButtonList('/' + this.$route.path.split('/')[1], (JSON.parse(sessionStorage.getItem('menuList') as string)))
    }
  }

  getButtonList (index: string, menuList: Array<MenuItem>) {
    menuList.map((item: MenuItem) => {
      if (item.childNode) {
        this.getButtonList(index, item.childNode)
      } else if (item.url === index) {
        this.$store.commit('updateButtonList', item.button ? item.button : [])
      }
    })
  }
}
