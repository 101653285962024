import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/manhole',
    name: 'manholeList',
    meta: {
      title: '实时状态'
    },
    component: () => import('@/views/manhole/List.vue')
  },
  {
    path: '/manhole/detail:projectId',
    name: 'manholeDetail',
    component: () => import('@/views/manhole/Detail.vue'),
    redirect: '/manhole',
    children: [
      {
        path: '/manhole/detail:projectId/map',
        name: 'manholeDetailMap',
        component: () => import('@/views/manhole/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/manhole/detail:projectId/list',
        name: 'manholeDetailList',
        component: () => import('@/views/manhole/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/manhole/detail:projectId/list/history',
    name: 'manholeListHistory',
    component: () => import('@/views/manhole/History.vue'),
    meta: {
      title: '历史数据'
    }
  }
]

export default router
