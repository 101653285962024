import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/soilHumidityList',
    name: 'soilHumidityList',
    meta: {
      title: '土壤监测'
    },
    component: () => import('@/views/soilHumidity/List.vue')
  },
  {
    path: '/soilHumidityList/detail:projectId',
    name: 'soilHumidityDetail',
    component: () => import('@/views/soilHumidity/Detail.vue'),
    redirect: '/soilHumidityList',
    children: [
      {
        path: '/soilHumidityList/detail:projectId/map',
        name: 'soilHumidityDetailMap',
        component: () => import('@/views/soilHumidity/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/soilHumidityList/detail:projectId/list',
        name: 'soilHumidityDetailList',
        component: () => import('@/views/soilHumidity/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/soilHumidityList/detail:projectId/list/history:id&:type',
    name: 'soilHumidityListHistory',
    component: () => import('@/views/soilHumidity/History.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    path: '/soilHumidityList/detail:projectId/map/history:id&:type',
    name: 'soilHumidityMapHistory',
    component: () => import('@/views/soilHumidity/History.vue'),
    meta: {
      title: '历史数据'
    }
  }
]

export default router
