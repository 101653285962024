// 接口名定义
export interface Apis {
  /** 新增工程信息 */
  insertEngineeringInfo: string;
  /** 删除工程信息 */
  deleteEngineeringInfo: string;
  /** 查询工程信息详情 */
  selectEngineeringInfoByInfoId: string;
  /** 分页查看工程信息 */
  selectEngineeringInfoByPage: string;
  /** 查看工程信息列表 */
  selectEngineeringInfoByList: string;
  /** 编辑工程信息 */
  updateEngineeringInfo: string;
  /** 新增工程事件 */
  insertEngineeringEvent: string;
  /** 删除工程事件 */
  deleteEngineeringEvent: string;
  /** 处理工程事件 */
  updateEngineeringEvent: string;
  /** 分页查看工程事件 */
  selectEngineeringEventByPage: string;
  /** 查看工程事件详情 */
  selectEngineeringEventByEventsId: string;
  /** 负责人列表 */
  selectNoAdminList: string;
}

export const apis: Apis = {
  insertEngineeringInfo: '/business/engineeringInfo/insertEngineeringInfo',
  deleteEngineeringInfo: '/business/engineeringInfo/deleteEngineeringInfo',
  selectEngineeringInfoByInfoId: '/business/engineeringInfo/selectEngineeringInfoByInfoId',
  selectEngineeringInfoByPage: '/business/engineeringInfo/selectEngineeringInfoByPage',
  selectEngineeringInfoByList: '/business/engineeringInfo/selectEngineeringInfoByList',
  updateEngineeringInfo: '/business/engineeringInfo/updateEngineeringInfo',
  insertEngineeringEvent: 'business/engineeringEvent/insertEngineeringEvent',
  updateEngineeringEvent: 'business/engineeringEvent/updateEngineeringEvent',
  deleteEngineeringEvent: 'business/engineeringEvent/deleteEngineeringEvent',
  selectEngineeringEventByEventsId: 'business/engineeringEvent/selectEngineeringEventByEventsId',
  selectEngineeringEventByPage: 'business/engineeringEvent/selectEngineeringEventByPage',
  selectNoAdminList: 'business/engineeringEvent/selectNoAdminList'
}
