export interface Apis {
  updateCameraAlarm: string;
  selectCameraAlarmByAlarmId: string;
  selectCameraAlarmByPage: string;
}
export const apis: Apis = {
  // 处理监控报警事件
  updateCameraAlarm: '/business/smartMonitor/updateCameraAlarm',
  // 查询监控报警事件详情
  selectCameraAlarmByAlarmId: '/business/smartMonitor/selectCameraAlarmByAlarmId',
  // 分页查询监控报警事件列表
  selectCameraAlarmByPage: '/business/smartMonitor/selectCameraAlarmByPage'
}
