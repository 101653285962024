// 人员
export interface Apis{
  personnelPage: string;
  personnelAdd: string;
  personnelUpdate: string;
  personnelDetail: string;
  personnelDisabled: string;
  personnelDelete: string;
  resetPassword: string;
}
const url = '/business/iparkUser/'
export const apis: Apis = {
  personnelPage: url + 'selectIparkUserByPage',
  personnelAdd: url + 'insertIparkUser',
  personnelUpdate: url + 'updateIparkUser',
  personnelDetail: url + 'selectIparkUserByUserId',
  personnelDisabled: url + 'deleteIparkUser',
  personnelDelete: url + 'eliminateIparkUser',
  resetPassword: url + 'resetPassword'
}
