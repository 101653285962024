export interface Apis {
  selectSumProjectList: string;
  selectInsect: string;
  selectInsectPage: string;
  selectInsectInfo: string;
  selectInsectInfoPage: string;
  selectInsectImgPage: string;
  selectInsectImgResultByList: string;
  selectInsectStatistics: string;
  selectInsectInfoLineChart: string;
  selectDaySevenNew: string;
  selectAnalysis: string;
  selectFourQuadrants: string;
  selectInsectImgResultById: string;
  selectDeviceProjectList: string;
  selectInsectList: string;
  selectInsectImgResultByImgId: string;
  selectPestsRecommendedByInsect: string;
  selectMedicineByInsect: string;
  selectVideoByInsect: string;
  selectWorkGuideByInsect: string;
  addMedicineReadingCounts: string;
  addVideoPlayCounts: string;
  addVideoLikeCounts: string;
  addWorkGuideReadingCounts: string;
  updateInsectSwitch: string;
  selectInsectConfigByList: string;
}
export const apis: Apis = {
  selectSumProjectList: '/business/insect/selectSumProjectList', // 项目列表
  selectInsect: '/business/insect/selectInsect', // 地图
  selectInsectPage: '/business/insect/selectInsectPage', // 详情列表
  selectInsectInfo: '/device/insect/selectInsectInfo', // 查询设备信息当前最新数据
  selectInsectInfoPage: '/device/insect/selectInsectInfoPage', // 分页虫情设备信息数据
  selectInsectImgPage: '/device/insect/selectInsectImgPage', // 分页虫情设备图片数据
  selectInsectImgResultByList: '/device/insect/selectInsectImgResultByList', // 查询虫情设备图片轮播数据
  selectInsectStatistics: '/device/insect/selectInsectStatistics', // 查询虫情结果统计
  selectInsectInfoLineChart: '/device/insect/selectInsectInfoLineChart', // 查询设备历史数据折线图
  selectDaySevenNew: '/bi/insectAnalysis/selectDaySevenNew', // 虫情分析获取最近7天数据
  selectAnalysis: '/bi/insectAnalysis/selectAnalysis', // 获取病虫害30天虫害数量占比
  selectFourQuadrants: '/bi/insectAnalysis/selectFourQuadrants', // 获取病虫害统计4象限数据
  selectInsectImgResultById: '/device/insect/selectInsectImgResultById', // 查询虫情设备图片结果
  selectDeviceProjectList: '/business/project/selectDeviceProjectList', // 获取项目
  selectInsectList: '/device/insect/selectInsectList', // 虫情历史监测
  selectInsectImgResultByImgId: '/device/insect/selectInsectImgResultByImgId', // 虫情详情
  selectPestsRecommendedByInsect: '/crud/pests/selectPestsRecommendedByInsect', // 虫害推荐
  selectMedicineByInsect: '/crud/medicine/selectMedicineByInsect', // 药剂推荐
  addMedicineReadingCounts: '/crud/medicine/addMedicineReadingCounts', // 药剂增加阅读量详情
  selectVideoByInsect: '/crud/video/selectVideoByInsect', // 微课视频推荐
  addVideoPlayCounts: '/crud/video/addVideoPlayCounts', // 微课堂视频增加阅读量 详情
  addVideoLikeCounts: '/crud/video/addVideoLikeCounts', // 视频点赞/取消
  selectWorkGuideByInsect: '/crud/workGuide/selectWorkGuideByInsect', // 作业指导推荐
  addWorkGuideReadingCounts: '/crud/workGuide/addWorkGuideReadingCounts', // 指导书阅读量 详情
  updateInsectSwitch: '/business/insect/updateInsectSwitch', // 开关控制
  selectInsectConfigByList: '/device/insectConfig/selectInsectConfigByList'
}
