import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/visitorInfoList',
    name: 'visitorInfoList',
    meta: {
      title: '游客信息'
    },
    component: () => import('@/views/visitorInfo/List.vue')
  },
  {
    path: '/visitorFeedbackList',
    name: 'visitorFeedbackList',
    meta: {
      title: '游客反馈'
    },
    component: () => import('@/views/visitorInfo/VisitorFeedback.vue')
  },
  {
    path: '/visitorFeedbackList/detail:id',
    name: 'visitorFeedbackDetail',
    meta: {
      title: '反馈详情'
    },
    component: () => import('@/views/visitorInfo/VisitorFeedbackDetail.vue')
  },
  {
    path: '/citizenFeedbackList',
    name: 'citizenFeedbackList',
    meta: {
      title: '市民反馈'
    },
    component: () => import('@/views/visitorInfo/CitizenFeedback.vue')
  },
  {
    path: '/citizenFeedbackList/detail:id',
    name: 'citizenFeedbackDetail',
    meta: {
      title: '反馈详情'
    },
    component: () => import('@/views/visitorInfo/CitizenFeedbackDetail.vue')
  },
  {
    path: '/citizenFeedbackList/orderDetail:id',
    name: 'citizenFBOrderDetail',
    meta: {
      title: '工单详情'
    },
    component: () => import('@/views/newOrder/Detail.vue')
  }
]

export default router
