
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getIndexPath } from '@/utils/formatData'
@Component
export default class TopBar extends Vue {
  currentIndex = +this.$store.state.currentMenu || 0
  get menuList () {
    return this.$store.state.menuList
  }

  @Watch('$store.state.currentMenu')
  getcurrentIndex (val: number) {
    this.currentIndex = val
  }

  @Watch('$route')
  routeChange (to: any) {
    // 快捷方式跳转
    this.getMenuIndex(this.menuList, '', to.path)
  }

  getMenuIndex (list: any, menuIndex: string, url: any) {
    list.forEach((item: any, index: number) => {
      if (item.childNode) {
        this.getMenuIndex(item.childNode, menuIndex.toString() + index.toString(), url)
      } else {
        if (item.url === url || item.url === '/' + url.split('/')[1]) {
          const current: string = menuIndex + index
          this.currentIndex = +current.substring(0, 1)
          this.$store.commit('setCurrentMenu', this.currentIndex)
        }
      }
    })
  }

  created () {
    // 1
  }

  change (val: number) {
    this.currentIndex = val
    this.$store.commit('setCurrentMenu', val)
    this.$router.push(getIndexPath([this.menuList[val]]))
  }

  // 顶部icon 未选择_nor
  getImgUrl (name: string, index: number) {
    return require('../../../assets/img/layout/' + (this.currentIndex === index ? name.split('_nor')[0] + '.png' : name + '.png'))
  }
}
