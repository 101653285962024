// 接口名定义
export interface Apis {
  /** 分页 */
  selectCateFoodByPage: string;
  /** 新增 */
  insertCateFood: string;
  /** 编辑 */
  updateCateFood: string;
  /** 详情 */
  selectCateFoodById: string;
  /** 删除 */
  deleteCateFood: string;
}

export const apis: Apis = {
  selectCateFoodByPage: '/operate/cateFood/selectCateFoodByPage',
  insertCateFood: '/operate/cateFood/insertCateFood',
  updateCateFood: '/operate/cateFood/updateCateFood',
  selectCateFoodById: '/operate/cateFood/selectCateFoodById',
  deleteCateFood: '/operate/cateFood/deleteCateFood'
}
