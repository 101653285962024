import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/weatherQuality',
    name: 'weatherQuality',
    meta: {
      title: '环境监测'
    },
    component: () => import('@/views/weatherQuality/List.vue')
  },
  {
    path: '/weatherQuality/detail:projectId',
    name: 'weatherQualityDetail',
    component: () => import('@/views/weatherQuality/Detail.vue'),
    redirect: '/weatherQuality',
    children: [
      {
        path: '/weatherQuality/detail:projectId/map',
        name: 'weatherQualityDetailMap',
        component: () => import('@/views/weatherQuality/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/weatherQuality/detail:projectId/list',
        name: 'weatherQualityDetailList',
        component: () => import('@/views/weatherQuality/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/weatherQuality/detail:projectId/map/dataAnalyse:deviceId',
    name: 'weatherQualityMapDataAnalyse',
    component: () => import('@/views/weatherQuality/DataAnalyse.vue'),
    meta: {
      title: '数据分析'
    }
  },
  {
    path: '/weatherQuality/detail:projectId/list/dataAnalyse:deviceId',
    name: 'weatherQualityListDataAnalyse',
    component: () => import('@/views/weatherQuality/DataAnalyse.vue'),
    meta: {
      title: '数据分析'
    }
  }
]
export default router
