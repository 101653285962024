import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/odor',
    name: 'odorList',
    meta: {
      title: '异味监测'
    },
    component: () => import('@/views/odor/List.vue')
  },
  {
    path: '/odor/detail:projectId',
    name: 'odorDetail',
    component: () => import('@/views/odor/Detail.vue'),
    redirect: '/odor',
    children: [
      {
        path: '/odor/detail:projectId/map',
        name: 'odorDetailMap',
        component: () => import('@/views/odor/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/odor/detail:projectId/list',
        name: 'odorDetailList',
        component: () => import('@/views/odor/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/odor/detail:projectId/map/history:deviceId',
    name: 'odorMapHistory',
    component: () => import('@/views/odor/History.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    path: '/odor/detail:projectId/list/history:deviceId',
    name: 'odorListHistory',
    component: () => import('@/views/odor/History.vue'),
    meta: {
      title: '历史数据'
    }
  }
]

export default router
