
export interface Apis {
  /** 分页 */
  selectDispatchScenariosByPage: string;
  /** 删除 */
  deleteDispatchScenarios: string;
  /** 编辑 */
  updateDispatchScenarios: string;
  /** 新增 */
  insertDispatchScenarios: string;
  /** 详情 */
  selectDispatchScenariosByScenariosId: string;
  /** 小组成员列表 */
  selectUserListByList: string;

}

export const apis: Apis = {
  selectDispatchScenariosByPage: '/business/dispatchScenarios/selectDispatchScenariosByPage',
  deleteDispatchScenarios: '/business/dispatchScenarios/deleteDispatchScenarios',
  updateDispatchScenarios: '/business/dispatchScenarios/updateDispatchScenarios',
  insertDispatchScenarios: '/business/dispatchScenarios/insertDispatchScenarios',
  selectDispatchScenariosByScenariosId: '/business/dispatchScenarios/selectDispatchScenariosByScenariosId',
  selectUserListByList: '/business/iparkUser/selectUserListByList'
}
