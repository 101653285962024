import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/trash',
    name: 'trashList',
    meta: {
      title: '智慧垃圾桶'
    },
    component: () => import('@/views/trash/List.vue')
  },
  {
    path: '/trash/detail:projectId',
    name: 'trashDetail',
    component: () => import('@/views/trash/Detail.vue'),
    redirect: '/trash',
    children: [
      {
        path: '/trash/detail:projectId/map',
        name: 'trashDetailMap',
        component: () => import('@/views/trash/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/trash/detail:projectId/list',
        name: 'trashDetailList',
        component: () => import('@/views/trash/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/trash/detail:projectId/list/trashcanDetail:deviceId',
    name: 'trashcanDetail',
    component: () => import('@/views/trash/trashcanDetail.vue'),
    meta: {
      title: '垃圾桶详情'
    }
  }
]

export default router
