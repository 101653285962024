import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/waterMeter',
    name: 'waterMeterList',
    meta: {
      title: '智慧水表'
    },
    component: () => import('@/views/waterMeter/List.vue')
  },
  {
    path: '/waterMeter/detail:projectId',
    name: 'waterMeterDetail',
    component: () => import('@/views/waterMeter/Detail.vue'),
    redirect: '/waterMeter',
    children: [
      {
        path: '/waterMeter/detail:projectId/map',
        name: 'waterMeterDetailMap',
        component: () => import('@/views/waterMeter/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/waterMeter/detail:projectId/list',
        name: 'waterMeterDetailList',
        component: () => import('@/views/waterMeter/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/waterMeter/forecast:projectId',
    name: 'waterMeterForecast',
    component: () => import('@/views/waterMeter/Forecast.vue'),
    meta: {
      title: '能耗预测'
    }
  },
  {
    path: '/waterMeter/detail:projectId/map/history:deviceId',
    name: 'waterMeterMapHistory',
    component: () => import('@/views/waterMeter/History.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    path: '/waterMeter/detail:projectId/list/history:deviceId',
    name: 'waterMeterListHistory',
    component: () => import('@/views/waterMeter/History.vue'),
    meta: {
      title: '历史数据'
    }
  }
]

export default router
