// 一张图养护模块接口
import { apis as bracelet } from '@/api/bracelet'
import { apis as location } from '@/api/location'
import { apis as soilHumidity } from '@/api/soilHumidity'
import { apis as waterQuality } from '@/api/waterQuality'
import { apis as insectMonitor } from '@/api/insectMonitor'
import { apis as trash } from '@/api/trash'

export interface Apis {
  /** 养护工人列表 */
  mapWorkerBracelet: string;
  /** 养护车辆列表 */
  selectLocationAndMachineList: string;
  /** 车辆运行时长统计 */
  selectLongTime: string;
  /** 土壤温湿与土壤肥力设备列表 */
  selectSoilWithFertilityMap: string;
  /** 土壤温湿变化统计 */
  selectSoilWithMap: string;
  /** 土壤肥力变化统计 */
  selectSoilFertilityMap: string;
  /** 水质监测列表 */
  selectWaterQuality: string;
  /** 虫情监测设备列表 */
  selectInsect: string;
  /** 虫情监测图片轮播数据 */
  selectInsectImgResultByList: string;
  /** 虫情监测统计分析 */
  selectAll: string;
  /** 垃圾监测设备列表 */
  selectTrashcan: string;
  /** 智能机械列表 */
  selectSmartMachineByPageForLocation: string;
  /** 喷灌统计分析 */
  irrigationSelectAll: string;
  /** 查询喷灌计划 */
  selectIrrigationPlanByList: string;
  /** 查询喷灌列表 */
  selectValveList: string;
}

export const apis: Apis = {
  mapWorkerBracelet: bracelet.listWorkerBracelet,
  selectLocationAndMachineList: location.locationMachineListPage,
  selectLongTime: '/business/location/selectLongTime',
  selectSoilWithFertilityMap: '/business/soilWith/selectSoilWithFertilityMap',
  selectSoilWithMap: soilHumidity.selectSoilWithMap,
  selectSoilFertilityMap: '/business/soilFertility/selectSoilFertilityMap',
  selectWaterQuality: waterQuality.selectWaterQuality,
  selectInsect: insectMonitor.selectInsect,
  selectInsectImgResultByList: '/device/insect/selectInsectImgResultByList',
  selectAll: '/bi/insectAnalysis/selectAll',
  selectTrashcan: trash.selectTrashcan,
  selectSmartMachineByPageForLocation: '/business/smartMachine/selectSmartMachineByPageForLocation',
  irrigationSelectAll: '/bi/irrigationStatistics/selectAll',
  selectIrrigationPlanByList: '/business/irrigationPlan/selectIrrigationPlanByList',
  selectValveList: '/business/device/selectValveList'
}
