// 接口名定义
export interface Apis {
  /** 分页 */
  selectTopicActivitiesByPage: string;
  /** 新增 */
  insertTopicActivities: string;
  /** 编辑 */
  updateTopicActivities: string;
  /** 详情 */
  selectTopicActivitiesById: string;
  /** 删除 */
  deleteTopicActivities: string;
}

export const apis: Apis = {
  selectTopicActivitiesByPage: '/operate/topicActivities/selectTopicActivitiesByPage',
  insertTopicActivities: '/operate/topicActivities/insertTopicActivities',
  updateTopicActivities: '/operate/topicActivities/updateTopicActivities',
  selectTopicActivitiesById: '/operate/topicActivities/selectTopicActivitiesById',
  deleteTopicActivities: '/operate/topicActivities/deleteTopicActivities'
}
