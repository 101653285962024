import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/plantEstimation',
    name: 'plantEstimation',
    meta: {
      title: '种植估算'
    },
    component: () => import('@/views/plantEstimation/List.vue')
  },
  {
    path: '/plantEstimation/add',
    name: 'plantEstimationAdd',
    meta: {
      title: '种植估算新增'
    },
    component: () => import('@/views/plantEstimation/Add.vue')
  },
  {
    path: '/plantEstimation/edit:id',
    name: 'plantEstimationEdit',
    component: () => import('@/views/plantEstimation/Add.vue'),
    meta: {
      title: '种植估算编辑'
    }
  },
  {
    path: '/plantEstimation/Actual',
    name: 'plantEstimationActual',
    meta: {
      title: '录入实际量'
    },
    component: () => import('@/views/plantEstimation/Actual.vue')
  },
  {
    path: '/plantEstimation/Detail:id',
    name: 'plantEstimationDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/plantEstimation/Detail.vue')
  },
  {
    path: '/plantEstimation/PlantDic',
    name: 'plantEstimationPlantDic',
    meta: {
      title: '植物字典'
    },
    component: () => import('@/views/plantEstimation/PlantDic.vue')
  }
]

export default router
