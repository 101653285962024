// 接口名定义
const baseUrl = '/business/fireFighting/'
export interface Apis {
  /** 新增消防栓 */
  insertFireFighting: string;
  /** 修改消防栓 */
  updateFireFighting: string;
  /** 删除消防栓 */
  deleteFireFighting: string;
  /** 分页查询消防栓列表 */
  selectFireFightingByPage: string;
  /** 查询消防栓详情 */
  selectFireFightingByFightingId: string;
  /** 消防栓项目汇总 */
  selectSumProjectList: string;
  /** 消防栓（地图） */
  selectFireFightingInfoMap: string;
  /** 分页查询消防栓列表 */
  selectFireFightingInfoList: string;
  /** 查询项目中未绑定的消防栓设备 */
  selectFireFightingDeviceListUnBindlist: string;
  /** 查询消防栓绑定的消防栓设备列表 */
  selectFireFightingDeviceListBindlist: string;
  /** 解绑消防栓设备 */
  updateUnBindFireFighting: string;
  /** 绑定消防栓设备 */
  updateBindFireFighting: string;
  /** 查询消防栓设备类型列表 */
  selectFireFightingDeviceTypeList: string;
}

export const apis: Apis = {
  insertFireFighting: baseUrl + 'insertFireFighting',
  updateFireFighting: baseUrl + 'updateFireFighting',
  deleteFireFighting: baseUrl + 'deleteFireFighting',
  selectFireFightingByPage: baseUrl + 'selectFireFightingByPage',
  selectFireFightingByFightingId: baseUrl + 'selectFireFightingByFightingId',
  selectSumProjectList: baseUrl + 'selectSumProjectList',
  selectFireFightingInfoMap: baseUrl + 'selectFireFightingInfoMap',
  selectFireFightingInfoList: baseUrl + 'selectFireFightingInfoList',
  selectFireFightingDeviceListUnBindlist: baseUrl + 'selectFireFightingDeviceListUnBindlist',
  selectFireFightingDeviceListBindlist: baseUrl + 'selectFireFightingDeviceListBindlist',
  updateUnBindFireFighting: baseUrl + 'updateUnBindFireFighting',
  updateBindFireFighting: baseUrl + 'updateBindFireFighting',
  selectFireFightingDeviceTypeList: baseUrl + 'selectFireFightingDeviceTypeList'
}
