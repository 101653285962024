import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  {
    path: '/theme',
    name: 'themeList',
    component: () => import('@/views/theme/List.vue'),
    meta: {
      title: '主题活动'
    }
  },
  {
    path: '/theme/add',
    name: 'themeAdd',
    component: () => import('@/views/theme/Add.vue'),
    meta: {
      title: '新增'
    }
  },
  {
    path: '/theme/update:id',
    name: 'themeUpdate',
    component: () => import('@/views/theme/Add.vue'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/theme/detail:id',
    name: 'themeDetail',
    component: () => import('@/views/theme/Detail.vue'),
    meta: {
      title: '详情'
    }
  }
]

export default router
