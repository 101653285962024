import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/rareTreesBase',
    name: 'rareTreesBase',
    component: () => import('@/views/rareTrees/Base.vue'),
    redirect: '/rareTreesBase/list',
    children: [{
      name: 'rareTreesList',
      path: '/rareTreesBase/list',
      component: () => import('@/views/rareTrees/List.vue'),
      meta: {
        title: '古树名木',
        hasBack: false
      }
    }, {
      path: '/rareTreesBase/map',
      name: 'rareTreesMap',
      component: () => import('@/views/rareTrees/Map.vue'),
      meta: {
        title: '古树名木',
        hasBack: false
      }
    }]
  },
  {
    path: '/rareTreesBase/list/add',
    name: 'rareTreesAdd',
    component: () => import('@/views/rareTrees/Add.vue'),
    meta: {
      title: '新增'
    }
  },
  {
    path: '/rareTreesBase/list/edit:seedlingId',
    name: 'rareTreesEdit',
    component: () => import('@/views/rareTrees/Add.vue'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/rareTreesBase/list/detail:seedlingId',
    name: 'rareTreesDetailList',
    component: () => import('@/views/rareTrees/PlantDetail.vue'),
    meta: {
      title: '列表-详情'
    }
  },
  {
    path: '/rareTreesBase/map/detail:seedlingId',
    name: 'rareTreesDetailMap',
    component: () => import('@/views/rareTrees/PlantDetail.vue'),
    meta: {
      title: '地图-详情'
    }
  }

]

export default router
