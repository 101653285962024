// 接口名定义
export interface Apis {
  // 批量分配
  batchAllocateDevice: string;
  // 回收设备
  recycleDevice: string;
  // 查询设备详情
  selectDeviceByDeviceId: string;
  // 分页查询设备列表
  selectDeviceByPage: string;
  // 获取设备类型列表
  getDeviceTypeList: string;
  // 获取土壤设备配置信息
  selectSoilConfigByDeviceId: string;
  getSpecies: string;
  // 修改设备经纬坐标
  updateDeviceSelective: string;
  // 更新土壤设备配置
  updateSoilConfig: string;
  // 获取土壤设备配置
  getSoilConfig: string;
  // 查询设备分配/回收记录
  getDeviceAllotProjectByPage: string;
  // 修改设备
  getUpdateDevice: string;
  // 视频监控历史数据
  getCameraData: string;
  // 获取肥力传感器配置
  getFertilityConfig: string;
  // 修改肥力传感器配置
  updateFertilityConfig: string;
  // 获取垃圾桶配置
  selectTrashcanConfigById: string;
  // 新增垃圾桶配置
  insertTrashcanConfig: string;
  // 修改垃圾桶配置
  updateTrashcanConfig: string;
  /** 资源一张图前台-资源-智能设备 */
  selectDeviceTypeCountList: string;
  /** 根据设备类型获取设备列表 */
  selectDeviceByList: string;
  /** 获取倾斜设备信息 */
  selectDumpConfById: string;
  /** 保存倾斜设备信息 */
  insertDumpConf: string;
  /** 保存井盖配置 */
  insertManholeConf: string;
  /** 查询井盖配置详情 */
  selectManholeConfById: string;
  /** 新增水尺设备配置 */
  insertDraftConfig: string;
  /** 查询水尺设备配置 */
  selectDraftConfigById: string;
  /** 获取设备历史数据 */
  getHistoryByTypeId: (typeId: string) => string;
}

export const apis: Apis = {
  batchAllocateDevice: '/device/toCustomer/device/batchAllocate',
  recycleDevice: '/device/toCustomer/device/batchRecycle',
  selectDeviceByPage: '/device/toCustomer/device/selectDeviceByPage',
  selectDeviceByDeviceId: '/device/toCustomer/device/selectDeviceByDeviceId',
  getDeviceTypeList: '/device/deviceType/selectDeviceTypeByList',
  selectSoilConfigByDeviceId: '/device/toCustomer/device/selectSoilConfigByDeviceId',
  getSpecies: '/device/soilConfig/getSpeciesList',
  updateDeviceSelective: '/device/toCustomer/device/updateDeviceSelective',
  updateSoilConfig: '/device/toCustomer/device/insertUpdateSoilConfig',
  getSoilConfig: '/device/toCustomer/device/selectSoilConfigByDeviceId',
  getDeviceAllotProjectByPage: '/device/toCustomer/device/selectDeviceAllotProjectByPage',
  getUpdateDevice: '/business/device/updateDevice',
  getCameraData: '/device/camera/selectCameraByDeviceId',
  // 获取土壤肥力设备配置
  getFertilityConfig: 'device/fertilityConfig/selectFertilityConfigByDeviceId',
  updateFertilityConfig: 'device/fertilityConfig/insertUpdateFertilityConfig',
  // 垃圾桶设备配置
  selectTrashcanConfigById: '/device/trashcan/selectTrashcanConfigById',
  insertTrashcanConfig: '/device/trashcan/insertTrashcanConfig',
  updateTrashcanConfig: '/device/trashcan/updateTrashcanConfig',
  selectDeviceTypeCountList: '/business/deviceType/selectDeviceTypeCountList',
  selectDeviceByList: '/device/toCustomer/device/selectDeviceByList',
  selectDumpConfById: '/device/dumpConf/selectDumpConfById',
  insertDumpConf: '/device/dumpConf/insertDumpConf',
  insertManholeConf: '/device/manholeConf/insertManholeConf',
  selectManholeConfById: '/device/manholeConf/selectManholeConfById',
  // 水尺设备配置
  insertDraftConfig: '/business/draftConfig/insertDraftConfig',
  selectDraftConfigById: '/business/draftConfig/selectDraftConfigById',

  getHistoryByTypeId: typeId => {
    const type = new Map<string, string>()
    type.set('1001', '/device/toCustomer/device/getReportData')
    type.set('1003', '/device/toCustomer/device/getReportData')
    type.set('1004', '/device/toCustomer/device/getReportData')
    type.set('1006', '/device/toCustomer/device/getReportData')
    type.set('1007', '/device/toCustomer/device/getReportData')
    type.set('1008', '/device/toCustomer/device/getReportData')
    type.set('1009', '/device/toCustomer/device/getReportData')
    type.set('1010', '/device/toCustomer/device/getReportData')
    type.set('1011', '/device/trashcan/selectTrashcanByPage')
    type.set('1012', '/device/toCustomer/device/getReportData')
    type.set('1013', '/device/toCustomer/device/getReportData')
    type.set('1014', '/device/toCustomer/device/getReportData')
    type.set('1015', '/device/toCustomer/device/getReportData')
    type.set('1017', '/device/insect/selectInsectInfoPage')
    type.set('1019', '/device/sumec/selectSumecHisByPage')
    type.set('1020', '/device/footpathInfo/selectFootpathInfoByList')
    type.set('1021', '/device/dumpInfo/selectDumpInfoByList')
    type.set('1022', '/device/manholeData/selectManholeDataByPage')
    type.set('1023', '/device/workCard/selectWorkCardByPage')
    type.set('1024', '/business/draft/selectDraftHistoryByPage')
    type.set('1025', '/device/control/selectControlByPage')
    type.set('1026', '/device/fireFightingM/selectFireFightingMByPage')
    type.set('1027', '/device/fireFightingB/selectFireFightingBByPage')
    type.set('1028', '/device/toCustomer/device/getReportData')
    type.set('1030', '/business/gas/selectGasDataByPage')
    type.set('1031', '/device/smartToilet/selectSmartToiletDataByPage')
    return type.get(typeId) || ''
  }
}
