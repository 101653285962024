export interface Apis {
    /** 项目管理 */
    project: string;
    /** 人员管理 */
    worker: string;
    /** 人员管理时间维度统计 */
    workerByTime: string;
    /** 巡查管理 */
    patrol: string;
    /** 设备管理 */
    device: string;
    /** 养护管理 */
    flowOrder: string;
    /** 资源统计 */
    resource: string;
    /** 消息通知 */
    message: string;
    /** 快捷菜单操作 */
    save: string;
    /** 查询快捷菜单列表 */
    selectMenuQuickconfigByList: string;
    /** 查询数据展示权限 */
    selectMenuDataShowList: string;
  }

export const apis: Apis = {
  project: '/business/projectStatistic/project',
  worker: '/business/projectStatistic/worker',
  workerByTime: '/business/projectStatistic/workerByTime',
  patrol: '/business/projectStatistic/patrol',
  device: '/business/projectStatistic/device',
  flowOrder: '/business/projectStatistic/flowOrder',
  resource: '/business/projectStatistic/resource',
  message: 'business/projectStatistic/message',
  save: '/business/menuQuickconfig/save',
  selectMenuQuickconfigByList: '/business/menuQuickconfig/selectMenuQuickconfigByList',
  selectMenuDataShowList: '/business/projectStatistic/selectMenuDataShowList'
}
