import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/irrigationmodelsetting',
    meta: {
      title: '智慧模型配置'
    },
    component: () => import('@/views/irrigation/IrrigationModelSetting.vue')
  },
  {
    path: '/irrigationmodelsetting/detail',
    meta: {
      title: '智慧模型配置'
    },
    redirect: '/irrigationmodelsetting',
    component: () => import('@/views/irrigation/IrrigationDetail.vue'),
    children: [
      {
        path: 'setting',
        component: () => import('@/views/irrigation/IrrigationSetting.vue'),
        meta: {
          title: '智慧模型配置'
        }
      },
      {
        path: 'timingplan',
        component: () => import('@/views/irrigation/IrrigationTimingPlan.vue'),
        meta: {
          title: '智慧模型配置'
        }
      },
      {
        path: 'smartplan',
        component: () => import('@/views/irrigation/IrrigationSmartPlan.vue'),
        meta: {
          title: '智慧模型配置'
        }
      }
    ]
  },
  {
    path: '/irrigationmodelsetting/addArea',
    name: 'irrigationAddArea',
    component: () => import('@/views/irrigation/IrrigationArea.vue'),
    meta: {
      title: '新增区域',
      hasQuery: true,
      backUrl: '/irrigationmodelsetting/detail/setting'
    }
  },
  {
    path: '/irrigationmodelsetting/updateArea:id',
    name: 'irrigationUpdateArea',
    component: () => import('@/views/irrigation/IrrigationArea.vue'),
    meta: {
      title: '编辑区域',
      hasQuery: true,
      backUrl: '/irrigationmodelsetting/detail/setting'
    }
  },
  {
    path: '/irrigationmodelsetting/addTiming',
    name: 'irrigationAddTiming',
    component: () => import('@/views/irrigation/IrrigationTimingPlanAdd.vue'),
    meta: {
      title: '新增定时计划',
      hasQuery: true,
      backUrl: '/irrigationmodelsetting/detail/timingplan'
    }
  },
  {
    path: '/irrigationmodelsetting/updateTiming:id',
    name: 'irrigationUpdateTiming',
    component: () => import('@/views/irrigation/IrrigationTimingPlanAdd.vue'),
    meta: {
      title: '编辑定时计划',
      hasQuery: true,
      backUrl: '/irrigationmodelsetting/detail/timingplan'
    }
  },
  {
    path: '/irrigationmodelsetting/etailTiming:id',
    name: 'irrigationDetailTiming',
    component: () => import('@/views/irrigation/IrrigationTimingDetail.vue'),
    meta: {
      title: '定时计划详情',
      hasQuery: true,
      backUrl: '/irrigationmodelsetting/detail/timingplan'
    }
  },
  {
    path: '/irrigationmodelsetting/addSmart',
    name: 'irrigationAddSmart',
    component: () => import('@/views/irrigation/IrrigationSmartPlanAdd.vue'),
    meta: {
      title: '新增智能计划',
      hasQuery: true,
      backUrl: '/irrigationmodelsetting/detail/smartplan'
    }
  },
  {
    path: '/irrigationmodelsetting/updateSmart:id',
    name: 'irrigationUpdateSmart',
    component: () => import('@/views/irrigation/IrrigationSmartPlanAdd.vue'),
    meta: {
      title: '编辑智能计划',
      hasQuery: true,
      backUrl: '/irrigationmodelsetting/detail/smartplan'
    }
  },
  {
    path: '/irrigationmodelsetting/etailSmart:id',
    name: 'irrigationDetailSmart',
    component: () => import('@/views/irrigation/IrrigationSmartDetail.vue'),
    meta: {
      title: '智能计划详情',
      hasQuery: true,
      backUrl: '/irrigationmodelsetting/detail/smartplan'
    }
  },
  {
    path: '/irrigationstatistics',
    meta: {
      title: '喷灌统计分析'
    },
    component: () => import('@/views/irrigation/IrrigationStatistics.vue')
  },
  {
    path: '/irrigationpower',
    meta: {
      title: '喷灌功耗分析'
    },
    component: () => import('@/views/irrigation/IrrigationPower.vue')
  }
]
export default router
