export interface Apis {
  // 项目列表
  sumByProject: string;
  // 视频不分页列表
  cameraList: string;
  // 视频分页列表
  cameraPageList: string;
  // 海康key，token
  getToken: string;
  // 萤石token
  getEzuikitToken: string;
  // 监控列表不分页
  selectSmartMonitorList: string;
  /** 只有设备型号等于 HIK_ISC 时 需要请求当前接口获取视频流地址 */
  selectDeviceIscUrl: string;
}

export const apis: Apis = {
  sumByProject: '/business/smartMonitor/selectSumProjectList',
  cameraList: '/business/smartMonitor/selectSmartMonitor',
  cameraPageList: '/business/smartMonitor/selectSmartMonitorPage',
  selectSmartMonitorList: 'business/smartMonitor/selectSmartMonitorList',
  getToken: '/device/camera/getHikCloudToken',
  getEzuikitToken: '/device/camera/getYstoken',
  selectDeviceIscUrl: '/device/device/selectDeviceIscUrl'
}
