import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [{
  name: 'HomePage',
  path: '/homePage',
  meta: {
    title: '首页概览'
  },
  component: () => import('@/views/homePage/Index.vue')
}]

export default router
