import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/utils/ajax'
import { getIndexPath } from '@/utils/formatData'
import { apis } from '@/api'
import { Page } from '@/types/common'
import { ProjectDetail } from '@/types/project'
import { CarTypeItem } from '@/types/carManage'
import { MenuNode } from '@/types/role'
// import { UserState } from './modules/user'

Vue.use(Vuex)

export interface RootState {
  keepAlive: string[];
  projectList: ProjectDetail[];
  userInfo: object;
  messageNum: number;
  buttonList: Array<object>;
  menuList: Array<MenuNode>;
  carTypeList: Array<object>;
  currentMenu: number | string;
}

export default new Vuex.Store<RootState>({
  state: {
    keepAlive: [],
    projectList: [],
    userInfo: {},
    messageNum: 0,
    buttonList: [],
    menuList: JSON.parse(sessionStorage.getItem('menuList') || '{}') || [],
    carTypeList: [],
    currentMenu: sessionStorage.getItem('currentMenu') || -1
  },
  mutations: {
    addKeepAlive (state: RootState, data: string) {
      if (!state.keepAlive.includes(data)) {
        state.keepAlive.push(data)
      }
    },
    removeKeepAlive (state: RootState, data: string) {
      const index = state.keepAlive.findIndex(item => item === data)
      state.keepAlive.splice(index, 1)
    },
    setProjectList (state: RootState, data: ProjectDetail[]) {
      state.projectList = data
    },
    updateUserInfo (state: RootState, data) {
      state.userInfo = data
    },
    updateMessageNum (state: RootState, data: number) {
      state.messageNum = data
    },
    updateButtonList (state: RootState, data: Array<object>) {
      state.buttonList = data
    },
    updateMenuList (state: RootState, data: Array<MenuNode>) {
      state.menuList = data
    },
    setCarTypeList (state: RootState, data: Array<object>) {
      state.carTypeList = data
    },
    // 当前
    setCurrentMenu (state, data) {
      state.currentMenu = data
      sessionStorage.setItem('currentMenu', data)
    }
  },
  actions: {
    getProjectList ({ commit }) {
      axios.get<Page<ProjectDetail>>(apis.project.selectProjectByList).then(res => {
        commit('setProjectList', res.list)
      })
    },
    // 车辆类型
    getCarTypeList ({ commit }) {
      axios.get<{[key: string]: CarTypeItem}>(apis.carManage.selectVehicleTypeList).then(res => {
        commit('setCarTypeList', res)
      })
    },
    // 更新登录账户信息
    getUserInfo ({ commit }) {
      axios.get(apis.userInfo.userInfo).then((res) => {
        window.sessionStorage.setItem('userInfo', JSON.stringify(res))
        commit('updateUserInfo', res)
      })
    },
    // 获取未读消息数
    getMessageNum ({ commit }, info) {
      axios.get(apis.userInfo.userReadNum, info).then((res) => {
        commit('updateMessageNum', res)
      })
    },
    // 获取菜单
    getMenu ({ commit }) {
      return axios.get(apis.common.getMenu).then(async (res: any) => {
        commit('updateMenuList', res)
        // 初次进入页面
        if (!sessionStorage.getItem('menuList')) {
          sessionStorage.setItem('menuList', JSON.stringify(res))
          if (res.length > 0) {
            let index = 0
            if ((+this.state.currentMenu !== 0 && +this.state.currentMenu !== -1)) {
              index = +this.state.currentMenu
            }
            this.commit('setCurrentMenu', index)
            return await getIndexPath([res[index]])
          }
        } else {
          const menuName = JSON.parse(sessionStorage.getItem('menuList') || '{}')[+this.state.currentMenu].menuName
          const index = res.findIndex((item: any) => {
            return item.menuName === menuName
          })
          this.commit('setCurrentMenu', index)
          sessionStorage.setItem('menuList', JSON.stringify(res))
        }
      })
    }
  },
  modules: {}
})
