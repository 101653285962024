export interface Apis {
  /** 新增项目考评指标 */
  insertEvaluationIndex: string;
  /** 修改项目考评指标 */
  updateEvaluationIndex: string;
  /** 删除项目考评指标 */
  deleteEvaluationIndex: string;
  /** 查询项目考评指标详情 */
  selectEvaluationIndexByIndexId: string;
  /** 查询项目考评指标列表 */
  selectEvaluationIndexByList: string;
  /** 批量新增项目考评项 */
  insertAllEvaluationTerm: string;
  /** 查询项目考评项列表 */
  selectEvaluationTermByList: string;
  /** 查询项目巡查得分 */
  selectIssueByScore: string;
  /** 新增项目考评记录 */
  insertEvaluation: string;
  /** 删除项目考评记录 */
  deleteEvaluation: string;
  /** 查询项目考评记录详情 */
  selectEvaluationByEvaluationId: string;
  /** 查询项目考评记录列表 */
  selectEvaluationByList: string;
  /** 批量保存项目考评等级 */
  insertEvaluationGradeList: string;
  /** 查询项目考评等级配置详情 */
  selectEvaluationGradeByGradeId: string;
  /** 查询项目考评等级配置列表 */
  selectEvaluationGradeByList: string;
  /** 查询当前项目是否已经有考评 */
  isEvaluationMobile: string;
}
export const apis: Apis = {
  insertEvaluationIndex: '/business/evaluationIndex/insertEvaluationIndex',
  updateEvaluationIndex: '/business/evaluationIndex/updateEvaluationIndex',
  deleteEvaluationIndex: '/business/evaluationIndex/deleteEvaluationIndex',
  selectEvaluationIndexByIndexId: '/business/evaluationIndex/selectEvaluationIndexByIndexId',
  selectEvaluationIndexByList: '/business/evaluationIndex/selectEvaluationIndexByList',
  insertAllEvaluationTerm: '/business/evaluationTerm/insertAllEvaluationTerm',
  selectEvaluationTermByList: '/business/evaluationTerm/selectEvaluationTermByList',
  selectIssueByScore: '/business/evaluation/selectIssueByScore',
  insertEvaluation: '/business/evaluation/insertEvaluation',
  deleteEvaluation: '/business/evaluation/deleteEvaluation',
  selectEvaluationByEvaluationId: '/business/evaluation/selectEvaluationByEvaluationId',
  selectEvaluationByList: '/business/evaluation/selectEvaluationByList',
  insertEvaluationGradeList: '/business/evaluationGrade/insertEvaluationGradeList',
  selectEvaluationGradeByGradeId: '/business/evaluationGrade/selectEvaluationGradeByGradeId',
  selectEvaluationGradeByList: '/business/evaluationGrade/selectEvaluationGradeByList',
  isEvaluationMobile: '/business/evaluation/isEvaluationMobile'
}
