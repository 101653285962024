// 角色
const url = '/business/iparkRole/'
export interface Apis {
  rolePage: string;
  roleAdd: string;
  roleUpdate: string;
  roleDelete: string;
  roleDetail: string;
  roleMenu: string;
}

export const apis: Apis = {
  rolePage: url + 'selectIparkRoleByPage',
  roleAdd: url + 'insertIparkRole',
  roleUpdate: url + 'updateIparkRole',
  roleDelete: url + 'deleteIparkRole',
  roleDetail: url + 'selectIparkRoleById',
  roleMenu: url + 'selectIparkMenuById'
}
