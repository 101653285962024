import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/sceneryList',
    name: 'sceneryList',
    meta: {
      title: '景观景点'
    },
    component: () => import('@/views/scenery/List.vue')
  },
  {
    path: '/sceneryList/add',
    name: 'sceneryAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/scenery/Add.vue')
  },
  {
    path: '/sceneryList/update:sceneryId',
    name: 'sceneryUpdate',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/scenery/Add.vue')
  },
  {
    path: '/sceneryList/detail:sceneryId',
    name: 'sceneryDetail',
    component: () => import('@/views/scenery/Detail.vue'),
    redirect: '/sceneryList',
    children: [
      {
        path: 'sceneryDetail',
        component: () => import('@/views/scenery/SceneryDetail.vue'),
        meta: {
          title: '基础信息'
        }
      },
      {
        path: 'record',
        component: () => import('@/views/scenery/Record.vue'),
        meta: {
          title: '维护记录'
        }
      }
    ]
  }
]

export default router
