import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/buildFacilitiesList',
    name: 'buildFacilitiesList',
    meta: {
      title: '建筑设施'
    },
    component: () => import('@/views/buildFacilities/List.vue')
  },
  {
    path: '/buildFacilitiesList/add',
    name: 'buildFacilitiesAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/buildFacilities/Add.vue')
  },
  {
    path: '/buildFacilitiesList/update:buildId',
    name: 'buildFacilitiesUpdate',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/buildFacilities/Add.vue')
  },
  {
    path: '/buildFacilitiesList/detail:buildId',
    name: 'buildFacilitiesDetail',
    component: () => import('@/views/buildFacilities/Detail.vue'),
    redirect: '/buildFacilitiesList',
    children: [
      {
        path: 'buildDetail',
        component: () => import('@/views/buildFacilities/BuildDetail.vue'),
        meta: {
          title: '基础信息'
        }
      },
      {
        path: 'record',
        component: () => import('@/views/buildFacilities/Record.vue'),
        meta: {
          title: '维护记录'
        }
      }
    ]
  }
]

export default router
