import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/projectFile',
    name: 'projectFile',
    component: () => import('@/views/projectFile/List.vue'),
    meta: {
      title: '项目文件'
    }
  }
]

export default router
