import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/questionsurvey',
    name: 'questionsurvey',
    meta: {
      title: '问卷调查'
    },
    component: () => import('@/views/questionsurvey/Questionsurvey.vue')
  },
  {
    path: '/questionsurvey/add',
    name: 'QuestionsurveyAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/questionsurvey/QuestionsurveyAdd.vue')
  },
  {
    path: '/questionsurvey/update:id',
    name: 'QuestionsurveyUpdate',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/questionsurvey/QuestionsurveyAdd.vue')
  },
  {
    path: '/questionsurvey/detail:id',
    name: 'QuestionsurveyDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/questionsurvey/QuestionsurveyDetail.vue')
  },
  {
    path: '/questionsurvey/cDetail:id',
    name: 'QuestionsurveyDetail2',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/questionsurvey/QuestionsurveyDetail2.vue')
  }
]

export default router
