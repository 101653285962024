// 工单计划
const baseUrl = '/business/flowPlans/'
export interface Apis{
  /** 新增 */
  insertFlowPlans: string;
  /** 分页 */
  selectFlowPlansByPage: string;
  /** 删除 */
  deleteFlowPlans: string;
  /** 详情 */
  selectFlowPlansByPlanId: string;
  /** 查询关联工单 */
  selectFlowPlansTaskById: string;
  /** 查询关联工单 */
  selectFlowTaskOrderByOrderId: string;
  /** 删除任务 */
  deleteFlowPlansTask: string;
  /** 查询工单计划汇总 */
  selectFlowPlansSum: string;
  /** 分页查询工单计划任务 */
  selectFlowPlansTaskPage: string;
  /** 导入任务 */
  importTask: string;
}
export const apis: Apis = {
  insertFlowPlans: baseUrl + 'insertFlowPlans',
  selectFlowPlansByPage: baseUrl + 'selectFlowPlansByPage',
  deleteFlowPlans: baseUrl + 'deleteFlowPlans',
  selectFlowPlansByPlanId: baseUrl + 'selectFlowPlansByPlanId',
  selectFlowPlansTaskById: baseUrl + 'selectFlowPlansTaskById',
  selectFlowTaskOrderByOrderId: baseUrl + 'selectFlowTaskOrderByOrderId',
  deleteFlowPlansTask: baseUrl + 'deleteFlowPlansTask',
  selectFlowPlansSum: baseUrl + 'selectFlowPlansSum',
  selectFlowPlansTaskPage: baseUrl + 'selectFlowPlansTaskPage',
  importTask: baseUrl + 'importTask'
}
