// 接口名定义
export interface Apis {
  // 获取项目列表
  selectSumProjectList: string;
  // 不分页查询列表
  selectSoilWith: string;
  // 分页查询列表
  selectSoilWithPage: string;
  // 历史数据折线图详情
  selectSoilWithMap: string;
  // 历史数据分页展示详情
  getReportData: string;
  // 查询分组列表
  selectSoilGroupByList: string;
  // 未分组设备
  selectNotGroupDeviceList: string;
  // 新建分组
  insertSoilGroup: string;
  // 编辑分组
  updateSoilGroup: string;
  // 删除分组
  deleteDataSoilGroup: string;
  // 打点
  updateSoilGroupPoint: string;
  // 地图页分组列表
  selectSoilGroupMapList: string;
  // 地图页分组详情
  selectMapDetail: string;
  // 地图页分组展示合集
  selectSoilWithConfig: string;
  // 分组折线图
  selectSoilWithMaps: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/soilWith/selectSumProjectList',
  selectSoilWith: '/business/soilWith/selectSoilWith',
  selectSoilWithPage: '/business/soilWith/selectSoilWithPage',
  selectSoilWithMap: '/business/soilWith/selectSoilWithMap',
  getReportData: '/device/toCustomer/device/getReportData',
  selectSoilGroupByList: '/business/soilGroup/selectSoilGroupByList',
  selectNotGroupDeviceList: '/business/soilGroup/selectNotGroupDeviceList',
  insertSoilGroup: '/business/soilGroup/insertSoilGroup',
  updateSoilGroup: '/business/soilGroup/updateSoilGroup',
  deleteDataSoilGroup: '/business/soilGroup/deleteDataSoilGroup',
  updateSoilGroupPoint: '/business/soilGroup/updateSoilGroupPoint',
  selectSoilGroupMapList: '/business/soilGroup/selectSoilGroupMapList',
  selectMapDetail: '/business/soilGroup/selectMapDetail',
  selectSoilWithConfig: '/business/soilGroup/selectSoilWithConfig',
  selectSoilWithMaps: '/business/soilGroup/selectSoilWithMap'
}
