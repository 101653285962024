
export interface Apis {
  selectServiceFacilitiesByPage: string;
  insertServiceFacilities: string;
  selectServiceFacilitiesByServiceId: string;
  deleteServiceFacilities: string;
  updateServiceFacilities: string;
  /** 资源一张图前台-资源-服务设施 */
  selectServiceFacilitiesByList: string;
  /** 资源一张图前台-资源-服务设施-天人山水-菜单分布分页列表 */
  selectServicePage: string;
  /** 资源一张图前台-资源-服务设施-天人山水-地图分布不分页列表 */
  selectServiceList: string;
}

export const apis: Apis = {
  selectServiceFacilitiesByPage: '/operate/serviceFacilities/selectServiceFacilitiesByPage', // 列表
  insertServiceFacilities: '/operate/serviceFacilities/insertServiceFacilities', // 新增
  selectServiceFacilitiesByServiceId: '/operate/serviceFacilities/selectServiceFacilitiesByServiceId', // 详情
  deleteServiceFacilities: '/operate/serviceFacilities/deleteServiceFacilities', // 删除
  updateServiceFacilities: '/operate/serviceFacilities/updateServiceFacilities', // 编辑
  selectServiceFacilitiesByList: '/operate/serviceFacilities/selectServiceFacilitiesByList',
  selectServicePage: '/operate/serviceFacilities/selectServicePage',
  selectServiceList: '/operate/serviceFacilities/selectServiceList'
}
