import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/scenic',
    name: 'scenicList',
    meta: {
      title: '景区介绍'
    },
    component: () => import('@/views/scenic/List.vue')
  },
  {
    path: '/scenic/add',
    name: 'scenicAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/scenic/Add.vue')
  },
  {
    path: '/scenic/update:id',
    name: 'scenicUpdate',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/scenic/Add.vue')
  },
  {
    path: '/scenic/detail:id',
    name: 'scenicDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/scenic/Detail.vue')
  }
]

export default router
