// 接口名定义
export interface Apis {
  // 获取项目列表
  selectSumProjectList: string;
  // 不分页查询垃圾桶列表
  selectWaterQuality: string;
  // 分页查询垃圾桶列表
  selectWaterQualityPage: string;
  // 数据详情
  selectWaterQualityDataList: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/waterQualityMonitor/selectSumProjectList',
  selectWaterQuality: '/business/waterQualityMonitor/selectWaterQuality',
  selectWaterQualityPage: '/business/waterQualityMonitor/selectWaterQualityPage',
  selectWaterQualityDataList: '/business/waterQualityMonitor/selectWaterQualityDataList'
}
