import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/advertisingList',
    name: 'advertisingList',
    meta: {
      title: '广告发布'
    },
    component: () => import('@/views/advertising/List.vue')
  },
  {
    path: '/advertisingList/Add',
    name: 'advertisingAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/advertising/Add.vue')
  },
  {
    path: '/advertisingList/edit:advertiseId',
    name: 'advertisingEdit',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/advertising/Add.vue')
  },
  {
    path: '/advertisingList/detail:advertiseId',
    name: 'advertisingDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/advertising/Detail.vue')
  }

]

export default router
