import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  {
    path: '/farmingCalendar',
    meta: { title: '农事日历' },
    component: () => import('@/views/farmingCalendar/Calendar.vue')
  },
  {
    path: '/farmingGuide',
    meta: { title: '农事指导管理' },
    component: () => import('@/views/farmingCalendar/Guide.vue')
  }
]

export default router
