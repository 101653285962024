import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/pressuremonitor',
    name: 'pressureMonitortList',
    meta: {
      title: '水压监测'
    },
    component: () => import('@/views/pressureMonitor/List.vue')
  },
  {
    path: '/pressuremonitor/pressureMonitorDetail:projectId',
    name: 'pressureMonitorDetail',
    meta: {
      title: '详情'
    },
    redirect: '/pressuremonitor',
    component: () => import('@/views/pressureMonitor/Detail.vue'),
    children: [
      {
        path: '/pressuremonitor/pressureMonitorDetail:projectId/map',
        name: 'pressureMonitorDetailMap',
        component: () => import('@/views/pressureMonitor/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/pressuremonitor/pressureMonitorDetail:projectId/list',
        name: 'pressureMonitorDetailList',
        component: () => import('@/views/pressureMonitor/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/pressuremonitor/pressureMonitorDetail:projectId/list/history:deviceId',
    name: 'pressureMonitorListHistory',
    component: () => import('@/views/pressureMonitor/History.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    path: '/pressureMonitor/pressureMonitorDetail:projectId/map/history:deviceId',
    name: 'pressureMonitorMapHistory',
    component: () => import('@/views/pressureMonitor/History.vue'),
    meta: {
      title: '历史数据'
    }
  }
]

export default router
