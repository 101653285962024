export interface Apis {
  /** 广告新增 */
  insertAdvertise: string;
  /** 广告修改 */
  updateAdvertise: string;
  /** 删除 */
  deleteAdvertise: string;
  /** 详情 */
  selectAdvertiseByAdvertiseId: string;
  /** 分页查询列表 */
  selectAdvertiseByPage: string;
  /** 发布/下架 */
  releaseAdvertise: string;
}
export const apis: Apis = {
  insertAdvertise: '/operate/advertise/insertAdvertise',
  updateAdvertise: '/operate/advertise/updateAdvertise',
  deleteAdvertise: '/operate/advertise/deleteAdvertise',
  selectAdvertiseByAdvertiseId: '/operate/advertise/selectAdvertiseByAdvertiseId',
  selectAdvertiseByPage: '/operate/advertise/selectAdvertiseByPage',
  releaseAdvertise: '/operate/advertise/releaseAdvertise'

}
