import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/vehicleList',
    name: 'vehicleList',
    meta: {
      title: '车辆监控'
    },
    component: () => import('@/views/vehicle/List.vue')
  },
  {
    path: '/vehicleList/vehicleDetail:projectId',
    name: 'vehicleDetail',
    meta: {
      title: '详情'
    },
    redirect: '/vehicleList',
    component: () => import('@/views/vehicle/Detail.vue'),
    children: [{
      path: '/vehicleList/vehicleDetail:projectId/map',
      name: 'vehicleMapDetail',
      meta: {
        title: '详情'
      },
      component: () => import('@/views/vehicle/DetailMap.vue')
    }, {
      path: '/vehicleList/vehicleDetail:projectId/list',
      name: 'vehicleListDetail',
      meta: {
        title: '详情'
      },
      component: () => import('@/views/vehicle/DetailList.vue')
    }]
  },
  {
    path: '/vehicleList/capture:projectId',
    name: 'vehicleCapture',
    meta: {
      title: '车辆抓拍'
    },
    component: () => import('@/views/vehicle/Capture.vue')
  }
]

export default router
