export interface Apis {
  /** 农事日历列表 */
  selectFarmingCalendarList: string;
  /** 查询农事日历详情 */
  selectFarmingCalendar: string;
  /** 添加农事日历作业 */
  insertFarmingJob: string;
  /** 修改农事日历作业 */
  updateFarmingJob: string;
  /** 删除农事日历作业 */
  deleteFarmingJob: string;
  /** 修改农事日历作业状态 */
  updateFarmingJobStatus: string;
  /** 查询农事类型列表 */
  selectFarmingTypeByList: string;
  /** 添加农事类型 */
  insertFarmingType: string;
  /** 修改农事类型 */
  updateFarmingType: string;
  /** 删除农事类型 */
  deleteFarmingType: string;
  /** 获取农事指导列表 */
  selectFarmingCalendarTemplateByPage: string;
  /** 查询农事指导详情 */
  selectFarmingCalendarTemplate: string;
  /** 编辑农事指导 */
  updateFarmingCalendarTemplate: string;
}
export const apis: Apis = {
  selectFarmingCalendarList: '/business/farmingCalendar/selectFarmingCalendarList',
  selectFarmingCalendar: '/business/farmingCalendar/selectFarmingCalendar',
  insertFarmingJob: '/business/farmingJob/insertFarmingJob',
  updateFarmingJob: '/business/farmingJob/updateFarmingJob',
  deleteFarmingJob: '/business/farmingJob/deleteFarmingJob',
  updateFarmingJobStatus: '/business/farmingJob/updateFarmingJobStatus',
  selectFarmingTypeByList: '/business/farmingType/selectFarmingTypeByList',
  insertFarmingType: '/business/farmingType/insertFarmingType',
  updateFarmingType: '/business/farmingType/updateFarmingType',
  deleteFarmingType: '/business/farmingType/deleteFarmingType',
  selectFarmingCalendarTemplateByPage: '/business/farmingCalendarTemplate/selectFarmingCalendarTemplateByPage',
  selectFarmingCalendarTemplate: '/business/farmingCalendarTemplate/selectFarmingCalendarTemplate',
  updateFarmingCalendarTemplate: '/business/farmingCalendarTemplate/updateFarmingCalendarTemplate'
}
