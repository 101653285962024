// 接口名定义
export interface Apis {
  // 关联设备下拉框
  selectNotBoundDeviceList: string;
  // 添加厕所
  insertSmartToilet: string;
  // 厕所详情
  selectSmartToiletDetail: string;
  // 编辑厕所
  updateSmartToilet: string;
  // 删除厕所
  deleteSmartToilet: string;
  // 获取项目列表
  selectSumProjectList: string;
  // 地图页查询列表
  selectSumProject: string;
  // 分页查询列表
  selectSmartToiletByPage: string;
  // 数据分析
  selectSmartToiletAnalysis: string;
}

export const apis: Apis = {
  selectNotBoundDeviceList: '/business/smartToilet/selectNotBoundDeviceList',
  insertSmartToilet: '/business/smartToilet/insertSmartToilet',
  selectSmartToiletDetail: '/business/smartToilet/selectSmartToiletDetail',
  updateSmartToilet: '/business/smartToilet/updateSmartToilet',
  deleteSmartToilet: '/business/smartToilet/deleteSmartToilet',
  selectSumProjectList: '/business/smartToilet/selectSumProjectList',
  selectSumProject: '/business/smartToilet/selectSumProject',
  selectSmartToiletByPage: '/business/smartToilet/selectSmartToiletByPage',
  selectSmartToiletAnalysis: '/business/smartToilet/selectSmartToiletAnalysis'
}
