import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/ordinarySeedlingList',
    name: 'ordinarySeedlingList',
    component: () => import('@/views/ordinaryseedling/Base.vue'),
    redirect: '/ordinarySeedlingList/list',
    children: [{
      name: 'ordinarySeedlingList',
      path: '/ordinarySeedlingList/list',
      component: () => import('@/views/ordinaryseedling/List.vue'),
      meta: {
        title: '普通苗木',
        hasBack: false
      }
    }, {
      path: '/ordinarySeedlingList/map',
      name: 'ordinarySeedlingMapList',
      component: () => import('@/views/ordinaryseedling/Map.vue'),
      meta: {
        title: '普通苗木',
        hasBack: false
      }
    }]
  },
  {
    path: '/ordinarySeedlingList/add',
    name: 'ordinarySeedlingAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/ordinaryseedling/Add.vue')
  },
  {
    path: '/ordinarySeedlingList/update:seedlingId',
    name: 'ordinarySeedlingUpdate',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/ordinaryseedling/Add.vue')
  },
  {
    path: '/ordinarySeedlingList/list/detail:seedlingId',
    name: 'ordinarySeedlingDetail',
    component: () => import('@/views/ordinaryseedling/PlantDetail.vue'),
    meta: {
      title: '列表-详情'
    }
  },
  {
    path: '/ordinarySeedlingList/map/detail:seedlingId',
    name: 'ordinarySeedlingMapDetail',
    component: () => import('@/views/ordinaryseedling/PlantDetail.vue'),
    meta: {
      title: '地图-详情'
    }
  }
]

export default router
