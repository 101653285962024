import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  {
    path: '/manholeManage',
    name: 'manholeManageList',
    component: () => import('@/views/manholeManage/List.vue'),
    meta: {
      title: '井盖管理'
    }
  },
  {
    path: '/manholeManage/add',
    name: 'manholeManageAdd',
    component: () => import('@/views/manholeManage/Add.vue'),
    meta: {
      title: '新增'
    }
  },
  {
    path: '/manholeManage/update:id',
    name: 'manholeManageUpdate',
    component: () => import('@/views/manholeManage/Add.vue'),
    meta: {
      title: '编辑'
    }
  }

]

export default router
