// 积水监测
export interface Apis {
  // 项目列表
  selectSumProjectList: string;
  // 详情地图
  selectDraft: string;
  // 详情列表
  selectDraftPage: string;
  // 设备数据详情
  selectDraftDataList: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/draft/selectSumProjectList',
  selectDraft: '/business/draft/selectDraft',
  selectDraftPage: '/business/draft/selectDraftPage',
  selectDraftDataList: '/business/draft/selectDraftDataList'
}
