import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/plan',
    name: 'planList',
    meta: {
      title: '工单计划'
    },
    component: () => import('@/views/plan/List.vue')
  },
  {
    path: '/plan/add',
    name: 'planAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/plan/Add.vue')
  },
  {
    path: '/plan/detail:id',
    name: 'planDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/plan/Detail.vue')
  },
  {
    path: '/plan/taskDetails',
    name: 'planTaskDetails',
    meta: {
      title: '任务明细'
    },
    component: () => import('@/views/plan/taskDetails.vue')
  }
]

export default router
