// 接口名定义
export interface Apis {
  // 获取项目列表
  selectSumProjectList: string;
  // 地图页查询列表
  selectSumProject: string;
  // 分页查询列表
  selectGasByPage: string;
  // 数据详情
  selectGasDataByPage: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/gas/selectSumProjectList',
  selectSumProject: '/business/gas/selectSumProject',
  selectGasByPage: '/business/gas/selectGasByPage',
  selectGasDataByPage: '/business/gas/selectGasDataByPage'
}
