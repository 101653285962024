// 接口名定义
export interface Apis {
  // 分页获取巡查列表
  selectPatrolSummary: string;
  // 查询流程节点编号对应的人员列表 节点编号 0新建（巡查人） 1反馈（整改人） 2审核（审核人）
  selectUserByProcessNodeCodeByList: string;
  // 新增巡查
  insertPatrol: string;
  // 删除巡查
  deletePatrol: string;
  // 查看巡查设置
  selectPatrolProcess: string;
  // 修改巡查设置
  updatePatrolProcess: string;
  /** 巡查签到记录列表 */
  selectWoAutographPage: string;
  // 分页查询问题列表
  selectIssueByPage: string;
  // 新增巡查问题
  insertIssue: string;
  // 删除巡查问题
  deleteIssue: string;
  // 查询巡查问题详情
  selectIssue: string;
  // 问题反馈
  rectify: string;
  // 问题审核
  audit: string;
  // 分页查询项目考评
  selectProjectEvaluation: string;
  /** 巡查导出 */
  exportPatrolData: string;
  /** 催办 */
  insertIssueUrge: string;
  /** 巡查热力图 */
  selectIssueHeat: string;
  /** 巡查月报 */
  selectPatrolMonthlyReport: string;
  /** 查询巡查问题节点配置和待确认问题总数 */
  selectIssueConfig: string;
  /** 更新巡查问题节点配置 */
  updateIssueConfig: string;
  /** 查询待确认问题列表  */
  selectNeedConfirmIssueList: string;
  /** 确认问题的审核 */
  updateConfirmStatus: string;
  /** 查询问题类型列表 */
  selectIssueTypeByList: string;
  /** 查询问题类型下拉框 */
  selectIssueTypeList: string;
  /** 添加问题类型 */
  insertIssueType: string;
  /** 修改问题类型 */
  updateIssueType: string;
  /** 删除问题类型 */
  deleteIssueType: string;
  /** 查询问题状态列表 */
  selectIssueStatusList: string;
  /** 转办 */
  updateRectifierIssue: string;
  /** 二级-巡查统计 */
  selectIssueSum: string;
  /** 二级-巡查列表 */
  pageIssueListByNew: string;
}

export const apis: Apis = {
  selectPatrolSummary: '/business/patrol/selectPatrolSummary',
  selectUserByProcessNodeCodeByList: '/business/process/selectUserByProcessNodeCodeByList',
  insertPatrol: '/business/patrol/insertPatrol',
  deletePatrol: '/business/patrol/deletePatrol',
  selectPatrolProcess: '/business/patrol/selectPatrolProcess',
  updatePatrolProcess: '/business/patrol/updatePatrolProcess',
  selectWoAutographPage: '/business/patrol/selectWoAutographPage',
  selectIssueByPage: '/business/issue/selectIssueByPage',
  insertIssue: '/business/issue/insertIssue',
  deleteIssue: '/business/issue/deleteIssue',
  selectIssue: '/business/issue/selectIssue',
  rectify: '/business/issue/rectify',
  audit: '/business/issue/audit',
  selectProjectEvaluation: '/business/issue/selectProjectEvaluation',
  exportPatrolData: '/business/patrol/exportPatrolData',
  insertIssueUrge: '/business/issue/insertIssueUrge',
  selectIssueHeat: '/business/issue/selectIssueHeat',
  selectPatrolMonthlyReport: '/business/patrol/selectPatrolMonthlyReport',
  selectIssueConfig: '/business/patrol/selectIssueConfig',
  updateIssueConfig: '/business/patrol/updateIssueConfig',
  selectNeedConfirmIssueList: '/business/issue/selectNeedConfirmIssueList',
  updateConfirmStatus: '/business/issue/updateConfirmStatus',
  selectIssueTypeByList: '/business/issueType/selectIssueTypeByList',
  selectIssueTypeList: '/business/issueType/selectIssueTypeList',
  insertIssueType: '/business/issueType/insertIssueType',
  updateIssueType: '/business/issueType/updateIssueType',
  deleteIssueType: '/business/issueType/deleteIssueType',
  selectIssueStatusList: '/business/issue/selectIssueStatusList',
  updateRectifierIssue: '/business/issue/updateRectifierIssue',
  selectIssueSum: '/business/issue/selectIssueSum',
  pageIssueListByNew: '/business/issue/pageIssueListByNew'
}
