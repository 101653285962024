export interface Apis {
  /** 新增 */
  insertProjectFiles: string;
  /** 分页 */
  selectProjectFilesByPage: string;
  /** 删除 */
  deleteProjectFiles: string;
  /** 删除文件类型 */
  deleteProjectFileTypes: string;
  /** 修改文件类型 */
  updateProjectFileTypes: string;
  /** 新增文件类型 */
  insertProjectFileTypes: string;
  /** 文件类型列表 */
  selectProjectFileTypesByList: string;
}
export const apis: Apis = {
  insertProjectFiles: '/business/projectFiles/insertProjectFiles',
  selectProjectFilesByPage: '/business/projectFiles/selectProjectFilesByPage',
  deleteProjectFiles: '/business/projectFiles/deleteProjectFiles',
  deleteProjectFileTypes: '/business/projectFileTypes/deleteProjectFileTypes',
  updateProjectFileTypes: '/business/projectFileTypes/updateProjectFileTypes',
  insertProjectFileTypes: '/business/projectFileTypes/insertProjectFileTypes',
  selectProjectFileTypesByList: '/business/projectFileTypes/selectProjectFileTypesByList'
}
