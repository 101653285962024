
export interface Apis {
  selectSceneryByPage: string;
  insertScenery: string;
  selectSceneryBySceneryId: string;
  deleteScenery: string;
  updateScenery: string;
  /** 资源一张图前台-资源-景观景点 */
  selectSceneryByList: string;
  /** 资源一张图前台-资源-景观景点-天人山水-菜单分布分页列表 */
  selectSceneryPage: string;
  /** 资源一张图前台-资源-景观景点-天人山水-地图分布不分页列表 */
  selectSceneryList: string;
}

export const apis: Apis = {
  selectSceneryByPage: '/operate/scenery/selectSceneryByPage', // 列表
  insertScenery: '/operate/scenery/insertScenery', // 新增
  selectSceneryBySceneryId: '/operate/scenery/selectSceneryBySceneryId', // 详情
  deleteScenery: '/operate/scenery/deleteScenery', // 删除
  updateScenery: '/operate/scenery/updateScenery', // 编辑
  selectSceneryByList: '/operate/scenery/selectSceneryByList',
  selectSceneryPage: '/operate/scenery/selectSceneryPage',
  selectSceneryList: '/operate/scenery/selectSceneryList'
}
