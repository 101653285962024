export interface Apis {
  selectSumProjectList: string;// 项目列表分页列表
  selectVehicle: string; // 详情地图
  selectVehicleDetailPage: string; // 详情分页列表
  selectVehicleDataPage: string; // 车辆抓拍分页列表
  selectLatestVehicleData: string; // 车辆抓拍详情
}

export const apis: Apis = {
  selectSumProjectList: '/business/vehicle/selectSumProjectList',
  selectVehicle: '/business/vehicle/selectVehicle',
  selectVehicleDetailPage: '/business/vehicle/selectVehicleDetailPage',
  selectVehicleDataPage: '/business/vehicle/selectVehicleDataPage',
  selectLatestVehicleData: '/business/vehicle/selectLatestVehicleData'
}
