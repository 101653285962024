// 接口名定义
export interface Apis {
  // 巡查日报
  selectDaySumPage: string;
  // 巡查日报问题列表
  selectDayIssuePage: string;
  // 巡查周报
  selectWeekStatistic: string;
  // 遗留问题
  selectUnCompeletedIssuePage: string;
  // 巡查月报
  selectMonthStatistic: string;
  // 随手拍（近6月月度统计+问题状态统计）
  select6MonthIssueStatistic: string;
  // 随手拍（遗留问题）
  selectUnRectifyIssuePage: string;
}

export const apis: Apis = {
  selectDaySumPage: '/business/patrolReport/selectDaySumPage',
  selectDayIssuePage: '/business/patrolReport/selectDayIssuePage',
  selectWeekStatistic: '/business/patrolReport/selectWeekStatistic',
  selectUnCompeletedIssuePage: '/business/patrolReport/selectUnCompeletedIssuePage',
  selectMonthStatistic: '/business/patrolReport/selectMonthStatistic',
  select6MonthIssueStatistic: '/business/patrolReport/select6MonthIssueStatistic',
  selectUnRectifyIssuePage: '/business/patrolReport/selectUnRectifyIssuePage'
}
