// 接口名定义
export interface Apis {
  // 获取项目列表
  selectSumProjectList: string;
  // 不分页查询垃圾桶列表
  selectWater: string;
  // 分页查询垃圾桶列表
  selectWaterDetailPage: string;
  // 历史数据折线图详情
  selectWaterMap: string;
  // 历史数据分页展示详情
  selectWaterPage: string;
  // 耗水统计
  selectWaterStatistic: string;
  // 耗水预测
  selectWaterForecast: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/water/selectSumProjectList',
  selectWater: '/business/water/selectWater',
  selectWaterDetailPage: '/business/water/selectWaterDetailPage',
  selectWaterMap: '/business/water/selectWaterMap',
  selectWaterPage: '/business/water/selectWaterPage',
  selectWaterForecast: '/business/water/selectWaterForecast',
  selectWaterStatistic: '/business/water/selectWaterStatistic'
}
