export interface Apis {
  // 工牌列表
  selectSumProjectList: string;
  // 查询手环地图
  selectWorkCardList: string;
  // 查询工牌列表（分页）
  selectWorkCardPage: string;
  // 健康数据汇总
  healthData: string;
  // 健康数据折线图
  lineHealthData: string;
  // sos解除
  updateCancelSos: string;
  // 查询未绑定手环列表
  selectWatchListUnBindlist: string;
  // 绑定手环
  updateBindWorkCard: string;
  // 解绑手环
  updateUnBindWorkCard: string;
  // 工人轨迹
  selectWorkCardByPage: string;
}
export const apis: Apis = {
  selectSumProjectList: '/business/workCard/selectSumProjectList',
  selectWorkCardList: '/business/workCard/selectWorkCardList',
  selectWorkCardPage: '/business/workCard/selectWorkCardPage',
  healthData: '/business/watchWorker/selectHealthData',
  lineHealthData: '/business/watchWorker/selectLineHealthData',
  updateCancelSos: '/business/workCard/updateCancelSos',
  selectWatchListUnBindlist: '/business/workCard/selectWatchListUnBindlist',
  updateBindWorkCard: '/business/workCard/updateBindWorkCard',
  updateUnBindWorkCard: '/business/workCard/updateUnBindWorkCard',
  selectWorkCardByPage: '/device/workCard/selectWorkCardByPage'
}
