const url = '/device/toCustomer/device/'
export interface Apis {
  // 项目列表
  selectLocationProjectList: string;
  // 设备历史数据
  selectLocationDataList: string;
  // 设备在线离线情况
  sumByProject: string;
  // 绑定机械设备
  locationBindMachine: string;
  // 修改绑定机械设备
  updateLocationBindMachine: string;
  // 机械列表,同地图数据
  selectLocationAndMachineList: string;
  // 终端定位设备列表
  locationBotBindMachineList: string;
  // 删除机械设备
  deleteLocationMachine: string;
  // 查询所有机械设备列表
  locationMachineList: string;
  // 查询所有机械设备列表分页
  locationMachineListPage: string;
  // 终端定位解绑
  updateUnBindLocation: string;
  // 查询机械不分页列表
  selectAllWorkerList: string;
}

export const apis: Apis = {
  selectLocationProjectList: '/business/location/selectSumProjectList',
  selectLocationDataList: '/business/location/selectTrack',
  sumByProject: url + 'sumByProject/1008',
  locationBindMachine: '/business/location/locationBindMachine',
  updateLocationBindMachine: '/business/location/updateLocationBindMachine',
  selectLocationAndMachineList: '/business/location/selectLocationList',
  locationBotBindMachineList: '/business/location/selectWatchListUnBindlist',
  deleteLocationMachine: '/business/location/deleteLocation',
  locationMachineList: '/device/location/locationMachineList',
  locationMachineListPage: '/business/location/selectLocationPage',
  updateUnBindLocation: '/business/location/updateUnBindLocation',
  selectAllWorkerList: '/business/location/selectAllWorkerList'
}
