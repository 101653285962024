const baseUrl = '/business'
export interface Apis {
  /** 查询预约项目详情 */
  selectAppointItemByItemId: string;
  /** 查询预约项目分页 */
  selectAppointItemByPage: string;
  /** 添加预约项目 */
  insertAppointItem: string;
  /** 更新预约项目状态 */
  updateAppointItemStatus: string;
  /** 删除预约项目 */
  deleteAppointItem: string;
  /** 查询预约项目列表 */
  selectAppointItemList: string;
  /** 查询公园的已开启预约项目 */
  selectParkAppointItemList: string;
  /** 查询预约时间汇总列表 */
  selectAppointItemTimeList: string;

  /** 游客 */
  /** 新增游客信息 */
  insertTouristInfo: string;
  /** 删除游客信息 */
  deleteTouristInfo: string;
  /** 修改游客信息 */
  updateTouristInfo: string;
  /** 查询游客信息列表 */
  selectTouristInfoByList: string;
  /** 查询游客信息分页 */
  selectTouristInfoByPage: string;
  /** 游客新增预约 */
  insertAppointItemRecord: string;
  /** 取消预约 */
  updateAppointItemRecordStatus: string;
  /** 我的预约 */
  selectMyPage: string;
  /** 查询游客预约详情 */
  selectAppointItemRecordByRecordId: string;
  /** 查询游客预约分页 */
  selectAppointItemRecordByPage: string;
  /** 查询预约人数总和 */
  selectEnterNumTotal: string;
}
export const apis: Apis = {
  // 游客服务
  // 预约管理
  // 预约项目
  selectAppointItemByItemId: baseUrl + '/appointItem/selectAppointItemByItemId',
  selectAppointItemByPage: baseUrl + '/appointItem/selectAppointItemByPage',
  insertAppointItem: baseUrl + '/appointItem/insertAppointItem',
  updateAppointItemStatus: baseUrl + '/appointItem/updateAppointItemStatus',
  deleteAppointItem: baseUrl + '/appointItem/deleteAppointItem',
  selectAppointItemList: baseUrl + '/appointItem/selectAppointItemList',
  selectParkAppointItemList: baseUrl + '/appointItem/selectParkAppointItemList',
  selectAppointItemTimeList: baseUrl + '/appointItemTime/selectAppointItemTimeList',
  // 游客
  insertTouristInfo: baseUrl + '/touristInfo/insertTouristInfo',
  deleteTouristInfo: baseUrl + '/touristInfo/deleteTouristInfo',
  updateTouristInfo: baseUrl + '/touristInfo/updateTouristInfo',
  selectTouristInfoByList: baseUrl + '/touristInfo/selectTouristInfoByList',
  selectTouristInfoByPage: baseUrl + '/touristInfo/selectTouristInfoByPage',
  insertAppointItemRecord: baseUrl + '/appointItemRecord/insertAppointItemRecord',
  updateAppointItemRecordStatus: baseUrl + '/appointItemRecord/updateAppointItemRecordStatus',
  selectMyPage: baseUrl + '/appointItemRecord/selectMyPage',
  selectAppointItemRecordByRecordId: baseUrl + '/appointItemRecord/selectAppointItemRecordByRecordId',
  selectAppointItemRecordByPage: baseUrl + '/appointItemRecord/selectAppointItemRecordByPage',
  selectEnterNumTotal: baseUrl + '/appointItemRecord/selectEnterNumTotal'
}
