// 接口名定义
export interface Apis {
  // 获取项目列表
  selectSumProjectList: string;
  // 不分页查询列表
  selectSoilFertility: string;
  // 分页查询列表
  selectSoilFertilityPage: string;
  // 历史数据折线图详情
  selectSoilFertilityMap: string;
  // 历史数据分页展示详情
  getReportData: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/soilFertility/selectSumProjectList',
  selectSoilFertility: '/business/soilFertility/selectSoilFertility',
  selectSoilFertilityPage: '/business/soilFertility/selectSoilFertilityPage',
  selectSoilFertilityMap: '/business/soilFertility/selectSoilFertilityMap',
  getReportData: '/device/toCustomer/device/getReportData'
}
