export interface Apis {
  /** 新增 */
  insertHealthLife: string;
  /** 删除 */
  deleteHealthLife: string;
  /** 详情 */
  selectHealthLifeByLifeId: string;
  /** 分页查询列表 */
  selectHealthLifeByPage: string;
}
export const apis: Apis = {
  insertHealthLife: '/operate/healthLife/insertHealthLife',
  deleteHealthLife: '/operate/healthLife/deleteHealthLife',
  selectHealthLifeByLifeId: '/operate/healthLife/selectHealthLifeByLifeId',
  selectHealthLifeByPage: '/operate/healthLife/selectHealthLifeByPage'
}
