export interface Apis {
  /** 新增 */
  insertManhole: string;
  /** 修改 */
  updateManhole: string;
  /** 删除 */
  deleteManhole: string;
  /** 解绑井盖设备 */
  updateUnBindManhole: string;
  /** 详情 */
  selectManholeByManholeId: string;
  /** 井盖分页 */
  selectManholeByPage: string;
  /** 导入 */
  importManhole: string;
}

export const apis: Apis = {
  insertManhole: '/business/manhole/insertManhole',
  updateManhole: '/business/manhole/updateManhole',
  deleteManhole: '/business/manhole/deleteManhole',
  updateUnBindManhole: '/business/manhole/updateUnBindManhole',
  selectManholeByManholeId: '/business/manhole/selectManholeByManholeId',
  selectManholeByPage: '/business/manhole/selectManholeByPage',

  importManhole: '/business/manhole/importManhole'
}
