export interface Apis {
  /** 新增 */
  insertParkStrategy: string;
  /** 删除 */
  deleteParkStrategy: string;
  /** 详情 */
  selectParkStrategyByStrategyId: string;
  /** 分页查询列表 */
  selectParkStrategyByPage: string;
}
export const apis: Apis = {
  insertParkStrategy: '/operate/parkStrategy/insertParkStrategy',
  deleteParkStrategy: '/operate/parkStrategy/deleteParkStrategy',
  selectParkStrategyByStrategyId: '/operate/parkStrategy/selectParkStrategyByStrategyId',
  selectParkStrategyByPage: '/operate/parkStrategy/selectParkStrategyByPage'
}
