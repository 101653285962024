export interface Apis {
  /** 无锡瑞景大屏总览 */
  selectSumProjectList: string;
  /** 无锡瑞景大屏二级地图页 */
  selectSumProjectDetailList: string;
  /** 获取工牌详情 */
  selectWorkCardDetail: string;
}
export const apis: Apis = {
  selectSumProjectList: '/business/ruiJing/selectSumProjectList',
  selectSumProjectDetailList: '/business/ruiJing/selectSumProjectDetailList',
  selectWorkCardDetail: '/business/ruiJing/selectWorkCardDetail'
}
