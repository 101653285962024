import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/manholeException',
    name: 'manholeException',
    meta: {
      title: '告警例外管理'
    },
    component: () => import('@/views/manholeException/List.vue')
  }
]

export default router
