import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  {
    path: '/scenarios',
    name: 'scenariosList',
    component: () => import('@/views/scenarios/List.vue'),
    meta: {
      title: '预案管理'
    }
  },
  {
    path: '/scenarios/add',
    name: 'scenariosAdd',
    component: () => import('@/views/scenarios/Add.vue'),
    meta: {
      title: '新增预案'
    }
  },
  {
    path: '/scenarios/edit:scenariosId',
    name: 'scenariosEdit',
    component: () => import('@/views/scenarios/Add.vue'),
    meta: {
      title: '编辑预案'
    }
  },
  {
    path: '/scenarios/detail:scenariosId',
    name: 'scenariosDetail',
    component: () => import('@/views/scenarios/Detail.vue'),
    meta: {
      title: '查看'
    }
  }

]

export default router
