import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/projectList',
    name: 'projectList',
    component: () => import('@/views/project/List.vue'),
    meta: {
      title: '项目基本信息'
    }
  },
  {
    path: '/projectgrid',
    name: 'projectgrid',
    component: () => import('@/views/project/ProjectGrid.vue'),
    meta: {
      title: '项目网格'
    }
  },
  {
    path: '/projectList/add',
    name: 'projectAdd',
    component: () => import('@/views/project/Add.vue'),
    meta: {
      title: '添加项目'
    }
  },
  {
    path: '/projectList/detail',
    name: 'projectDetail',
    component: () => import('@/views/project/Detail.vue'),
    redirect: '/projectList',
    children: [
      {
        path: 'base',
        component: () => import('@/views/project/Base.vue'),
        meta: {
          title: '项目详情'
        }
      },
      {
        path: 'person',
        component: () => import('@/views/project/Person.vue'),
        meta: {
          title: '项目详情'
        }
      },
      {
        path: 'area',
        component: () => import('@/views/project/Area.vue'),
        meta: {
          title: '项目详情'
        }
      }
    ]
  },
  {
    path: '/projectList/areaSet',
    component: () => import('@/views/project/AreaSet.vue'),
    meta: {
      title: '项目区域设置范围',
      hasQuery: true,
      backUrl: '/projectList/detail/area'
    }
  }
]

export default router
