// 组织架构
const url = '/business/iparkDept/'
export interface Apis {
  departmentList: string;
  departmentAdd: string;
  departmentUpdate: string;
  departmentDelete: string;
}

export const apis: Apis = {
  departmentList: url + 'selectIparkDeptByList',
  departmentAdd: url + 'insertIparkDept',
  departmentUpdate: url + 'updateIparkDept',
  departmentDelete: url + 'deleteIparkDept'
}
