import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/lostGoodsList',
    name: 'lostGoodsList',
    meta: {
      title: '失物招领'
    },
    component: () => import('@/views/lostGoods/List.vue')
  },
  {
    path: '/lostGoodsList/detail:id',
    name: 'lostGoodsDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/lostGoods/Detail.vue')
  }
]

export default router
