export interface Apis {
  /** 新增维护记录 */
  insertMaintainRecord: string;
  /** 批量新增维护记录 */
  insertAllMaintainRecord: string;
  /** 养护记录列表 */
  selectMaintainRecordByPage: string;
  /** 编辑维护记录 */
  updateMaintainRecord: string;
  /** 删除维护记录 */
  deleteMaintainRecord: string;
  /** 查询养护记录图表 */
  statisticsMaintain: string;
  // 养护类型配置
  /** 查询养护类型配置 */
  selectConfigByList: string;
  /** 新增养护类型配置 */
  insertConfig: string;
  /** 修改养护类型配置 */
  updateConfig: string;
  /** 删除养护类型配置 */
  deleteDataConfig: string;

  /** 苗木类别配置 */
  /** 查询苗木类别配置 */
  selectSeedlingTypeByList: string;
  /** 新增苗木类别配置 */
  insertSeedlingType: string;
  /** 修改苗木类别配置 */
  updateSeedlingType: string;
  /** 删除苗木类别配置 */
  deleteSeedlingType: string;
}
export const apis: Apis = {
  insertMaintainRecord: '/operate/maintainRecord/insertMaintainRecord',
  insertAllMaintainRecord: '/operate/maintainRecord/insertAllMaintainRecord',
  selectMaintainRecordByPage: '/operate/maintainRecord/selectMaintainRecordByPage',
  updateMaintainRecord: '/operate/maintainRecord/updateMaintainRecord',
  deleteMaintainRecord: '/operate/maintainRecord/deleteMaintainRecord',
  statisticsMaintain: '/operate/maintainRecord/statisticsMaintain',
  selectConfigByList: '/operate/config/selectConfigByList',
  insertConfig: '/operate/config/insertConfig',
  updateConfig: '/operate/config/updateConfig',
  deleteDataConfig: '/operate/config/deleteDataConfig',

  /** 苗木类别配置 */
  /** 查询苗木类别配置 */
  selectSeedlingTypeByList: '/operate/seedlingType/selectSeedlingTypeByList',
  /** 新增苗木类别配置 */
  insertSeedlingType: '/operate/seedlingType/insertSeedlingType',
  /** 修改苗木类别配置 */
  updateSeedlingType: '/operate/seedlingType/updateSeedlingType',
  /** 删除苗木类别配置 */
  deleteSeedlingType: '/operate/seedlingType/deleteSeedlingType'
}
