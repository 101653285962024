import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/insectMonitor',
    name: 'insectMonitor',
    meta: {
      title: '虫情监测'
    },
    component: () => import('@/views/insectMonitor/List.vue')
  },
  {
    path: '/insectMonitor/detail:projectId',
    name: 'insectMonitorDetail',
    component: () => import('@/views/insectMonitor/Detail.vue'),
    redirect: '/insectMonitor',
    children: [
      {
        path: '/insectMonitor/detail:projectId/map',
        name: 'insectMonitorDetailMap',
        component: () => import('@/views/insectMonitor/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/insectMonitor/detail:projectId/list',
        name: 'insectMonitorDetailList',
        component: () => import('@/views/insectMonitor/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }

    ]
  },
  {
    path: '/insectMonitor/detail:projectId/map/recommend:insectName',
    name: 'insectMonitorRecommendMap',
    component: () => import('@/views/insectMonitor/Recommend.vue'),
    meta: {
      title: '智库推荐'
    }

  },
  {
    path: '/insectMonitor/detail:projectId/list/DevDetail:deviceId/recommend:insectName',
    name: 'insectMonitorRecommendDev',
    component: () => import('@/views/insectMonitor/Recommend.vue'),
    meta: {
      title: '智库推荐'
    }
  },
  {
    path: '/insectMonitor/detail:projectId/list/DevDetail:deviceId/historyMonitor/recommend:insectName',
    name: 'insectMonitorRecommendHistory',
    component: () => import('@/views/insectMonitor/Recommend.vue'),
    meta: {
      title: '智库推荐'
    }

  },
  {
    path: '/insectAnalyze',
    name: 'insectAnalyze',
    meta: {
      title: '虫情分析'
    },
    component: () => import('@/views/insectAnalyze/insectAnalyze.vue')
  }, {
    path: '/insectMonitor/detail:projectId/list/DevDetail:deviceId',
    name: 'insectMonitorDevDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/insectMonitor/DevDetail.vue')
  },
  {
    path: '/insectMonitor/detail:projectId/map/historyMonitor:deviceId',
    name: 'historyMonitorMap',
    meta: {
      title: '历史监测'
    },
    component: () => import('@/views/insectMonitor/HistoryMonitor.vue')
  },
  {
    path: '/insectMonitor/detail:projectId/list/DevDetail:deviceId/historyMonitor',
    name: 'historyMonitorList',
    meta: {
      title: '历史监测'
    },
    component: () => import('@/views/insectMonitor/HistoryMonitor.vue')
  },
  {
    path: '/insectMonitor/detail:projectId/list/DevDetail:deviceId/historyMonitor',
    name: 'detailHistoryMonitor',
    meta: {
      title: '历史监测'
    },
    component: () => import('@/views/insectMonitor/HistoryMonitor.vue')
  },
  {
    path: '/insectMonitor/detail:projectId/map/HistoryData:deviceId',
    name: 'historyDataMap',
    meta: {
      title: '历史数据'
    },
    component: () => import('@/views/insectMonitor/HistoryData.vue')
  },
  {
    path: '/insectMonitor/detail:projectId/list/HistoryData:deviceId',
    name: 'historyDataList',
    meta: {
      title: '历史数据'
    },
    component: () => import('@/views/insectMonitor/HistoryData.vue')
  },
  {
    path: '/insectMonitor/detail:projectId/list/DevDetail:deviceId/HistoryData',
    name: 'detailHistoryData',
    meta: {
      title: '历史数据'
    },
    component: () => import('@/views/insectMonitor/HistoryData.vue')
  }
]

export default router
