import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  {
    path: '/order',
    name: 'order',
    meta: {
      title: '我的工单'
    },
    component: () => import('@/views/newOrder/List.vue')
  },
  {
    path: '/order/add',
    name: 'orderAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/newOrder/Add.vue')
  },
  {
    path: '/order/detail:id',
    name: 'orderDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/newOrder/Detail.vue')
  },
  {
    path: '/orderRecord',
    name: 'orderRecord',
    meta: {
      title: '工单记录'
    },
    component: () => import('@/views/newOrder/Record.vue')
  },
  {
    path: '/orderRecord/detail:id',
    name: 'orderRecordDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/newOrder/Detail.vue')
  },
  {
    path: '/orderStatistics',
    name: 'orderStatistics',
    meta: {
      title: '工单统计'
    },
    component: () => import('@/views/newOrder/Statistics.vue')
  },
  {
    path: '/orderExportList',
    name: 'orderExportList',
    meta: {
      title: '导出列表'
    },
    component: () => import('@/views/newOrder/ExportList.vue')
  }
]

export default router
