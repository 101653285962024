export interface Apis {
  /** 井盖分页 */
  selectSumProjectList: string;
  /** 井盖详情分页 */
  selectManholeInfoPage: string;
  /** 井盖详情地图 */
  selectManholeInfoList: string;
  /** 解除绑定 */
  updateUnBindManhole: string;
  /** 绑定井盖设备 */
  updateBindManhole: string;
  /** 未绑定设备列表 */
  selectManholeListUnBindlist: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/manhole/selectSumProjectList',
  selectManholeInfoPage: '/business/manhole/selectManholeInfoPage',
  selectManholeInfoList: '/business/manhole/selectManholeInfoList',
  updateUnBindManhole: '/business/manhole/updateUnBindManhole',
  updateBindManhole: '/business/manhole/updateBindManhole',
  selectManholeListUnBindlist: '/business/manhole/selectManholeListUnBindlist'
}
