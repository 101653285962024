import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/inspectionMonth',
    name: 'inspectionMonth',
    meta: {
      title: '巡查月报'
    },
    component: () => import('@/views/inspectionMonth/Index.vue')
  }
]

export default router
