import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/appointproject',
    meta: {
      title: '预约项目'
    },
    component: () => import('@/views/appointmanage/project/Project.vue')
  },
  {
    path: '/appointproject/add',
    name: 'appointProjectAdd',
    meta: {
      title: '添加项目'
    },
    component: () => import('@/views/appointmanage/project/ProjectAdd.vue')
  },
  {
    path: '/appointproject/detail:id',
    name: 'appointProjectDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/appointmanage/project/ProjectDetail.vue')
  },
  {
    path: '/appointtourist',
    meta: {
      title: '预约游客'
    },
    component: () => import('@/views/appointmanage/tourist/Tourist.vue')
  }
]

export default router
