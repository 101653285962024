import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/locationList',
    name: 'locationList',
    meta: {
      title: '终端定位'
    },
    component: () => import('@/views/location/List.vue')
  },
  {
    path: '/locationList/locationDetail',
    name: 'locationDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/location/Detail.vue')
  },
  {
    path: '/locationList/locationDetail/detailPlayback:id',
    name: 'detailPlayback',
    meta: {
      title: '轨迹回放',
      hasQuery: true,
      backUrl: '/locationList/locationDetail'
    },
    component: () => import('@/views/location/DetailPlayback.vue')
  }
]

export default router
