// 接口名定义
export interface Apis {
  // 获取项目列表
  selectSumProjectList: string;
  // 不分页查询设备（地图页）
  selectSmoke: string;
  // 分页查询设备列表
  selectSmokeDetailPage: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/smoke/selectSumProjectList',
  selectSmoke: '/business/smoke/selectSmoke',
  selectSmokeDetailPage: '/business/smoke/selectSmokeDetailPage'
}
