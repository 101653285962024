// 接口名定义
export interface Apis {
  // 获取项目列表
  selectSumProjectList: string;
  // 不分页查询垃圾桶列表
  selectJoinnin: string;
  // 分页查询垃圾桶列表
  selectJoinninDetailPage: string;
  // 历史数据折线图详情
  selectElectricMap: string;
  // 历史数据分页展示详情
  selectJoinninPage: string;
  // 开合闸记录
  selectJoinninRecordPage: string;
  // 开启断路器
  openJoinnin: string;
  // 关闭断路器
  closeJoinnin: string;
  // 用电统计
  selectElectricStatistic: string;
  // 耗电预测
  selectJoinninForecast: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/joinnin/selectSumProjectList',
  selectJoinnin: '/business/joinnin/selectJoinnin',
  selectJoinninDetailPage: '/business/joinnin/selectJoinninDetailPage',
  selectElectricMap: '/business/joinnin/selectElectricMap',
  selectJoinninPage: '/business/joinnin/selectJoinninPage',
  selectJoinninRecordPage: '/business/joinnin/selectJoinninRecordPage',
  openJoinnin: '/business/joinnin/openJoinnin',
  closeJoinnin: '/business/joinnin/closeJoinnin',
  selectElectricStatistic: '/business/joinnin/selectElectricStatistic',
  selectJoinninForecast: '/business/joinnin/selectJoinninForecast'
}
