export interface Apis {
  /** 查看质量安全列表 */
  selectQualitySafetyByPage: string;
  /** 新增质量安全 */
  insertQualitySafety: string;
  /** 查看质量安全 */
  selectQualitySafetyBySafetyId: string;
  /** 下发工单 */
  placeOrder: string;
}
export const apis: Apis = {
  selectQualitySafetyByPage: '/business/qualitySafety/selectQualitySafetyByPage',
  insertQualitySafety: '/business/qualitySafety/insertQualitySafety',
  selectQualitySafetyBySafetyId: '/business/qualitySafety/selectQualitySafetyBySafetyId',
  placeOrder: '/business/qualitySafety/placeOrder'
}
