import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/operationrecord',
    component: () => import('@/views/operationmanage/OperationRecord.vue'),
    meta: {
      title: '经营记录'
    }
  },
  {
    path: '/operationrecord/add',
    component: () => import('@/views/operationmanage/OperationRecordAdd.vue'),
    meta: {
      title: '新增经营记录'
    }
  },
  {
    path: '/operationrecord/view:operatingId',
    name: 'operationRecordView',
    component: () => import('@/views/operationmanage/OperationRecordView.vue'),
    meta: {
      title: '经营记录详情'
    }
  },
  {
    path: '/operationrecord/edit:operatingId',
    name: 'operationRecordEdit',
    component: () => import('@/views/operationmanage/OperationRecordAdd.vue'),
    meta: { title: '编辑经营记录' }
  },
  {
    path: '/operationMonthReport',
    name: 'operationMonthReport',
    component: () => import('@/views/operationmanage/MonthReport.vue'),
    meta: {
      title: '经营月报'
    }
  },
  {
    path: '/operationMonthReport/detail:projectId|:yearMonth',
    name: 'operationMonthReportDetail',
    component: () => import('@/views/operationmanage/MonthReportDetail.vue'),
    meta: {
      title: '经营月报'
    }
  },
  {
    path: '/planReport',
    name: 'PlanReport',
    component: () => import('@/views/operationmanage/PlanReport.vue'),
    meta: {
      title: '计划汇报'
    }
  },
  {
    path: '/planReport/Add',
    name: 'WeekPlanAdd',
    component: () => import('@/views/operationmanage/planReportAdd/WeekPlanAdd.vue'),
    meta: {
      title: '周工作计划新增'
    }
  },
  {
    path: '/planReport/Edit:reportId&type=:type',
    name: 'WeekPlanEdit',
    component: () => import('@/views/operationmanage/planReportAdd/WeekPlanAdd.vue'),
    meta: {
      title: '周工作计划编辑'
    }
  },
  {
    path: '/planReport/Add',
    name: 'WeekBerichtAdd',
    component: () => import('@/views/operationmanage/planReportAdd/WeekPlanAdd.vue'),
    meta: {
      title: '周工作汇报新增'
    }
  },
  {
    path: '/planReport/Edit:reportId&type=:type',
    name: 'WeekBerichtEdit',
    component: () => import('@/views/operationmanage/planReportAdd/WeekPlanAdd.vue'),
    meta: {
      title: '周工作汇报编辑'
    }
  },
  {
    path: '/planReport/Add',
    name: 'MonthPlanAdd',
    component: () => import('@/views/operationmanage/planReportAdd/MonthPlanAdd.vue'),
    meta: {
      title: '月工作计划新增'
    }
  },
  {
    path: '/planReport/Edit:reportId&type=:type',
    name: 'MonthPlanEdit',
    component: () => import('@/views/operationmanage/planReportAdd/MonthPlanAdd.vue'),
    meta: {
      title: '月工作计划编辑'
    }
  },
  {
    path: '/planReport/detail:reportId',
    name: 'PlanReportDetail',
    component: () => import('@/views/operationmanage/PlanDetail.vue'),
    meta: {
      title: '计划汇报详情'
    }
  },
  {
    path: '/planApproval',
    name: 'PlanApproval',
    component: () => import('@/views/operationmanage/PlanApproval.vue'),
    meta: {
      title: '计划审批'
    }
  },
  {
    path: '/planApproval/detail:reportId&approve=:approve',
    name: 'PlanApprovalDetail',
    component: () => import('@/views/operationmanage/PlanDetail.vue'),
    meta: {
      title: '计划审批详情'
    }
  }
]

export default router
