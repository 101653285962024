import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/dispatch',
    name: 'dispatchList',
    meta: {
      title: '事件列表'
    },
    component: () => import('@/views/dispatch/List.vue')
  },
  {
    path: '/dispatch/add',
    name: 'dispatchAdd',
    meta: {
      title: '添加事件'
    },
    component: () => import('@/views/dispatch/Add.vue')
  },
  {
    path: '/dispatch/verify:id',
    name: 'dispatchVerify',
    meta: {
      title: '核实事件'
    },
    component: () => import('@/views/dispatch/Verify.vue')
  },
  {
    path: '/dispatch/dispose:id',
    name: 'dispatchDispose',
    meta: {
      title: '处置事件'
    },
    component: () => import('@/views/dispatch/Dispose.vue')
  },
  {
    path: '/dispatch/detail:id',
    name: 'dispatchDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/dispatch/Detail.vue')
  },
  {
    path: '/dispatch/verify:id/detail:scenariosId',
    name: 'dispatchScenariosDetail',
    component: () => import('@/views/scenarios/Detail.vue'),
    meta: {
      title: '查看'
    }
  }
]

export default router
