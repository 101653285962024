import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/healthLife',
    name: 'healthLifeList',
    meta: {
      title: '健康养生'
    },
    component: () => import('@/views/healthLife/List.vue')
  },
  {
    path: '/healthLife/add',
    name: 'healthLife',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/healthLife/Add.vue')
  },
  {
    path: '/healthLife/detail:id',
    name: 'healthLifeDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/healthLife/Detail.vue')
  }
]

export default router
