export interface Apis {
  /** 新增 */
  insertUpdateScenicIntroduce: string;
  /** 删除 */
  deleteScenicIntroduce: string;
  /** 详情 */
  selectScenicIntroduceByProjectId: string;
  /** 分类查询 */
  selectScenicIntroduceByPage: string;
}

export const apis: Apis = {
  insertUpdateScenicIntroduce: '/operate/scenicIntroduce/insertUpdateScenicIntroduce',
  deleteScenicIntroduce: '/operate/scenicIntroduce/deleteScenicIntroduce',
  selectScenicIntroduceByProjectId: '/operate/scenicIntroduce/selectScenicIntroduceByProjectId',
  selectScenicIntroduceByPage: '/operate/scenicIntroduce/selectScenicIntroduceByPage'
}
