import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/notice',
    name: 'notice',
    meta: {
      title: '公告发布'
    },
    component: () => import('@/views/notice/List.vue')
  },
  {
    path: '/notice/add',
    name: 'noticeAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/notice/Add.vue')
  },
  {
    path: '/notice/update:id',
    name: 'noticeUpdate',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/notice/Add.vue')
  }
]

export default router
