export interface Apis {
  /** 评价列表 */
  selectAdminEvaluateByPage: string;
  /** 评价详情 */
  selectEvaluateById: string;
  /** 删除 */
  deleteEvaluate: string;
  /** 显示/屏蔽 */
  shieldEvaluate: string;
  /** 置顶 */
  topEvaluate: string;
  /** 管理列表 */
  selectEvaluateManageByPage: string;
  /** 查询评价类目与对象 */
  selectEvaluateTypeObjectByList: string;

}
export const apis: Apis = {
  selectAdminEvaluateByPage: '/operate/evaluate/selectAdminEvaluateByPage',
  selectEvaluateById: '/operate/evaluate/selectEvaluateById',
  deleteEvaluate: '/operate/evaluate/deleteEvaluate',
  shieldEvaluate: '/operate/evaluate/shieldEvaluate',
  topEvaluate: '/operate/evaluate/topEvaluate',
  selectEvaluateManageByPage: '/operate/evaluate/selectEvaluateManageByPage',
  selectEvaluateTypeObjectByList: '/operate/evaluate/selectEvaluateTypeObjectByList'
}
