import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/engineeringList',
    name: 'engineeringList',
    component: () => import('@/views/engineering/List.vue'),
    meta: {
      title: '工程信息'
    }
  },
  {
    path: '/engineeringList/addList',
    name: 'AddList',
    meta: {
      title: '新增工程'
    },
    component: () => import('@/views/engineering/AddList.vue')
  },
  {
    path: '/engineeringList/updateList:id',
    name: 'UpdateList',
    meta: {
      title: '编辑工程'
    },
    component: () => import('@/views/engineering/AddList.vue')
  },
  {
    path: '/engineeringList/detailList:id',
    name: 'DetailList',
    meta: {
      title: '工程详情'
    },
    component: () => import('@/views/engineering/DetailList.vue')
  }
]

export default router
