
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { password } from '@/utils/validate'
interface UserInfo {
  userId: string;
  initPwd: boolean;
}

@Component
export default class ResetPassword extends Vue {
  private validatePass = (rule: object, value: string, callback: Function) => {
    if (value !== this.formData.newPassword) {
      callback(new Error('两次输入密码不一致!'))
    } else {
      callback()
    }
  }

  private showDialog = false
  private formData = {
    newPassword: '',
    confirmPassword: ''
  }

  private rules = {
    newPassword: [
      { required: true, message: '请输入新密码', trigger: ['blur', 'change'] },
      { validator: password, trigger: ['blur', 'change'] }
    ],
    confirmPassword: [
      { required: true, message: '请再次输入新密码', trigger: ['blur', 'change'] },
      { validator: this.validatePass, trigger: ['blur', 'change'] }]
  }

  get userInfo (): UserInfo {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo') as string) as UserInfo
    return userInfo || { userId: '', isInitPwd: false }
  }

  get userId () {
    return this.userInfo.userId
  }

  get initPwd () {
    return this.userInfo.initPwd
  }

  created (): void {
    this.showDialog = this.initPwd
  }

  // 密码修改
  submitPassword () {
    (this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        const info = {
          newPassword: this.formData.newPassword,
          userId: this.userId
        }
        this.$axios.post(this.$apis.userInfo.updateInitPassword, info).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.showDialog = false
          this.userInfo.initPwd = false
          sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
        })
      }
    })
  }
}
