// 清单
const url = '/business/transferProduct/'
// 事项
const urls = '/business/transferIssue/'
export interface Apis {
  /** 清单导入 */
  importTransferProduct: string;
  /** 清单详情 */
  selectTransferProductByProductId: string;
  /** 新增清单 */
  insertTransferProduct: string;
  /** 修改清单 */
  updateTransferProduct: string;
  /** 删除清单 */
  deleteTransferProduct: string;
  /** 清单分页 */
  selectTransferProductByPage: string;
  /** 清点清单 */
  transfer: string;
  /** 事项详情 */
  selectTransferIssueByIssueId: string;
  /** 新增事项 */
  insertTransferIssue: string;
  /** 删除事项 */
  deleteTransferIssue: string;
  /** 处理事项 */
  dealTransferIssue: string;
  /** 事项分页 */
  selectTransferIssueByPage: string;

}
export const apis: Apis = {
  importTransferProduct: url + 'importTransferProduct',
  selectTransferProductByProductId: url + 'selectTransferProductByProductId',
  insertTransferProduct: url + 'insertTransferProduct',
  updateTransferProduct: url + 'updateTransferProduct',
  deleteTransferProduct: url + 'deleteTransferProduct',
  selectTransferProductByPage: url + 'selectTransferProductByPage',
  transfer: url + 'transfer',
  selectTransferIssueByIssueId: urls + 'selectTransferIssueByIssueId',
  insertTransferIssue: urls + 'insertTransferIssue',
  deleteTransferIssue: urls + 'deleteTransferIssue',
  dealTransferIssue: urls + 'dealTransferIssue',
  selectTransferIssueByPage: urls + 'selectTransferIssueByPage'
}
