import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/handoverList',
    name: 'handoverList',
    meta: {
      title: '移交清单'
    },
    component: () => import('@/views/handover/HandoverList.vue')
  },
  {
    path: '/handoverMatter',
    name: 'handoverMatter',
    meta: {
      title: '移交事项记录'
    },
    component: () => import('@/views/handover/HandoverMatter.vue')
  },
  {
    path: '/handoverMatter/detail:issueId',
    name: 'HandoverMatterDetail',
    meta: {
      title: '移交事项详情'
    },
    component: () => import('@/views/handover/HandoverMatterDetail.vue')
  }
]

export default router
