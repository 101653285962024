import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/parkStrategy',
    name: 'parkStrategyList',
    meta: {
      title: '园区攻略'
    },
    component: () => import('@/views/parkStrategy/List.vue')
  },
  {
    path: '/parkStrategy/add',
    name: 'parkStrategyAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/parkStrategy/Add.vue')
  },
  {
    path: '/parkStrategy/detail:id',
    name: 'parkStrategyDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/parkStrategy/Detail.vue')
  }
]

export default router
