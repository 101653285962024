const baseUrl = '/business'
export interface Apis {
  /** 新增问卷 */
  insertQuestionnaire: string;
  /** 上下架问卷 */
  publishQuestionnaireById: string;
  /** 更新问卷 */
  updateQuestionnaire: string;
  /** 删除问卷 */
  deleteQuestionnaire: string;
  /** 分页查询问卷列表 */
  selectQuestionnaireByPage: string;
  /** 查看问卷详情(选项占比) */
  selectQuestionDetail: string;
  /** 查询问卷详情(问题和答案) */
  selectQuestionnaireDetail: string;
  /** 查看填空题答案(查看全部按钮) */
  selectGapFillingQuestionByPage: string;
  /** 查询满意度问卷结果分析 */
  selectMydQuestionnaireResult: string;
  /** 导出满意度问卷分析表 */
  exportMydQuestionnaireStatistic: string;
  /** 导出满意度问卷答案 */
  exportMydQuestionnaireResult: string;
}
export const apis: Apis = {
  insertQuestionnaire: baseUrl + '/questionnaireSurvey/insertQuestionnaire',
  publishQuestionnaireById: baseUrl + '/questionnaireSurvey/publishQuestionnaireById',
  updateQuestionnaire: baseUrl + '/questionnaireSurvey/updateQuestionnaire',
  deleteQuestionnaire: baseUrl + '/questionnaireSurvey/deleteQuestionnaire',
  selectQuestionnaireByPage: baseUrl + '/questionnaireSurvey/selectQuestionnaireByPage',
  selectQuestionDetail: baseUrl + '/questionnaireSurvey/selectQuestionDetail',
  selectQuestionnaireDetail: baseUrl + '/questionnaireSurvey/selectQuestionnaireDetail',
  selectGapFillingQuestionByPage: baseUrl + '/questionnaireSurvey/selectGapFillingQuestionByPage',
  selectMydQuestionnaireResult: baseUrl + '/questionnaireSurvey/selectMydQuestionnaireResult',
  exportMydQuestionnaireStatistic: baseUrl + '/questionnaireSurvey/exportMydQuestionnaireStatistic',
  exportMydQuestionnaireResult: baseUrl + '/questionnaireSurvey/exportMydQuestionnaireResult'
}
