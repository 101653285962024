import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/screen',
    name: 'screen',
    meta: {
      title: '智慧大屏'
    },
    component: () => import('@/views/screen/List.vue')
  }
]

export default router
