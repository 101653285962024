import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  {
    path: '/materialmanage',
    meta: { title: '物料管理', hasBack: false },
    component: () => import('@/views/material/manage/Manage.vue')
  },
  {
    path: '/materialmanage/add',
    meta: { title: '新增物料' },
    component: () => import('@/views/material/manage/ManageAdd.vue')
  },
  {
    path: '/materialmanage/edit',
    meta: { title: '编辑物料' },
    component: () => import('@/views/material/manage/ManageAdd.vue')
  },
  {
    path: '/materialmanage/detail',
    meta: { title: '物料详情' },
    component: () => import('@/views/material/manage/ManageDetail.vue')
  },
  {
    path: '/materialwarehousing',
    meta: { title: '入库管理', hasBack: false },
    component: () => import('@/views/material/warehousing/Warehousing.vue')
  },
  {
    path: '/materialwarehousing/add',
    meta: { title: '添加入库单' },
    component: () => import('@/views/material/warehousing/WarehousingAdd.vue')
  },
  {
    path: '/materialwarehousing/detail',
    meta: { title: '入库详情' },
    component: () => import('@/views/material/warehousing/WarehousingDetail.vue')
  },
  {
    path: '/materialexwarehouse',
    meta: { title: '出库管理', hasBack: false },
    component: () => import('@/views/material/exwarehouse/Exwarehouse.vue')
  },
  {
    path: '/materialexwarehouse/add',
    meta: { title: '添加出库单' },
    component: () => import('@/views/material/exwarehouse/ExwarehouseAdd.vue')
  },
  {
    path: '/materialexwarehouse/detail',
    meta: { title: '出库详情' },
    component: () => import('@/views/material/exwarehouse/ExwarehouseDetail.vue')
  },
  {
    path: '/flowingwater',
    meta: { title: '出入库流水' },
    component: () => import('@/views/material/flowingwater/Index.vue')
  },
  {
    path: '/inventoryquery',
    meta: { title: '库存查询' },
    component: () => import('@/views/material/inventoryquery/Index.vue')
  },
  {
    path: '/suppliermanage',
    meta: { title: '供应商管理' },
    component: () => import('@/views/material/suppliermanage/List.vue')
  },
  {
    path: '/suppliermanage/add',
    meta: { title: '新增供应商' },
    component: () => import('@/views/material/suppliermanage/Add.vue')
  },
  {
    path: '/suppliermanage/edit:supplierId',
    meta: { title: '编辑供应商' },
    component: () => import('@/views/material/suppliermanage/Add.vue')
  },
  {
    path: '/suppliermanage/detail:supplierId',
    meta: { title: '供应商详情' },
    component: () => import('@/views/material/suppliermanage/Detail.vue')
  },
  {
    path: '/machinemanage',
    meta: { title: '机械管理', hasBack: false },
    component: () => import('@/views/material/machine/Machine.vue')
  },
  {
    path: '/machinemanage/add',
    meta: { title: '新增机械' },
    component: () => import('@/views/material/machine/MachineAdd.vue')
  },
  {
    path: '/machinemanage/edit',
    meta: { title: '编辑机械' },
    component: () => import('@/views/material/machine/MachineAdd.vue')
  },
  {
    path: '/machinemanage/detail',
    meta: { title: '机械详情' },
    component: () => import('@/views/material/machine/MachineDetail.vue')
  }
]

export default router
