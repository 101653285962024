// 接口名定义
export interface Apis {
  /** 获取项目列表 */
  selectSumProjectPage: string;
  /** 割草机（地图） */
  selectProjectSumecDeviceList: string;
  /** 分页查询设备列表 */
  selectSumecDevicePage: string;
  /** 割草机器人详情 */
  selectSumecDeviceDetail: string;
  /** 历史数据 */
  selectSumecMowingPage: string;
  /** 割草机器人任务配置 */
  selectSumecConfigByDeviceId: string;
    // 同步时间
  insertSumecTime: string;
    // 同步信息
  insertSumecData: string;
    // 配置割草机时间段
  insertSumecCuttime: string;
  // 发送割草机指令
  insertSumecInstructions: string;
  // 查询区域
  selectSumecConfigMapByList: string;
  // 修改区域
  updateSumecConfigMap: string;
  // 发送割草机切换地图指令
  insertSumecInstructionsMap: string;
}

export const apis: Apis = {
  selectSumProjectPage: '/business/sumec/selectSumProjectPage',
  selectProjectSumecDeviceList: '/business/sumec/selectProjectSumecDeviceList',
  selectSumecDevicePage: '/business/sumec/selectSumecDevicePage',
  selectSumecDeviceDetail: '/business/sumec/selectSumecDeviceDetail',
  selectSumecMowingPage: '/business/sumec/selectSumecMowingPage',
  // 割草机器人任务配置
  selectSumecConfigByDeviceId: '/device/sumecConfig/selectSumecConfigByDeviceId',
  insertSumecTime: '/device/sumecConfig/insertSumecTime',
  insertSumecCuttime: '/device/sumecConfig/insertSumecCuttime',
  insertSumecInstructions: '/device/sumecConfig/insertSumecInstructions',
  insertSumecData: '/device/sumecConfig/insertSumecData',
  selectSumecConfigMapByList: '/device/sumecConfig/selectSumecConfigMapByList',
  updateSumecConfigMap: '/device/sumecConfig/updateSumecConfigMap',
  insertSumecInstructionsMap: '/device/sumecConfig/insertSumecInstructionsMap'
}
