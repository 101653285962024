import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/realtimeTrafficList',
    name: 'realtimeTrafficList',
    meta: {
      title: '实时客流'
    },
    component: () => import('@/views/realtimeTraffic/List.vue')
  },
  {
    path: '/realtimeTrafficList/analyse:projectId',
    name: 'trafficAnalyse',
    meta: {
      title: '客流分析'
    },
    component: () => import('@/views/realtimeTraffic/Analyse.vue')
  }
]

export default router
