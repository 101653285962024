import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/carManageList',
    name: 'carManageList',
    meta: {
      title: '车辆信息管理'
    },
    redirect: '/carManageList/base',
    component: () => import('@/views/carManage/Index.vue'),
    children: [
      {
        path: 'base',
        name: 'carManageListBase',
        props: true,
        component: () => import('@/views/carManage/BaseList.vue'),
        meta: {
          title: '车辆信息管理',
          hasBack: false
        }
      },
      {
        path: 'maintain',
        name: 'carManageListMaintain',
        props: true,
        component: () => import('@/views/carManage/MaintainList.vue'),
        meta: {
          title: '车辆信息管理',
          hasBack: false
        }
      },
      {
        path: 'illegal',
        name: 'carManageListIllegal',
        props: true,
        component: () => import('@/views/carManage/IllegalList.vue'),
        meta: {
          title: '车辆信息管理',
          hasBack: false
        }
      }
    ]
  },
  {
    path: '/carManageList/base/addBase',
    name: 'addBase',
    meta: {
      title: '新增车辆'
    },
    component: () => import('@/views/carManage/AddBase.vue')
  },
  {
    path: '/carManageList/base/editBase:id',
    name: 'editBase',
    meta: {
      title: '编辑车辆'
    },
    component: () => import('@/views/carManage/AddBase.vue')
  },
  {
    path: '/carManageList/base/detailBase:id',
    name: 'detailBase',
    meta: {
      title: '车辆详情'
    },
    component: () => import('@/views/carManage/DetailBase.vue')
  },
  {
    path: '/carManageList/maintain/addMaintain',
    name: 'addMaintain',
    meta: {
      title: '新增维修信息'
    },
    component: () => import('@/views/carManage/AddMaintain.vue')
  },
  {
    path: '/carManageList/maintain/editMaintain:id',
    name: 'editMaintain',
    meta: {
      title: '编辑维修信息'
    },
    component: () => import('@/views/carManage/AddMaintain.vue')
  },
  {
    path: '/carManageList/illegal/addIllegal',
    name: 'addIllegal',
    meta: {
      title: '新增违章信息'
    },
    component: () => import('@/views/carManage/AddIllegal.vue')
  },
  {
    path: '/carManageList/illegal/editIllegal:id',
    name: 'editIllegal',
    meta: {
      title: '编辑违章信息'
    },
    component: () => import('@/views/carManage/AddIllegal.vue')
  }

]

export default router
