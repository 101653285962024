export interface Apis {
  /** 分页 */
  selectManholeExceptionByPage: string;
  /** 详情 */
  selectManholeExceptionByExceptionId: string;
  /** 删除 */
  deleteManholeException: string;
  /** 修改 */
  updateManholeException: string;
  /** 新增 */
  insertManholeException: string;
  /** 井盖不分页列表 */
  selectManholeByList: string;
  /** 取消 */
  cancelManholeException: string;
}

export const apis: Apis = {
  selectManholeExceptionByPage: '/business/manholeException/selectManholeExceptionByPage',
  selectManholeExceptionByExceptionId: '/business/manholeException/selectManholeExceptionByExceptionId',
  deleteManholeException: '/business/manholeException/deleteManholeException',
  updateManholeException: '/business/manholeException/updateManholeException',
  insertManholeException: '/business/manholeException/insertManholeException',
  selectManholeByList: '/business/manhole/selectManholeByList',
  cancelManholeException: '/business/manholeException/cancelManholeException'
}
