import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/smoke',
    name: 'smokeList',
    meta: {
      title: '智慧烟感'
    },
    component: () => import('@/views/smoke/List.vue')
  },
  {
    path: '/smoke/detail:projectId',
    name: 'smokeDetail',
    component: () => import('@/views/smoke/Detail.vue'),
    redirect: '/smoke',
    children: [
      {
        path: '/smoke/detail:projectId/map',
        name: 'smokeDetailMap',
        component: () => import('@/views/smoke/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/smoke/detail:projectId/list',
        name: 'smokeDetailList',
        component: () => import('@/views/smoke/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  }
]

export default router
