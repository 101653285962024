import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/qualitySafety',
    name: 'qualitySafety',
    meta: {
      title: '质量安全'
    },
    component: () => import('@/views/qualitySafety/List.vue')
  },
  {
    path: '/qualitySafety/Add',
    name: 'qualitySafetyAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/qualitySafety/Add.vue')
  },
  {
    path: '/qualitySafety/detail:safetyId',
    name: 'qualitySafetyDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/qualitySafety/Detail.vue')
  },
  {
    path: '/qualitySafety/orderDetail:id',
    name: 'qualitySafetyOrderDetail',
    meta: {
      title: '工单详情'
    },
    component: () => import('@/views/newOrder/Detail.vue')
  }
]

export default router
