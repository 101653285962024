// 接口名定义
export interface Apis {
  insertProject: string;
  updateProject: string;
  deleteProject: string;
  selectProjectById: string;
  /** 查询项目列表 */
  selectProjectByList: string;
  /** 查询所有项目 */
  selectProjectAllByList: string;
  selectProjectByPage: string;
  // 项目人员管理
  insertProjectUser: string;
  deleteDataProjectUser: string;
  selectProjectUserByList: string;
  // 项目区域管理
  insertProjectArea: string;
  updateProjectArea: string;
  deleteDataProjectArea: string;
  selectProjectAreaByList: string;
  // 电子围栏
  insertProjectLocation: string;
  deleteProjectLocation: string;
  /** 根据fenceCode删除电子围栏 */
  deleteLocationByFenceCode: string;
  selectProjectAreaById: string;
  projectLocationList: string;
  /** 查询项目下所有区域的电子围栏列表 */
  selectProjectAreaLocations: string;
  /** 查询项目及所有区域的电子围栏列表 */
  selectProjectAreaLocation: string;
  /** 街道接口 */
  selectVillageList: string;
  // 查询该用户下的所有能管理的项目列表
  selectMangeProjectByList: string;
  /** 区域详情 */
  selectProjectAreaInfo: string;
  /** 查询养护项目区域未绑点位设置 */
  selectProjectAreaYhUnBindPointList: string;
  /** 查询养护项目区域点位列表 */
  selectProjectAreaYhPointList: string;
  /** 查询养护项目区域列表 */
  selectProjectAreaYhList: string;
  /** 养护项目区域日签到点位 */
  selectProjectAreaYhDatePoint: string;
  /** -养护项目区域养护标准 */
  selectProjectAreaYhStandard: string;
}

export const apis: Apis = {
  insertProject: '/business/project/insertProject',
  updateProject: '/business/project/updateProject',
  deleteProject: '/business/project/deleteProject',
  selectProjectById: '/business/project/selectProjectById',
  selectProjectByList: '/business/project/selectProjectByList',
  selectProjectAllByList: '/business/project/selectProjectAllByList',
  selectProjectByPage: '/business/project/selectProjectByPage',
  // 项目人员管理
  insertProjectUser: '/business/projectUser/insertProjectUser',
  deleteDataProjectUser: '/business/projectUser/deleteDataProjectUser',
  selectProjectUserByList: '/business/projectUser/selectProjectUserByList',
  // 项目区域管理
  insertProjectArea: '/business/projectArea/insertProjectArea',
  updateProjectArea: '/business/projectArea/updateProjectArea',
  deleteDataProjectArea: '/business/projectArea/deleteDataProjectArea',
  selectProjectAreaByList: '/business/projectArea/selectProjectAreaByList',
  selectProjectAreaInfo: '/business/projectArea/selectProjectAreaInfo',
  selectProjectAreaYhUnBindPointList: '/business/projectAreaYh/selectProjectAreaYhUnBindPointList',
  selectProjectAreaYhPointList: '/business/projectAreaYh/selectProjectAreaYhPointList',
  selectProjectAreaYhList: '/business/projectAreaYh/selectProjectAreaYhList',
  selectProjectAreaYhDatePoint: '/business/projectAreaYh/selectProjectAreaYhDatePoint',
  selectProjectAreaYhStandard: '/business/projectAreaYh/selectProjectAreaYhStandard',
  // 电子围栏
  insertProjectLocation: '/business/projectArea/insertProjectLocation',
  deleteProjectLocation: '/business/projectArea/deleteProjectLocation',
  deleteLocationByFenceCode: '/business/projectArea/deleteLocationByFenceCode',
  selectProjectAreaById: '/business/projectArea/selectProjectAreaById',
  // 查询电子围栏列表
  projectLocationList: '/business/projectArea/selectProjectLocationList',
  // 查询区域电子围栏列表
  selectProjectAreaLocations: '/business/projectArea/selectProjectAreaLocations',
  selectProjectAreaLocation: '/business/projectArea/selectProjectAreaLocation',
  /** 街道接口 */
  selectVillageList: '/business/project/selectVillageList',
  selectMangeProjectByList: '/business/project/selectMangeProjectByList'
}
