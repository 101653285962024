
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ImportData extends Vue {
  @Prop({ default: '' }) private type!: string
  @Prop({ default: '' }) private params!: object

  private isShow = false

  getExportUrl () {
    const exportMap = new Map()
    exportMap.set('0', this.$apis.patrol.exportIssue) // 导出巡查问题清单
    exportMap.set('1', this.$apis.patrol.planexporttoexcel) // 导出巡查管理表单
    exportMap.set('2', this.$apis.patrol.taskexporttoexcel) // 导出巡查管理-执行记录-任务记录表单
    exportMap.set('3', this.$apis.patrol.facilityExcel) // 资产导出
    exportMap.set('6', this.$apis.questionSurvey.exportMydQuestionnaireStatistic) // 导出满意度问卷分析表
    exportMap.set('7', this.$apis.questionSurvey.exportMydQuestionnaireResult) // 导出满意度调查答卷表
    return exportMap.get(this.type)
  }

  onExport () {
    // console.log('下载查询条件', this.params)
    this.$axios.get(this.getExportUrl(), this.params).then(res => {
      if (res === 0) {
        this.$message.error('导出失败:当前条件下没有数据!')
      } else {
        this.isShow = true
      }
    })
  }

  onClose () {
    this.isShow = false
  }
}
