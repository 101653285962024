export interface Apis {
  /** 新增 */
  insertDispatch: string;
  /** 编辑 */
  updateDispatch: string;
  /** 核实 */
  verifyDispatch: string;
  /** 删除 */
  deleteDispatch: string;
  /** 详情 */
  selectDispatchByDispatchId: string;
  /** 分页 */
  selectDispatchByPage: string;
  /** 处置 */
  insertDispatchHandle: string;
  /** 预案列表 */
  selectDispatchScenariosByList: string;
  /** 取消 */
  cancelDispatch: string;
  /** 新增事件类型 */
  insertDispatchType: string;
  /** 删除事件类型 */
  deleteDataDispatchType: string;
  /** 修改事件类型 */
  updateDispatchType: string;
  /** 事件类型列表 */
  selectDispatchTypeByList: string;
  /** 智慧路牌列表 */
  selectGuideboard: string;
  /** 智慧路牌操作 */
  insertGuideboardSwitch: string;
}
export const apis: Apis = {
  insertDispatch: '/business/dispatch/insertDispatch',
  updateDispatch: '/business/dispatch/updateDispatch',
  verifyDispatch: '/business/dispatch/verifyDispatch',
  deleteDispatch: '/business/dispatch/deleteDispatch',
  selectDispatchByDispatchId: '/business/dispatch/selectDispatchByDispatchId',
  selectDispatchByPage: '/business/dispatch/selectDispatchByPage',
  insertDispatchHandle: '/business/dispatch/insertDispatchHandle',
  selectDispatchScenariosByList: '/business/dispatchScenarios/selectDispatchScenariosByList',
  cancelDispatch: '/business/dispatch/cancelDispatch',
  deleteDataDispatchType: '/business/dispatchType/deleteDataDispatchType',
  insertDispatchType: '/business/dispatchType/insertDispatchType',
  updateDispatchType: '/business/dispatchType/updateDispatchType',
  selectDispatchTypeByList: '/business/dispatchType/selectDispatchTypeByList',
  selectGuideboard: '/business/guideboard/selectGuideboard',
  insertGuideboardSwitch: '/business/guideboardSwitch/insertGuideboardSwitch'
}
