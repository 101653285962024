import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/engineeringEvent',
    name: 'engineeringEvent',
    component: () => import('@/views/engineering/Event.vue'),
    meta: {
      title: '工程事件'
    }
  },
  {
    path: '/engineeringEvent/addEvent',
    name: 'AddEvent',
    meta: {
      title: '新增事件'
    },
    component: () => import('@/views/engineering/AddEvent.vue')
  },
  {
    path: '/engineeringEvent/detailEvent:id',
    name: 'DetailEvent',
    meta: {
      title: '查看'
    },
    component: () => import('@/views/engineering/DetailEvent.vue')
  }
]

export default router
