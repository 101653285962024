export interface Apis {
  /** 失物新增 */
  insertLostGoods: string;
  /** 失物认领状态更新 */
  updateLostGoods: string;
  /** 失物信息列表 */
  selectLostGoodsByPage: string;
  /** 查询失物详情 */
  selectLostGoodsById: string;
  /** 寻物认领 */
  updateLostGoodsApply: string;
  /** 分页查询寻物列表 */
  selectLostGoodsApplyByPage: string;
  /** 招领点新增 */
  insertClaimPlaces: string;
  /** 招领点编辑 */
  updateClaimPlaces: string;
  /** 招领点删除 */
  deleteDataClaimPlaces: string;
  /** 招领点分页列表 */
  selectClaimPlacesByPage: string;
  /** 未打点项目列表 */
  selectProjectList: string;
}

export const apis: Apis = {
  insertLostGoods: '/operate/lostGoods/insertLostGoods',
  updateLostGoods: '/operate/lostGoods/updateLostGoods',
  selectLostGoodsByPage: '/operate/lostGoods/selectLostGoodsByPage',
  selectLostGoodsById: '/operate/lostGoods/selectLostGoodsById',
  updateLostGoodsApply: '/operate/lostGoodsApply/updateLostGoodsApply',
  selectLostGoodsApplyByPage: '/operate/lostGoodsApply/selectLostGoodsApplyByPage',
  insertClaimPlaces: '/operate/claimPlaces/insertClaimPlaces',
  updateClaimPlaces: '/operate/claimPlaces/updateClaimPlaces',
  deleteDataClaimPlaces: '/operate/claimPlaces/deleteDataClaimPlaces',
  selectClaimPlacesByPage: '/operate/claimPlaces/selectClaimPlacesByPage',
  selectProjectList: '/operate/claimPlaces/selectProjectList'
}
