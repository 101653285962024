import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  // 院校简介
  {
    path: '/campus',
    name: 'campusList',
    meta: {
      title: '院校简介'
    },
    component: () => import('@/views/school/campus/List.vue')
  },
  {
    path: '/campus/add',
    name: 'campusAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/school/campus/Add.vue')
  },
  {
    path: '/campus/edit:id',
    name: 'campusEdit',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/school/campus/Add.vue')
  },
  {
    path: '/campus/detail:id',
    name: 'campusDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/school/campus/Detail.vue')
  },
  // 实时资讯
  {
    path: '/infoManage',
    name: 'infoManageList',
    meta: {
      title: '实时资讯'
    },
    component: () => import('@/views/school/information/List.vue')
  },
  {
    path: '/infoManage/add',
    name: 'infoManageAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/school/information/Add.vue')
  },
  {
    path: '/infoManage/edit:id',
    name: 'infoManageEdit',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/school/information/Add.vue')
  },
  {
    path: '/infoManage/detail:id',
    name: 'infoManageDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/school/information/Detail.vue')
  },
  // 文化科普
  {
    path: '/cultureUniversal',
    name: 'cultureList',
    meta: {
      title: '文化科普'
    },
    component: () => import('@/views/school/universal/List.vue')
  },
  {
    path: '/cultureUniversal/add',
    name: 'cultureAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/school/universal/Add.vue')
  },
  {
    path: '/cultureUniversal/edit:id',
    name: 'cultureEdit',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/school/universal/Add.vue')
  },
  {
    path: '/cultureUniversal/detail:id',
    name: 'cultureDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/school/universal/Detail.vue')
  },
  // 校园广告
  {
    path: '/campusAdvert',
    name: 'advertList',
    meta: {
      title: '校园广告'
    },
    component: () => import('@/views/school/advert/List.vue')
  },
  {
    path: '/campusAdvert/add',
    name: 'advertAdd',
    meta: {
      title: '新增'
    },
    component: () => import('@/views/school/advert/Add.vue')
  },
  {
    path: '/campusAdvert/edit:id',
    name: 'advertEdit',
    meta: {
      title: '编辑'
    },
    component: () => import('@/views/school/advert/Add.vue')
  },
  {
    path: '/campusAdvert/detail:id',
    name: 'advertDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/school/advert/Detail.vue')
  }
]

export default router
