import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/projectEvaluationList',
    name: 'projectEvaluationList',
    component: () => import('@/views/projectEvaluation/List.vue'),
    meta: {
      title: '项目考评'
    }
  },
  {
    path: '/evaluationProject',
    name: '',
    meta: { title: '项目考评' },
    component: () => import('@/views/evaluation/EvaluationProject.vue')
  },
  {
    path: '/evaluationProject/add',
    name: '',
    meta: { title: '新增考评' },
    component: () => import('@/views/evaluation/EvaluationProjectAdd.vue')
  },
  {
    path: '/evaluationProject/view',
    name: '',
    meta: { title: '考评详情' },
    component: () => import('@/views/evaluation/EvaluationProjectView.vue')
  },
  {
    path: '/evaluationRules',
    name: '',
    meta: { title: '考评规则' },
    component: () => import('@/views/evaluation/EvaluationRules.vue')
  }
]

export default router
