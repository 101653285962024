import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/workcard',
    name: 'workcardtList',
    meta: {
      title: '智慧工牌'
    },
    component: () => import('@/views/workcard/List.vue')
  },
  {
    path: '/workcard/workcardDetail:projectId',
    name: 'workcardDetail',
    meta: {
      title: '详情'
    },
    redirect: '/workcard',
    component: () => import('@/views/workcard/Detail.vue'),
    children: [
      {
        path: '/workcard/workcardDetail:projectId/map',
        name: 'workcardDetailMap',
        meta: {
          title: '详情'
        },
        component: () => import('@/views/workcard/DetailMap.vue')
      },
      {
        path: '/workcard/workcardDetail:projectId/list',
        name: 'workcardDetailList',
        meta: {
          title: '详情'
        },
        component: () => import('@/views/workcard/DetailList.vue')
      }
    ]
  },
  {
    path: '/workcard/workcardDetail:projectId/map/playback:workerId',
    name: 'workcardMapPlayback',
    meta: {
      title: '轨迹回放'
    },
    component: () => import('@/views/workcard/DetailPlayback.vue')
  },
  {
    path: '/workcard/workcardDetail:projectId/list/playback:workerId',
    name: 'workcardListPlayback',
    meta: {
      title: '轨迹回放'
    },
    component: () => import('@/views/workcard/DetailPlayback.vue')
  }
]

export default router
