import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/inspectionReport',
    name: 'inspectionReport',
    meta: {
      title: '巡查报表'
    },
    redirect: '/inspectionReport/dayStatistical',
    component: () => import('@/views/inspectionReport/Index.vue'),
    children: [{
      path: '/inspectionReport/dayStatistical',
      name: 'inspectionReportDay',
      meta: {
        title: '巡查报表',
        hasBack: false
      },
      component: () => import('@/views/inspectionReport/DayStatistical.vue')
    },
    {
      path: '/inspectionReport/weekStatistical',
      name: 'inspectionReportWeek',
      meta: {
        title: '巡查报表',
        hasBack: false
      },
      component: () => import('@/views/inspectionReport/WeekStatistical.vue')
    },
    {
      path: '/inspectionReport/monthStatistical',
      name: 'inspectionReportMonth',
      meta: {
        title: '巡查报表',
        hasBack: false
      },
      component: () => import('@/views/inspectionReport/MonthStatistical.vue')
    },
    {
      path: '/inspectionReport/postHocReport',
      name: 'inspectionPostHocReport',
      meta: {
        title: '巡查报表',
        hasBack: false
      },
      component: () => import('@/views/inspectionReport/PostHocReport.vue')
    }]
  }, {
    path: '/inspectionReport/dayStatistical/detail',
    name: 'inspectionReportDayDetail',
    meta: {
      title: '巡查日报详情'
    },
    component: () => import('@/views/inspectionReport/DayStatisticalDetail.vue')
  }
]

export default router
