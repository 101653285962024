import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/warehousing',
    meta: { title: '资产入库' },
    component: () => import('@/views/assetScheduling/warehousing/List.vue')
  },
  {
    path: '/warehousing/add',
    meta: { title: '资产入库新增' },
    component: () => import('@/views/assetScheduling/warehousing/Add.vue')
  },
  {
    name: 'warehousingDetail',
    path: '/warehousing/detail:orderId',
    meta: { title: '资产入库详情' },
    component: () => import('@/views/assetScheduling/warehousing/Detail.vue')
  },
  {
    name: 'AssestWarehousingDetail',
    path: '/asset/:assetId/operation/detail:orderId',
    meta: { title: '资产入库详情' },
    component: () => import('@/views/assetScheduling/warehousing/Detail.vue')
  },
  {
    path: '/asset',
    meta: { title: '资产清单' },
    component: () => import('@/views/assetScheduling/list/List.vue')
  },
  {
    path: '/asset/:assetId',
    meta: { title: '资产清单' },
    component: () => import('@/views/assetScheduling/list/Basis.vue'),
    redirect: '/asset',
    children: [{
      path: 'asset',
      meta: {
        hasBack: true,
        title: '资产信息'
      },
      component: () => import('@/views/assetScheduling/list/Asset.vue')
    }, {
      path: 'operation',
      meta: {
        hasBack: true,
        title: '操作信息'
      },
      component: () => import('@/views/assetScheduling/list/Operation.vue')
    }]
  },
  {
    path: '/transfer',
    meta: { title: '资产调拨' },
    component: () => import('@/views/assetScheduling/transfer/List.vue')
  },
  {
    name: 'transferAdd',
    path: '/transfer/add',
    meta: { title: '资产调拨新增' },
    component: () => import('@/views/assetScheduling/transfer/Add.vue')
  },
  {
    name: 'assetTransferAdd',
    path: '/asset/transfer/add:projectId:assetCode',
    meta: { title: '资产调拨新增' },
    component: () => import('@/views/assetScheduling/transfer/Add.vue')
  },
  {
    name: 'transferDetail',
    path: '/transfer/detail:orderId',
    meta: { title: '资产调拨详情' },
    component: () => import('@/views/assetScheduling/transfer/Detail.vue')
  },
  {
    name: 'assetTransferDetail',
    path: '/asset/:assetId/operation/detail:orderId',
    meta: { title: '资产调拨详情' },
    component: () => import('@/views/assetScheduling/transfer/Detail.vue')
  },
  {
    name: 'inventoryDetail',
    path: '/asset/:assetId/operation/detail',
    meta: { title: '资产盘点' },
    component: () => import('@/views/assetScheduling/inventory/Inventory.vue')
  },
  {
    name: 'disposalDetail',
    path: '/asset/:assetId/operation/detail',
    meta: { title: '资产处置详情' },
    component: () => import('@/views/assetScheduling/disposal/Detail.vue')
  },
  {
    name: 'assetRequisition',
    path: '/asset/requisition:projectId:assetCode',
    meta: { title: '新增领用' },
    component: () => import('@/views/assetScheduling/requisitionRefund/Add.vue')
  },
  {
    name: 'assetRefund',
    path: '/asset/refund:projectId:assetCode',
    meta: { title: '新增退还' },
    component: () => import('@/views/assetScheduling/requisitionRefund/Add.vue')
  },
  {
    path: '/requisitionRefund/requisition/add',
    meta: { title: '新增领用' },
    component: () => import('@/views/assetScheduling/requisitionRefund/Add.vue')
  }, {
    path: '/requisitionRefund/refund/add',
    meta: { title: '新增退还' },
    component: () => import('@/views/assetScheduling/requisitionRefund/Add.vue')
  },
  {
    name: 'assetDisposalAdd',
    path: '/asset/disposal/add:projectId:assetCode',
    meta: { title: '资产处置新增' },
    component: () => import('@/views/assetScheduling/disposal/Add.vue')
  },
  {
    name: 'requisitionDetail',
    path: '/requisitionRefund/requisition/detail:orderId',
    meta: { title: '领用详情' },
    component: () => import('@/views/assetScheduling/requisitionRefund/Detail.vue')
  },
  {
    name: 'AssetsRequisitionDetail',
    path: '/asset/:assetId/operation/detail:orderId',
    meta: { title: '领用详情' },
    component: () => import('@/views/assetScheduling/requisitionRefund/Detail.vue')
  },
  {
    name: 'refundDetail',
    path: '/requisitionRefund/refund/detail:orderId',
    meta: { title: '退还详情' },
    component: () => import('@/views/assetScheduling/requisitionRefund/Detail.vue')
  }, {
    name: 'AssetsRefundDetail',
    path: '/asset/:assetId/operation/detail:orderId',
    meta: { title: '退还详情' },
    component: () => import('@/views/assetScheduling/requisitionRefund/Detail.vue')
  },
  {
    path: '/requisitionRefund',
    meta: { title: '领用退还' },
    component: () => import('@/views/assetScheduling/requisitionRefund/Basis.vue'),
    redirect: '/requisitionRefund/requisition',
    children: [{
      path: 'requisition',
      meta: {
        hasBack: false,
        title: '资产领用'
      },
      component: () => import('@/views/assetScheduling/requisitionRefund/Requisition.vue')
    }, {
      path: 'refund',
      meta: {
        hasBack: false,
        title: '资产退还'
      },
      component: () => import('@/views/assetScheduling/requisitionRefund/Refund.vue')
    }]
  }, {
    path: '/requisitionRefund/add',
    meta: {
      hasBack: false,
      title: '入库单据'
    },
    component: () => import('@/views/assetScheduling/requisitionRefund/Add.vue')
  }, {
    path: '/requisitionRefund/detail',
    meta: {
      hasBack: false,
      title: '入库单据'
    },
    component: () => import('@/views/assetScheduling/requisitionRefund/Detail.vue')
  },
  {
    path: '/assetSetting',
    meta: { title: '资产设置' },
    component: () => import('@/views/assetScheduling/setting/Basis.vue'),
    redirect: '/assetSetting/types',
    children: [{
      path: 'types',
      meta: {
        hasBack: false,
        title: '资产分类'
      },
      component: () => import('@/views/assetScheduling/setting/Types.vue')
    }, {
      path: 'area',
      meta: {
        hasBack: false,
        title: '资产位置'
      },
      component: () => import('@/views/assetScheduling/setting/Area.vue')
    }, {
      path: 'approval',
      meta: {
        hasBack: false,
        title: '资产审批'
      },
      component: () => import('@/views/assetScheduling/setting/Approval.vue')
    }, {
      path: 'equity',
      meta: {
        hasBack: false,
        title: '资产产权'
      },
      component: () => import('@/views/assetScheduling/setting/Equity.vue')
    }]
  }, {
    path: '/disposal',
    meta: { title: '资产处置' },
    component: () => import('@/views/assetScheduling/disposal/List.vue')
  },
  {
    path: '/disposal/add',
    meta: { title: '资产处置新增' },
    component: () => import('@/views/assetScheduling/disposal/Add.vue')
  },
  {
    path: '/disposal/detail',
    meta: { title: '资产处置详情' },
    component: () => import('@/views/assetScheduling/disposal/Detail.vue')
  },
  {
    path: '/inventory',
    meta: { title: '资产盘点' },
    component: () => import('@/views/assetScheduling/inventory/List.vue')
  },
  {
    path: '/inventory/Inventory',
    meta: { title: '盘点' },
    component: () => import('@/views/assetScheduling/inventory/Inventory.vue')
  },
  {
    path: '/inventory/detail',
    meta: { title: '资产盘点详情' },
    component: () => import('@/views/assetScheduling/inventory/Detail.vue')
  }
]

export default router
