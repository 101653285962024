import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/circuitBreaker',
    name: 'circuitBreakerList',
    meta: {
      title: '智慧断路器'
    },
    component: () => import('@/views/circuitBreaker/List.vue')
  },
  {
    path: '/circuitBreaker/detail:projectId',
    name: 'circuitBreakerDetail',
    component: () => import('@/views/circuitBreaker/Detail.vue'),
    redirect: '/circuitBreaker',
    children: [
      {
        path: '/circuitBreaker/detail:projectId/map',
        name: 'circuitBreakerDetailMap',
        component: () => import('@/views/circuitBreaker/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/circuitBreaker/detail:projectId/list',
        name: 'circuitBreakerDetailList',
        component: () => import('@/views/circuitBreaker/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/circuitBreaker/forecast:projectId',
    name: 'circuitBreakerForecast',
    component: () => import('@/views/circuitBreaker/Forecast.vue'),
    meta: {
      title: '能耗预测'
    }
  },
  {
    path: '/circuitBreaker/detail:projectId/map/history:deviceId',
    name: 'circuitBreakerMapHistory',
    component: () => import('@/views/circuitBreaker/History.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    path: '/circuitBreaker/detail:projectId/list/history:deviceId',
    name: 'circuitBreakerListHistory',
    component: () => import('@/views/circuitBreaker/History.vue'),
    meta: {
      title: '历史数据'
    }
  }
]

export default router
