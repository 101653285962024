// 接口名定义
export interface Apis {
  /** 查询智能遥控项目列表 */
  selectSumProjectList: string;
  /** 地图 */
  /** 查询项目详情地图 */
  selectControl: string;
  /** 控制器开关操作 */
  insertControl: string;
  /** 控制器详情列表 */
  selectControlPage: string;
  /** 控制器详情 */
  selectControlById: string;
  /** 查询控制器绑定列表 */
  selectControlDeviceByPage: string;
  /** 查询控制器未绑定设备的线路 */
  selectNotDeviceByList: string;
  /** 添加控制器 */
  insertControlDevice: string;
  /** 编辑控制器 */
  updateControlDevice: string;
  /** 删除控制器 */
  deleteDataControlDevice: string;
  /** 查看控制器详情 */
  selectControlDeviceById: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/control/selectSumProjectList',
  selectControl: '/business/control/selectControl',
  selectControlPage: '/business/control/selectControlPage',
  insertControl: '/device/control/insertControl',
  selectControlById: '/business/control/selectControlById',
  selectControlDeviceByPage: '/business/controlDevice/selectControlDeviceByPage',
  selectNotDeviceByList: '/business/controlDevice/selectNotDeviceByList',
  insertControlDevice: '/business/controlDevice/insertControlDevice',
  updateControlDevice: '/business/controlDevice/updateControlDevice',
  deleteDataControlDevice: '/business/controlDevice/deleteDataControlDevice',
  selectControlDeviceById: '/business/controlDevice/selectControlDeviceById'
}
