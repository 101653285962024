import { render, staticRenderFns } from "./CustomUpload.vue?vue&type=template&id=3662dd71&scoped=true"
import script from "./CustomUpload.vue?vue&type=script&lang=js"
export * from "./CustomUpload.vue?vue&type=script&lang=js"
import style0 from "./CustomUpload.vue?vue&type=style&index=0&id=3662dd71&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3662dd71",
  null
  
)

export default component.exports