import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/intercom',
    name: 'intercom',
    component: () => import('@/views/layout/Blank.vue'),
    meta: {
      title: '巡查终端'
    }
  },
  {
    path: '/patrol',
    name: 'patrol',
    component: () => import('@/views/layout/Blank.vue'),
    meta: {
      title: '智慧巡更'
    }
  },
  {
    path: '/3dMap',
    name: '3dMap',
    component: () => import('@/views/layout/Blank.vue'),
    meta: {
      title: '实景三维地图'
    }
  }
]

export default router
