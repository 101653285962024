export interface Apis {
  userInfo: string;
  updateInitPassword: string;
  updatePassword: string;
  updateIparkUser: string;
  userLogPage: string;
  // 获取未读消息数
  userReadNum: string;
}

export const apis: Apis = {
  userInfo: '/business/iparkUser/selectIparkUserByUserId',
  updateInitPassword: '/business/iparkUser/updateInitPassword',
  updatePassword: '/business/iparkUser/updatePassword',
  // 修改用户信息包括头像
  updateIparkUser: '/business/iparkUser/updateIparkUser',
  // 登录日志
  userLogPage: '/mc/loginLog/selectLoginLogByPage',
  userReadNum: '/mc/msg/readNum'
}
