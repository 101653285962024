export interface Apis {
  /** 物料管理 */
  insertMaterial: string;
  updateMaterial: string;
  deleteMaterial: string;
  selectMaterialByMaterialId: string;
  selectMaterialByList: string;
  selectMaterialByPage: string;
  importNewMaterial: string;

  /** 物料分类管理 */
  insertMaterialType: string;
  updateMaterialType: string;
  deleteDataMaterialType: string;
  selectMaterialTypeByList: string;

  /** 入库记录管理 */
  insertStorage: string;
  selectStorageById: string;
  selectStorageByPage: string;
  inExportExcel: string;

  /** 出库记录管理 */
  insertCheckout: string;
  selectCheckoutById: string;
  selectCheckoutByPage: string;
  outExportExcel: string;

  /** 仓库管理 */
  insertWarehouse: string;
  updateWarehouse: string;
  deleteWarehouse: string;
  selectWarehouseByWarehouseId: string;
  selectWarehouseByList: string;
  selectWarehouseByPage: string;

  /** 库存查询 */
  // 查询库存列表
  selectStockByList: string;

  /** 出入库流水 */
  // 查询出入库流水
  selectDetailedByList: string;

  /** 供应商管理 */
  // 新增供应商
  insertErpSupplier: string;
  updateErpSupplier: string;
  deleteErpSupplier: string;
  selectErpSupplierBySupplierId: string;
  selectErpSupplierByPage: string;
  // 供应商类型
  insertErpSupplierType: string;
  updateErpSupplierType: string;
  deleteDataErpSupplierType: string;
  selectErpSupplierTypeBySupplierType: string;
  selectErpSupplierTypeByPage: string;
  /** 机械管理 */
  insertMachinery: string;
  updateMachinery: string;
  deleteMachinery: string;
  selectMachineryByMachineryId: string;
  selectMachineryByPage: string;
}

export const apis: Apis = {
  /** 物料管理 */
  insertMaterial: '/business/material/insertMaterial',
  updateMaterial: '/business/material/updateMaterial',
  deleteMaterial: '/business/material/deleteMaterial',
  selectMaterialByMaterialId: '/business/material/selectMaterialByMaterialId',
  selectMaterialByList: '/business/material/selectMaterialByList',
  selectMaterialByPage: '/business/material/selectMaterialByPage',
  importNewMaterial: '/business/material/importNewMaterial',

  /** 物料分类管理 */
  insertMaterialType: '/business/materialType/insertMaterialType',
  updateMaterialType: '/business/materialType/updateMaterialType',
  deleteDataMaterialType: '/business/materialType/deleteDataMaterialType',
  selectMaterialTypeByList: '/business/materialType/selectMaterialTypeByList',

  /** 入库记录管理 */
  insertStorage: '/business/storage/insertStorage',
  selectStorageById: '/business/storage/selectStorageById',
  selectStorageByPage: '/business/storage/selectStorageByPage',
  inExportExcel: '/business/storage/exportExcel',

  /** 出库记录管理 */
  insertCheckout: '/business/checkout/insertCheckout',
  selectCheckoutById: '/business/checkout/selectCheckoutById',
  selectCheckoutByPage: '/business/checkout/selectCheckoutByPage',
  outExportExcel: '/business/checkout/exportExcel',

  selectStockByList: '/business/warehouse/selectStockByList',
  selectDetailedByList: '/business/warehouse/selectDetailedByList',

  /** 仓库管理 */
  insertWarehouse: '/business/warehouse/insertWarehouse',
  updateWarehouse: '/business/warehouse/updateWarehouse',
  deleteWarehouse: '/business/warehouse/deleteWarehouse',
  selectWarehouseByWarehouseId: '/business/warehouse/selectWarehouseByWarehouseId',
  selectWarehouseByList: '/business/warehouse/selectWarehouseByList',
  selectWarehouseByPage: '/business/warehouse/selectWarehouseByPage',

  /** 供应商管理 */
  // 新增供应商
  insertErpSupplier: '/business/erpSupplier/insertErpSupplier',
  updateErpSupplier: '/business/erpSupplier/updateErpSupplier',
  deleteErpSupplier: '/business/erpSupplier/deleteErpSupplier',
  selectErpSupplierBySupplierId: '/business/erpSupplier/selectErpSupplierBySupplierId',
  selectErpSupplierByPage: '/business/erpSupplier/selectErpSupplierByPage',
  // 供应商类型
  insertErpSupplierType: '/business/erpSupplierType/insertErpSupplierType',
  updateErpSupplierType: '/business/erpSupplierType/updateErpSupplierType',
  deleteDataErpSupplierType: '/business/erpSupplierType/deleteDataErpSupplierType',
  selectErpSupplierTypeBySupplierType: '/business/erpSupplierType/selectErpSupplierTypeBySupplierType',
  selectErpSupplierTypeByPage: '/business/erpSupplierType/selectErpSupplierTypeByPage',

  /** 机械管理 */
  insertMachinery: '/business/machinery/insertMachinery',
  updateMachinery: '/business/machinery/updateMachinery',
  deleteMachinery: '/business/machinery/deleteMachinery',
  selectMachineryByMachineryId: '/business/machinery/selectMachineryByMachineryId',
  selectMachineryByPage: '/business/machinery/selectMachineryByPage'
}
