// 接口名定义
const baseUrl = '/business/flowOrder/'
const typeUrl = '/business/flowOrderType/'
export interface Apis {
  /** 新增工单类型 */
  insertFlowOrderType: string;
  /** 修改工单类型 */
  updateFlowOrderType: string;
  /** 删除工单类型 */
  deleteFlowOrderType: string;
  /** 工单类型列表 */
  selectFlowOrderTypeByList: string;
  /** 新增工单 */
  insertFlowOrder: string;
  /** 修改工单 */
  updateFlowOrder: string;
  /** 删除工单 */
  deleteFlowOrder: string;
  /** 查询工单详情 */
  selectFlowOrderByOrderId: string;
  /** 分页查询工单列表 */
  selectFlowOrderByPage: string;
  /** 工单办理 办理类型handleType:1转派2反馈3审核4关闭 */
  insertFlowOrderHandle: string;
  /** 工单导出 */
  exportFlowOrderData: string;
  /** 工单统计 */
  selectFlowStatistics: string;
  /** 工单状态统 */
  selectFlowStatus: string;
  /** 工单完成统计(按项目) */
  selectOrderCompletionByProject: string;
  /** 工单完成统计(按类型) */
  selectOrderCompletionByType: string;
  /** 工单环比分析 (按项目) */
  selectOrderAnalysisByProject: string;
  /** 工单环比分析 (按类型) */
  selectOrderAnalysisByType: string;
  /** 工单导出文件列表查询 */
  selectFlowOrderFileByPage: string;
  /** 工单导出文件列表删除 */
  deleteFlowOrderFile: string;
}

export const apis: Apis = {
  insertFlowOrderType: typeUrl + 'insertFlowOrderType',
  updateFlowOrderType: typeUrl + 'updateFlowOrderType',
  deleteFlowOrderType: typeUrl + 'deleteFlowOrderType',
  selectFlowOrderTypeByList: typeUrl + 'selectFlowOrderTypeByList',
  insertFlowOrder: baseUrl + 'insertFlowOrder',
  updateFlowOrder: baseUrl + 'updateFlowOrder',
  deleteFlowOrder: baseUrl + 'deleteFlowOrder',
  selectFlowOrderByOrderId: baseUrl + 'selectFlowOrderByOrderId',
  selectFlowOrderByPage: baseUrl + 'selectFlowOrderByPage',
  insertFlowOrderHandle: baseUrl + 'insertFlowOrderHandle',
  selectFlowStatistics: baseUrl + 'selectFlowStatistics',
  exportFlowOrderData: baseUrl + 'exportFlowOrderData',
  selectFlowStatus: baseUrl + 'selectFlowStatus',
  selectOrderCompletionByProject: baseUrl + 'selectOrderCompletionByProject',
  selectOrderCompletionByType: baseUrl + 'selectOrderCompletionByType',
  selectOrderAnalysisByProject: baseUrl + 'selectOrderAnalysisByProject',
  selectOrderAnalysisByType: baseUrl + 'selectOrderAnalysisByType',
  selectFlowOrderFileByPage: '/business/flowOrderFile/selectFlowOrderFileByPage',
  deleteFlowOrderFile: '/business/flowOrderFile/deleteFlowOrderFile'
}
