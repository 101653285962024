import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/hydrantManage',
    name: 'hydrantManage',
    meta: {
      title: '消防栓管理'
    },
    component: () => import('@/views/hydrant/manage/List.vue')
  },
  {
    path: '/hydrantManage/add',
    name: 'hydrantManageAdd',
    meta: {
      title: '消防栓添加'
    },
    component: () => import('@/views/hydrant/manage/Add.vue')
  },
  {
    path: '/hydrantManage/edit:id',
    name: 'hydrantManageEdit',
    meta: {
      title: '消防栓编辑'
    },
    component: () => import('@/views/hydrant/manage/Add.vue')
  },
  {
    path: '/hydrantMonitor',
    name: 'hydrantMonitor',
    meta: {
      title: '消防栓监测'
    },
    component: () => import('@/views/hydrant/monitor/List.vue')
  },
  {
    path: '/hydrantMonitor/detail:projectId',
    name: 'hydrantMonitorDetail',
    meta: {
      title: '详情'
    },
    redirect: '/hydrantMonitor',
    component: () => import('@/views/hydrant/monitor/Detail.vue'),
    children: [
      {
        path: '/hydrantMonitor/detail:projectId/map',
        name: 'hydrantMonitorDetailMap',
        component: () => import('@/views/hydrant/monitor/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/hydrantMonitor/detail:projectId/list',
        name: 'hydrantMonitorDetailList',
        component: () => import('@/views/hydrant/monitor/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/hydrantMonitor/detail:projectId/list/history:id',
    name: 'hydrantMonitorListHistory',
    component: () => import('@/views/hydrant/monitor/History.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    path: '/hydrantMonitor/detail:projectId/map/history:id',
    name: 'hydrantMonitorMapHistory',
    component: () => import('@/views/hydrant/monitor/History.vue'),
    meta: {
      title: '历史数据'
    }
  }
]

export default router
