import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/eventManage',
    name: 'eventManage',
    meta: {
      title: '事件管理'
    },
    component: () => import('@/views/eventManage/List.vue')
  }
]

export default router
