export interface Apis {
  /** 校园简介 */
    // 新增
    insertCampusInfo: string;
    // 分页查询
    selectCampusInfoByPage: string;
    // 详情
    selectCampusInfoByInfoId: string;
    // 修改
    updateCampusInfo: string;
    // 删除
    deleteCampusInfo: string;
  /** 实时资讯 */
    // 新增
    insertCampusConsult: string;
    // 分页查询
    selectCampusConsultByPage: string;
    // 详情
    selectCampusConsultByConsultId: string;
    // 修改
    updateCampusConsult: string;
    // 删除
    deleteCampusConsult: string;
    // 发布/下架
    updateConsultStatus: string;
  /** 文化科普 */
    // 新增
    insertCampusScience: string;
    // 分页查询
    selectCampusScienceByPage: string;
    // 详情
    selectCampusScienceByScienceId: string;
    // 修改
    updateCampusScience: string;
    // 删除
    deleteCampusScience: string;
    // 发布/下架
    updateScienceStatus: string;
  /** 校园广告 */
    // 新增
    insertCampusAdvert: string;
    // 分页查询
    selectCampusAdvertByPage: string;
    // 详情
    selectCampusAdvertByAdvertId: string;
    // 修改
    updateCampusAdvert: string;
    // 删除
    deleteCampusAdvert: string;
    // 发布/下架
    updateAdvertStatus: string;
}

export const apis: Apis = {
  /** 校园简介 */
  insertCampusInfo: '/business/campusInfo/insertCampusInfo',
  selectCampusInfoByPage: '/business/campusInfo/selectCampusInfoByPage',
  selectCampusInfoByInfoId: '/business/campusInfo/selectCampusInfoByInfoId',
  updateCampusInfo: '/business/campusInfo/updateCampusInfo',
  deleteCampusInfo: '/business/campusInfo/deleteCampusInfo',
  /** 实时资讯 */
  insertCampusConsult: '/business/campusConsult/insertCampusConsult',
  selectCampusConsultByPage: '/business/campusConsult/selectCampusConsultByPage',
  selectCampusConsultByConsultId: '/business/campusConsult/selectCampusConsultByConsultId',
  updateCampusConsult: '/business/campusConsult/updateCampusConsult',
  deleteCampusConsult: '/business/campusConsult/deleteCampusConsult',
  updateConsultStatus: '/business/campusConsult/updateCampusStatus',
  /** 文化科普 */
  insertCampusScience: '/business/campusScience/insertCampusScience',
  selectCampusScienceByPage: '/business/campusScience/selectCampusScienceByPage',
  selectCampusScienceByScienceId: '/business/campusScience/selectCampusScienceByScienceId',
  updateCampusScience: '/business/campusScience/updateCampusScience',
  deleteCampusScience: '/business/campusScience/deleteCampusScience',
  updateScienceStatus: '/business/campusScience/updateCampusStatus',
  /** 文化科普 */
  insertCampusAdvert: '/business/campusAdvert/insertCampusAdvert',
  selectCampusAdvertByPage: '/business/campusAdvert/selectCampusAdvertByPage',
  selectCampusAdvertByAdvertId: '/business/campusAdvert/selectCampusAdvertByAdvertId',
  updateCampusAdvert: '/business/campusAdvert/updateCampusAdvert',
  deleteCampusAdvert: '/business/campusAdvert/deleteCampusAdvert',
  updateAdvertStatus: '/business/campusAdvert/updateCampusStatus'
}
