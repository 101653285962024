import { RouteConfig } from 'vue-router'
const router: Array<RouteConfig> = [
  {
    path: '/intelligentMachine',
    name: 'intelligentMachineList',
    component: () => import('@/views/intelligentMachine/List.vue'),
    meta: {
      title: '智能机械'
    }
  },
  {
    path: '/intelligentMachine/add',
    name: 'intelligentMachineAdd',
    component: () => import('@/views/intelligentMachine/Add.vue'),
    meta: {
      title: '新增'
    }
  },
  {
    path: '/intelligentMachine/edit:id',
    name: 'intelligentMachineEdit',
    component: () => import('@/views/intelligentMachine/Add.vue'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/intelligentMachine/detail',
    name: 'intelligentMachineDetail',
    component: () => import('@/views/intelligentMachine/Detail.vue'),
    redirect: '/intelligentMachine',
    meta: {
      title: '详情'
    },
    children: [
      {
        path: 'machineDetail',
        props: true,
        component: () => import('@/views/intelligentMachine/machineDetail.vue'),
        meta: {
          title: '详情'
        }
      }
    ]

  }

]

export default router
