import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/overviewScreen',
    name: 'overviewScreen',
    meta: {
      title: '项目概览'
    },
    component: () => import('@/views/overview/Index.vue')
  },
  {
    path: '/overviewScreen/project:projectId',
    name: 'overviewScreenProject',
    meta: {
      title: '项目详情'
    },
    component: () => import('@/views/overview/Detail.vue')
  },
  {
    path: '/overviewScreen/playback:projectId:workerId',
    name: 'overviewScreenPlayback',
    meta: {
      title: '轨迹回放' // 项目详情轨迹回放
    },
    component: () => import('@/views/workcard/DetailPlayback.vue')
  },
  {
    path: '/overviewScreen/project:projectId/playback:workerId',
    name: 'overviewScreenMapPlayback',
    meta: {
      title: '轨迹回放' // 项目详情轨迹回放
    },
    component: () => import('@/views/workcard/DetailPlayback.vue')
  }
]

export default router
