import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/dispatchMap',
    name: 'dispatchMap',
    meta: {
      title: '事件地图'
    },
    component: () => import('@/views/dispatchMap/Index.vue')
  }
]

export default router
