import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/braceletList',
    name: 'braceletList',
    meta: {
      title: '智慧手环'
    },
    component: () => import('@/views/bracelet/List.vue')
  },
  {
    path: '/braceletList/braceletDetail:projectId',
    name: 'braceletDetail',
    meta: {
      title: '详情'
    },
    redirect: '/braceletList',
    component: () => import('@/views/bracelet/Detail.vue'),
    children: [{
      path: '/braceletList/braceletDetail:projectId/map',
      name: 'braceletDetailMap',
      meta: {
        title: '详情'
      },
      component: () => import('@/views/bracelet/DetailMap.vue')
    },
    {
      path: '/braceletList/braceletDetail:projectId/list',
      name: 'braceletDetailList',
      meta: {
        title: '详情'
      },
      component: () => import('@/views/bracelet/DetailList.vue')
    }]
  },
  {
    path: '/braceletList/braceletDetail:projectId/map/playback:workerId',
    name: 'braceletMapPlayback',
    meta: {
      title: '轨迹回放'
    },
    component: () => import('@/views/bracelet/DetailPlayback.vue')
  },
  {
    path: '/braceletList/braceletDetail:projectId/list/playback:workerId',
    name: 'braceletListPlayback',
    meta: {
      title: '轨迹回放'
    },
    component: () => import('@/views/bracelet/DetailPlayback.vue')
  },
  {
    path: '/braceletList/braceletDetail:projectId/list/health:workerId',
    name: 'braceletListHealth',
    meta: {
      title: '健康数据'
    },
    component: () => import('@/views/bracelet/DetailDrawing.vue')
  },
  {
    path: '/braceletList/braceletDetail:projectId/map/health:workerId',
    name: 'braceletMapHealth',
    meta: {
      title: '健康数据'
    },
    component: () => import('@/views/bracelet/DetailDrawing.vue')
  },
  {
    path: '/onlineStatistics',
    name: 'onlineStatistics',
    meta: { title: '在岗统计' },
    component: () => import('@/views/bracelet/OnlineStatistics.vue')
  },
  {
    path: '/onlineStatistics/detail',
    name: 'onlineStatisticsDetail',
    meta: { title: '详情' },
    component: () => import('@/views/bracelet/OnlineStatisticsDetail.vue')
  },
  {
    path: '/onlineStatistics/rulesettings',
    name: 'rulesettings',
    meta: { title: '详情' },
    component: () => import('@/views/bracelet/RuleSettings.vue')
  }
]

export default router
