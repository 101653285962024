import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/soilFertilityList',
    name: 'soilFertilityList',
    meta: {
      title: '土壤肥力'
    },
    component: () => import('@/views/soilFertility/List.vue')
  },
  {
    path: '/soilFertilityList/detail:projectId',
    name: 'soilFertilityDetail',
    component: () => import('@/views/soilFertility/Detail.vue'),
    redirect: '/soilFertilityList',
    children: [
      {
        path: '/soilFertilityList/detail:projectId/map',
        name: 'soilFertilityDetailMap',
        component: () => import('@/views/soilFertility/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/soilFertilityList/detail:projectId/list',
        name: 'soilFertilityDetailList',
        component: () => import('@/views/soilFertility/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/soilFertilityList/detail:projectId/list/history:deviceId',
    name: 'soilFertilityListHistory',
    component: () => import('@/views/soilFertility/History.vue'),
    meta: {
      title: '历史数据'
    }
  },
  {
    path: '/soilFertilityList/detail:projectId/map/history:deviceId',
    name: 'soilFertilityMapHistory',
    component: () => import('@/views/soilFertility/History.vue'),
    meta: {
      title: '历史数据'
    }
  }
]

export default router
