// 接口名定义
export interface Apis {
  login: string;
  /** sso登录，只需authCode，为徐州定制，非正常登录 */
  ssoLogin: string;
  /** sso登录，只需authCode，为瑞景定制，非正常登录 */
  ssoLoginRj: string;
  logout: string;
  getVerifyCode: string;
}

export const apis: Apis = {
  login: '/iparkAdmin/custom/login',
  ssoLogin: '/user/custom/ssoLogin',
  ssoLoginRj: '/user/custom/ssoLoginRj',
  logout: '/user/logout',
  getVerifyCode: '/iparkAdmin/getVerifyCode'
}
