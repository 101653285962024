import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/sumec',
    name: 'sumecList',
    meta: {
      title: '割草机器人'
    },
    component: () => import('@/views/sumec/List.vue')
  },
  {
    path: '/sumec/detail:projectId',
    name: 'sumecDetail',
    component: () => import('@/views/sumec/Detail.vue'),
    redirect: '/sumec',
    children: [
      {
        path: '/sumec/detail:projectId/map',
        name: 'sumecDetailMap',
        component: () => import('@/views/sumec/DetailMap.vue'),
        meta: {
          title: '详情'
        }
      },
      {
        path: '/sumec/detail:projectId/list',
        name: 'sumecDetailList',
        component: () => import('@/views/sumec/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/sumec/detail:projectId/list/device:deviceId',
    name: 'sumecDeviceDetailList',
    component: () => import('@/views/sumec/DeviceDetail.vue'),
    meta: {
      title: '设备详情'
    }
  },
  {
    path: '/sumec/detail:projectId/map/device:deviceId',
    name: 'sumecDeviceDetailMap',
    component: () => import('@/views/sumec/DeviceDetail.vue'),
    meta: {
      title: '设备详情'
    }
  }
]

export default router
